import React, { useState } from 'react'
import DragDropManager from './DragDropManager'
export interface IDragDropContext {
    manager: DragDropManager;
};
//@ts-ignore
export const DragDropContext: React.Context<IDragDropContext> = React.createContext<IDragDropContext>();

const DragDropProvider: React.FC = ({ children }) => {
    const [manager] = useState(new DragDropManager());

    const ctx: IDragDropContext = {
        manager
    };
    return <DragDropContext.Provider value={ctx} children={children} />
}
export default DragDropProvider;