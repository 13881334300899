import Api, { HttpMethod, toOptional } from '../..';
import AccelFile, { FileSource } from '../../../models/file/accel-file';

export type PartETag = {
    eTag: string;
    partNumber: number;
}

export type UploadFileArgs = {
    source?: FileSource;
    fileId?: string;
    courseId?: string;
    schoolId?: string;
    courseItemId?: string;
    courseItemTaskId?: string;
    courseItemTestId?: string;
    courseItemTestQuestionId?: string;
    courseItemTestAnswerId?: string;
    courseItemTaskAnswerId?: string;
    courseItemTaskAnswerCommentId?: string;
    isPublic?: boolean;
    siteId?: string;
    meta?: any;
    voice?: boolean;
}

export default class FileApi {
    constructor(private api: Api) {
    }

    abort = (fileId: string) => this.api.execute2({ url: `/file/abort`, method: HttpMethod.PUT, headers: { 'X-Content-Id': fileId } });

    begin = (file: File, chunkSize: number, args: UploadFileArgs) => {
        const headers: any = {};
        headers['X-Content-Type'] = file.type
            ? file.type
            : 'application/octet-stream';

        if (file.size) headers['X-Content-Length'] = file.size;
        if (chunkSize) headers['X-Chunk-Size'] = chunkSize;
        if (file.name) headers['X-Content-Name'] = encodeURI(file.name);

        if (args.fileId) headers['X-Content-File-Id'] = args.fileId;
        if (args.source) headers['X-Source'] = args.source;
        if (args.schoolId) headers['X-School-Id'] = args.schoolId;
        if (args.courseItemId) headers['X-Course-Item-Id'] = args.courseItemId;
        if (args.courseItemTaskId) headers['X-Course-Item-Task-Id'] = args.courseItemTaskId;
        if (args.courseItemTestId) headers['X-Course-Item-Test-Id'] = args.courseItemTestId;
        if (args.courseItemTestQuestionId) headers['X-Course-Item-Test-Question-Id'] = args.courseItemTestQuestionId;
        if (args.courseItemTestAnswerId) headers['X-Course-Item-Test-Answer-Id'] = args.courseItemTestAnswerId;
        if (args.courseItemTaskAnswerId) headers['X-Course-Item-Task-Answer-Id'] = args.courseItemTaskAnswerId;
        if (args.courseItemTaskAnswerCommentId) headers['X-Course-Item-Task-Answer-Comment-Id'] = args.courseItemTaskAnswerCommentId;
        if (args.isPublic) headers['X-Is-Public'] = args.isPublic;
        if (args.siteId) headers['X-Site-Id'] = args.siteId;
        if (args.meta) headers['X-Meta'] = JSON.stringify(args.meta);
        if (args.voice) headers['X-Voice'] = args.voice;

        return this.api.execute2<{ id: string; urls: string[]; }>({
            url: `/file/begin`,
            method: HttpMethod.POST,
            headers
        });
    }

    upload = (url: string, contentType: string) => this.api.execute2({ url, method: HttpMethod.PUT, headers: { 'Content-Type': contentType } });

    complete = (id: string, eTags: PartETag[]) => this.api.execute2<AccelFile>({ url: `/file/complete`, method: HttpMethod.POST, body: { id, eTags } }, json => AccelFile.fromJson(json));

    save = (file: Partial<AccelFile>) => this.api.execute2({
        url: `/file`, method: HttpMethod.PUT,
        body: {
            id: file.id,
            name: toOptional(file.name),
            description: toOptional(file.description),
            isPublic: toOptional(file.isPublic),
            metadataJson: toOptional(file.additionalData ? JSON.stringify(file.additionalData) : undefined),
        }
    });

    remove = (fileId: string) => this.api.execute2({ url: `/file/${fileId}`, method: HttpMethod.DELETE });

    download = (fileId: string, isPreview: boolean = false) => this.api.execute2<string>({ url: `/file/${fileId}`, method: HttpMethod.GET, body: { isPreview } });

    fetch = (fileId: string, fields?: string) => this.api.get<AccelFile>(`/file/${fileId}/info`,
        fields ?? `{
            id,
            name,
            size,
            type,
            mimeType,
            cloudKey,
            extension,
            isPublic,
            createdDate
        }`,
        json => AccelFile.fromJson(json));

    /**
     * Prepares a file for future use, calc metadata, convert to another type if needed, etc.
     */
    public prepare = (fileId: string) => this.api.execute2<void>({ method: HttpMethod.PUT, url: `/file/prepare/${fileId}` });
}