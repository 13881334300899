import { BytesPer } from './HowMany';

export enum FileSizeUnit {
    Bytes,
    Kilobytes,
    Megabytes,
    Gigabytes,
}

export class FileSizeUnitUtils {

    static toBytes(
        value: number,
        units: FileSizeUnit = FileSizeUnit.Bytes): number {

        switch (units) {
            case FileSizeUnit.Gigabytes:
                return value * BytesPer.Gigabytes;
            case FileSizeUnit.Megabytes:
                return value * BytesPer.Megabytes;
            case FileSizeUnit.Kilobytes:
                return value * BytesPer.Kilobytes;
            case FileSizeUnit.Bytes:
                return value;
            default:
                throw new Error("Invalid FileSizeUnit");
        }
    }

    static fromBytes(
        bytes: number,
        units: FileSizeUnit) {
        switch (units) {
            case FileSizeUnit.Gigabytes:
                return bytes / BytesPer.Gigabytes;
            case FileSizeUnit.Megabytes:
                return bytes / BytesPer.Megabytes;
            case FileSizeUnit.Kilobytes:
                return bytes / BytesPer.Kilobytes;
            case FileSizeUnit.Bytes:
                return bytes;
            default:
                throw new Error("Invalid FileSizeUnit");
        }
    }
}
export default FileSizeUnit;