import { Space } from 'antd';
import React, { useMemo } from "react";
import { NumberFormat, TimeLoc } from '..';
import { TimeSpan } from '../../utils';


export function getDurationParts(duration: TimeSpan, convertDaysToHours?: boolean) {
    let leftDuration = duration;
    let days = 0;
    if (convertDaysToHours !== true) {
        days = Math.floor(leftDuration.days);
        leftDuration = leftDuration.substract(TimeSpan.fromDays(days));
    }
    const hours = Math.floor(leftDuration.hours);
    leftDuration = leftDuration.substract(TimeSpan.fromHours(hours));
    const minutes = Math.floor(leftDuration.minutes);
    leftDuration = leftDuration.substract(TimeSpan.fromMinutes(minutes));
    const seconds = Math.floor(leftDuration.seconds);
    return { days, hours, minutes, seconds };
}

type DurationProps = {
    duration: TimeSpan;
    includeSeconds?: boolean;
    convertDaysToHours?: boolean;
    placeholder?: React.ReactNode;
    format?: 'default' | 'text' | 'textShort';
    hideUnitIfZero?: boolean;
    className?: string;
    suffix?: React.ReactNode;
}

const Duration: React.FC<DurationProps> = ({ duration, convertDaysToHours, includeSeconds, format, placeholder, hideUnitIfZero, className, suffix }) => {
    const isNegative = useMemo(() => duration.seconds < 0, [duration]);
    const { days, hours, minutes, seconds } = useMemo(() => getDurationParts(duration, convertDaysToHours), [duration, convertDaysToHours]);

    if (format == 'default')
        return <span className={className}>
            {isNegative && '-'}
            {(hideUnitIfZero === false || days > 0) && <><TimeLoc value={days} measure='days' short />&nbsp;</>}
            {(hideUnitIfZero === false || hours > 0 || days > 0) && <>{hours.toString().padStart(2, '0')}:</>}
            {<>{minutes.toString().padStart(2, '0')}:</>}
            {<>{seconds.toString().padStart(2, '0')}</>}
            {suffix}
        </span>;

    if (minutes == 0 && hours == 0 && days == 0 && (includeSeconds == false || seconds == 0))
        return <span className={className}>
            {placeholder ?? <Space size={5}>
                {isNegative && '-'}
                {convertDaysToHours && <TimeLoc value={0} measure='days' short={format == 'textShort'} />}
                <TimeLoc value={0} measure='hours' short={format == 'textShort'} />
                <TimeLoc value={0} measure='minutes' short={format == 'textShort'} />
                {includeSeconds && <TimeLoc value={0} measure='seconds' short={format == 'textShort'} />}
                {suffix}
            </Space>}
        </span>;
    const secondsVisible = seconds > 0 && (includeSeconds == true || (minutes == 0 && hours == 0 && days == 0));
    return <Space size={5} className={className}>
        {isNegative && '-'}
        {days > 0 && <TimeLoc value={days} measure='days' short={format == 'textShort'}
            renderValue={x => <NumberFormat number={x} precision={0} truncateIfFractionalZero delimeter='space' />} />}
        {hours > 0 && <TimeLoc value={hours} measure='hours' short={format == 'textShort'}
            renderValue={x => <NumberFormat number={x} precision={0} truncateIfFractionalZero delimeter='space' />} />}
        {minutes > 0 && <TimeLoc value={minutes} measure='minutes' short={format == 'textShort'} />}
        {secondsVisible && <TimeLoc value={seconds} measure='seconds' short={format == 'textShort'} />}
        {suffix}
    </Space>;
}
Duration.defaultProps = {
    includeSeconds: true,
    convertDaysToHours: false,
    format: 'default',
}
export default Duration;