import { AccelFile, User } from '../../../models';

export default class HubCaller extends User {
    constructor(caller?: Partial<HubCaller>) {
        super();
        if (caller)
            this.update(caller);
    }

    static fromJson(json: any) {
        return new HubCaller({
            id: json.id,
            firstName: json.firstName,
            lastName: json.lastName,
            phone: json.phone,
            email: json.email,
            gender: json.gender,
            avatar: json.avatar ? AccelFile.fromJson(json.avatar) : undefined,
            birthday: json.birthday ? new Date(json.birthday) : undefined,
        });
    }
}
