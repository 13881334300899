import TimeUnit, { TimeUnitUtils } from './TimeUnit';
import { TicksPer, MillisecondsPer } from './HowMany';
import { TimeMeasure } from '../../models';

export default class TimeSpan {
    readonly ticks: number;
    readonly milliseconds: number;
    readonly seconds: number;
    readonly minutes: number;
    readonly hours: number;
    readonly days: number;

    constructor(value: number, units: TimeUnit = TimeUnit.Milliseconds) {
        const ms = TimeUnitUtils.toMilliseconds(value, units);

        this.ticks = ms * TicksPer.Millisecond;
        this.milliseconds = ms;
        this.seconds = ms / MillisecondsPer.Second;
        this.minutes = ms / MillisecondsPer.Minute;
        this.hours = ms / MillisecondsPer.Hour;
        this.days = ms / MillisecondsPer.Day;
    }

    add(span: TimeSpan): TimeSpan {
        return TimeSpan.fromMilliseconds(this.milliseconds + span.milliseconds);
    }

    substract(span: TimeSpan): TimeSpan {
        return TimeSpan.fromMilliseconds(this.milliseconds - span.milliseconds);
    }

    get total(): TimeSpan {
        return this;
    }

    get isZero(): boolean {
        return this.ticks == 0;
    }

    static from(value: number, units: TimeUnit) {
        return new TimeSpan(value, units);
    }

    static fromDays(value: number): TimeSpan {
        return new TimeSpan(value, TimeUnit.Days);
    }

    static fromHours(value: number): TimeSpan {
        return new TimeSpan(value, TimeUnit.Hours);
    }

    static fromMinutes(value: number): TimeSpan {
        return new TimeSpan(value, TimeUnit.Minutes);
    }

    static fromSeconds(value: number): TimeSpan {
        return new TimeSpan(value, TimeUnit.Seconds);
    }

    static fromMilliseconds(value: number): TimeSpan {
        return new TimeSpan(value, TimeUnit.Milliseconds);
    }

    static fromMs(value: number): TimeSpan {
        return new TimeSpan(value, TimeUnit.Milliseconds);
    }

    static fromTicks(value: number): TimeSpan {
        return new TimeSpan(value, TimeUnit.Ticks);
    }

    static fromTimeMeasure(value: number, measure: TimeMeasure): TimeSpan {
        switch (measure) {
            case 'seconds':
                return TimeSpan.fromSeconds(value);
            case 'minutes':
                return TimeSpan.fromMinutes(value);
            case 'hours':
                return TimeSpan.fromHours(value);
            case 'days':
                return TimeSpan.fromDays(value);
            case 'weeks':
                return TimeSpan.fromDays(value * 7);
            case 'months':
                return TimeSpan.fromDays(value * 30);
            default:
                throw new Error("Invalid TimeMeasure.");
        }
    }

    static get zero(): TimeSpan {
        return new TimeSpan(0);
    }
}

// for comparison when sorting
TimeSpan.prototype.toString = function () {
    return this.milliseconds.toString();
}

TimeSpan.prototype.valueOf = function () {
    return this.milliseconds;
}