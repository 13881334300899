import { action } from 'mobx';
import { BaseFilter, ContactGroup, Entity, Scenario } from '..';
import { ISerializable } from '../Entity';
import { isEmpty } from '../../utils';

export default class BillingAddress extends Entity implements ISerializable {
    constructor(address?: Partial<BillingAddress>) {
        super();
        if (address) this.update(address);
    }

    createdDate: moment.Moment;
    company: string;
    firstName: string;
    lastName: string;
    address: string;
    state: string;
    country: string;
    city: string;
    zipCode: string;
    note: string;

    get isEmpty() {
        return isEmpty(this.firstName) && isEmpty(this.lastName) && isEmpty(this.address) && isEmpty(this.state) && isEmpty(this.country) && isEmpty(this.city) && isEmpty(this.zipCode)
    }

    get fullName() {
        const hasFirstName = !isEmpty(this.firstName);
        const hasLastName = !isEmpty(this.lastName);
        if (!hasFirstName && !hasLastName) return '';
        if (hasFirstName && !hasLastName) return this.firstName;
        if (!hasFirstName && hasLastName) return this.lastName;
        return `${this.firstName} ${this.lastName}`;
    }

    toString() {
        return [this.address, this.city, this.zipCode, this.state, this.country, this.fullName, this.company, this.note].filter(x => x).join(', ');
    }

    toJson() {
        return {
            ...this
        }
    }

    clone() {
        return new BillingAddress({
            ...this
        });
    }

    @action update(partnership: Partial<BillingAddress>, allowUndefined = false) {
        super.update(partnership, allowUndefined);
    }

    static fromJson(json: any): BillingAddress {
        return new BillingAddress({
            ...json,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            partnershipVisibleToGroup: json.partnershipVisibleToGroup ? ContactGroup.fromJson(json.partnershipVisibleToGroup) : undefined,
        });
    }
}

export class BillingAddressFilter extends BaseFilter<BillingAddress> {
    constructor(filter?: Partial<BillingAddressFilter>) {
        super();
        if (filter) this.update(filter);
    }

    id: string;

    update(changes: Partial<BillingAddressFilter>) {
        super.update(changes);
    }
}