import { observable, action, computed } from 'mobx';
import { AccelFile, BaseFilter, CertItem, CertSettings, Course, Entity, Scenario } from '..';
import { ISerializable } from '../Entity';
import moment from 'moment';

export default class Cert extends Entity implements ISerializable {
    constructor(c?: Partial<Cert>) {
        super();
        if (c) this.update(c);
    }

    @observable name: string;
    @observable settingsJson: string;
    @observable settings: CertSettings;
    @observable releasedCount: number;
    @observable serialNumber: string;
    @observable nextNumber: number;
    @observable usePadLeft: boolean;
    @observable padLeftLength: number;

    @observable createdDate: moment.Moment;
    @observable updatedDate: moment.Moment | null;

    @observable image: AccelFile;
    @observable items: CertItem[];

    @observable courses: Course[];

    @observable scenario: Scenario;

    @computed get nextPadNumber() {
        return this.usePadLeft
            ? this.nextNumber.toString().padStart(this.padLeftLength ?? 0, '0')
            : this.nextNumber.toString();
    }

    @computed get nextSerialNumber() {
        return `${this.serialNumber ?? ''}${this.nextPadNumber}`;
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            imageId: this.image === null ? null : this.image?.id,
            settingsJson: JSON.stringify(this.settings?.toJson()),
            items: this.items?.map(i => i.toJson()),
            serialNumber: this.serialNumber,
            usePadLeft: this.usePadLeft,
            padLeftLength: this.padLeftLength,
        }
    }

    clone() {
        return new Cert({
            ...this,
            settings: this.settings?.clone(),
            items: this.items?.map(i => i.clone()),
        });
    }

    @action update(c: Partial<Cert>, allowUndefined = false) {
        super.update(c, allowUndefined);
    }

    hasChanges(cert: Cert) {
        return this.name != cert.name
            || this.serialNumber != cert.serialNumber
            || this.usePadLeft != cert.usePadLeft
            || this.padLeftLength != cert.padLeftLength
            || this.settings.hasChanges(cert.settings)
            || !this.items.isEquals(cert.items, (a, b, aIndex, bIndex) => aIndex == bIndex && !a.hasChanges(b));
    }

    static default() {
        return new Cert({
            settings: CertSettings.default(),
        });
    }

    static fromJson(json: any): Cert {
        const cert = new Cert({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : null,
            image: json.image ? AccelFile.fromJson(json.image) : null,
            settings: json.settingsJson ? CertSettings.fromJson(JSON.parse(json.settingsJson)) : undefined,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            items: json.items ? json.items.map(CertItem.fromJson) : [],
        });
        if (cert.scenario)
            cert.scenario.update({ cert: cert });

        return cert;
    }
}


export class CertFilter extends BaseFilter<Cert>{
    constructor(filter?: Partial<CertFilter>) {
        super();
        if (filter) this.update(filter);
    }

    id: string;
    excludeId: string;

    update(changes: Partial<CertFilter>) {
        super.update(changes);
    }
}