import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { Entity, BillingPlan, BillingPaymentMethod, PaymentGatewayType, School } from '..';
import { Currency } from '../../enums';
import { ISerializable } from '../Entity';

export default class SchoolBilling extends Entity implements ISerializable {
    constructor(b?: Partial<SchoolBilling>) {
        super(b);
        if (b) this.update(b);
    }

    @observable balance: number;
    @observable bonusBalance: number;
    @observable credit: number;
    @observable discountOnMainServices: number;
    @observable discountOnExtraServices: number;
    @observable trialDaysCount: number;
    @observable autoRecharge: boolean;
    @observable autoRechargeAmount: number;
    @observable autoRechargeBalance: number;
    @observable agreedBillingPlan: boolean;

    @observable createdDate: moment.Moment;
    @observable currency: Currency;

    @observable plan: BillingPlan;
    @observable paymentMethod: BillingPaymentMethod;
    @observable school: School;

    @computed get totalBalance() {
        return this.balance + this.bonusBalance;
    }

    @computed get totalBalanceWithCredit() {
        return this.totalBalance + this.credit;
    }

    @computed get hasLowBalance() {
        return this.totalBalance < 0 && (this.totalBalance - this.credit) > 0;
    }

    @computed get isLocked() {
        return this.credit == 0
            ? this.totalBalanceWithCredit < 0
            : this.totalBalanceWithCredit <= 0;
    }

    @computed get trialCompletedDate(): moment.Moment | null {
        return this.trialDaysCount <= 0
            ? null
            : moment(this.createdDate).utc().startOf('day').add(this.trialDaysCount, 'days');
    }
    @computed get trialCompletedDateExpired() {
        return this.trialCompletedDate && this.trialCompletedDate > moment();
    }

    @computed get needToAcceptAgreement() {
        return this.plan?.isAgreementRequired && !this.agreedBillingPlan;
    }

    @computed get isProdamusGateway() {
        return this.gatewayType == PaymentGatewayType.Prodamus;
    }

    @computed get minDepositAmount() {
        switch (this.currency) {
            case Currency.RUB: return this.isProdamusGateway ? 50 : 1;
            case Currency.USD: return 1;
            case Currency.EUR: return 1;
            case Currency.IDR: return 1000;
            default: return 0;
        }
    }

    @action update(billing: Partial<SchoolBilling>, allowUndefined = false) {
        super.update(billing, allowUndefined);
    }

    toJson() {
        return { ...this };
    }

    clone() {
        return new SchoolBilling({
            ...this,
            plan: this.plan?.clone(),
            paymentMethod: this.paymentMethod?.clone()
        });
    }

    static fromJson(json: any): SchoolBilling {
        return new SchoolBilling({
            id: json.id,
            balance: json.balance,
            bonusBalance: json.bonusBalance,
            credit: json.credit,
            trialDaysCount: json.trialDaysCount,
            discountOnMainServices: json.discountOnMainServices,
            discountOnExtraServices: json.discountOnExtraServices,
            autoRecharge: json.autoRecharge,
            autoRechargeAmount: json.autoRechargeAmount,
            autoRechargeBalance: json.autoRechargeBalance,
            agreedBillingPlan: json.agreedBillingPlan,
            currency: json.currency,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            plan: json.billingPlan ? BillingPlan.fromJson(json.billingPlan) : undefined,
            paymentMethod: json.autoRechargePaymentMethod ? BillingPaymentMethod.fromJson(json.autoRechargePaymentMethod) : undefined,
            school: json.school ? School.fromJson(json.school) : undefined
        });
    }

    @computed get gatewayType(): PaymentGatewayType {
        switch (this.currency) {
            case Currency.RUB:
                return PaymentGatewayType.Prodamus;
            default:
                return PaymentGatewayType.AuthorizeNet;
        }
    }
}
