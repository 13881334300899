import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AccelFile } from '../../models';
import { Context } from '../AccelProvider/AccelProvider';
import { DownloadOutlined, LoadingOutlined, PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { TimeSpan, combineClasses, saveAs } from '../../utils';
import { AudioWaveform } from './components/AudioWaveform/AudioWaveform';
import { AudioPlayerDuration } from './components/Duration/Duration';
import { PlaybackRateSelect } from './components/PlaybackRateSelect/PlaybackRateSelect';
import useLocalStorage from '../hooks/useLocalStorage';
import { useAudioContext } from '../hooks/useAudioRecorder';
import useDebouncedCallback from '../hooks/useDebouncedCallback';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import styles from './AudioPlayer.module.scss';

type Props = {
    file: AccelFile;
    defaultPlaybackRate?: number;
    className?: string;
    size?: SizeType;
    downloadable?: boolean;
    style?: React.CSSProperties;
    onPlaybackRateChange?: (rate: number) => void;
}
const AudioPlayer: React.FC<Props> = ({ file, defaultPlaybackRate, size, downloadable, style, onPlaybackRateChange, className }) => {

    const { api, fileProvider } = useContext(Context);

    const [playbackRate, setPlaybackRate] = useLocalStorage('global.audioPlaybackRate', defaultPlaybackRate ?? 1, true, x => parseFloat(x));

    const audioRef = useRef<HTMLAudioElement>();
    const [loading, setLoading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [currentSec, setCurrentSec] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [inited, setInited] = useState(false);

    const [getAudioMeta] = useAudioContext();

    const downloadAudio = useCallback(async () => {
        const url = (file.isExternalFile || file.previewAudio)
            ? fileProvider.getUrl(file, true)!
            : await fetchUrl();
        saveAs(url, false, file.name);
    }, [file]);

    const fetchUrl = useCallback(async () => {
        const result = await api.file.download(file.id);
        return result.response?.body;
    }, [file]);

    const handleCreateWaveform = useCallback(async () => {
        audioRef.current!.removeEventListener('canplaythrough', handleCreateWaveform);
        if (file.audioMeta?.frequencyLevels) return;

        try {
            // doesn't work via localhost due to cors (use https/ngrok)
            const res = await fetch(audioRef.current!.src);
            const blob = await res.blob();
            const meta = await getAudioMeta(blob);
            file.audioMeta = meta;

            // ignore result
            await api.file.save(file);
        } catch (e) {
            console.error(e);
        }
    }, [file]);

    const handleTimeUpdate = useCallback(async () => {
        setCurrentSec(audioRef.current!.currentTime);
        setPercent(audioRef.current!.currentTime / audioRef.current!.duration * 100);
    }, [])

    const handleEnded = useCallback(async () => {
        setPercent(0);
        setCurrentSec(0);
        setPlaying(false);
    }, []);

    const initPlayer = useCallback(async () => {
        setLoading(true);
        const url = (file.isExternalFile || file.previewAudio)
            ? fileProvider.getUrl(file, true)!
            : await fetchUrl();

        if (!url) {
            console.error("Couldn't fetch audio url");
            return;
        }

        audioRef.current = document.createElement('audio');
        audioRef.current.src = url;
        audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.addEventListener('canplaythrough', handleCreateWaveform);
        audioRef.current.addEventListener('ended', handleEnded);
        setLoading(false);
        setInited(true);
    }, [file]);

    const playHandle = useCallback(async () => {
        if (!inited) await initPlayer();
        setPlaying(true);
    }, [file, inited]);

    const seekHandle = useDebouncedCallback((percent: number) => {
        try {
            audioRef.current!.currentTime = percent / 100 * audioRef.current!.duration;
        }
        catch (e) {
        }
    }, [], TimeSpan.fromMs(100));

    useEffect(() => {
        if (!audioRef.current) return;
        playing ? audioRef.current.play() : audioRef.current.pause();
    }, [playing]);

    useEffect(() => {
        if (audioRef.current)
            audioRef.current.playbackRate = playbackRate;
    }, [playbackRate]);

    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
                audioRef.current.removeEventListener('ended', handleEnded);
                audioRef.current.pause();
                audioRef.current.remove();
            }
        }
    }, []);

    const freqLevels = useMemo(() => file.audioMeta?.frequencyLevels ?? Array.from({ length: 128 }, () => 0), [file.audioMeta]);

    return <div className={combineClasses('flex align-center gap-10 w-100', styles.audio_player, className)} data-size={size ?? 'middle'} style={style}>
        {!playing
            ? <Button type='link' icon={loading ? <LoadingOutlined /> : <PlayCircleFilled />} className={styles.audio_player_button} onClick={playHandle} />
            : <Button type='link' icon={<PauseCircleFilled />} className={styles.audio_player_button} onClick={() => setPlaying(false)} />}
        <div className='flex-1 lh-18'>
            <AudioWaveform levels={freqLevels} playedPercent={percent} onSeek={seekHandle} />
            <div className='flex-1 flex justify-between align-center'>
                <AudioPlayerDuration currentSec={currentSec} durationInSec={file.audioMeta?.durationInSec ?? 0} className='text-placeholder' />
                <PlaybackRateSelect playbackRate={playbackRate}
                    onPlaybackRateChange={rate => {
                        setPlaybackRate(rate);
                        onPlaybackRateChange?.(rate);
                    }}
                    className='text-placeholder c-pointer' />
            </div>
        </div>
        {downloadable === true && <Button onClick={downloadAudio} type='link' size={size} icon={<DownloadOutlined />} className='p-0' />}
    </div>;
}

export default AudioPlayer;