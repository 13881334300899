import type { DragSource, DropTarget } from '../models';
import { useRef, useContext, useCallback, useEffect } from 'react';
import { useLocalStore } from 'mobx-react';
import { DragDropContext } from '../DragDropProvider';
import { runInAction } from 'mobx';
import DragDropManager from '../DragDropManager';
import { debounce } from 'lodash';

export type DropProps<T> = {
    accept?: string[];
    onOver?: (source: DragSource<T>, position: RelativePosition, direction: Direction, e: DragEvent) => void;
    onEnter?: (source: DragSource<T>, position: RelativePosition, e: DragEvent) => void;
    onLeave?: (source: DragSource<T>, e: DragEvent) => void;
    onDrop?: (source: DragSource<T>, position: RelativePosition, e: DragEvent) => void;
    canDrop?: (source: DragSource<T>, position: RelativePosition, e?: DragEvent) => boolean;
    droppable?: () => boolean;
    dragThreshold?: { x: number, y: number }
}

export type RelativePosition = {
    vertical: 'top' | 'bottom',
    horizontal: 'left' | 'right'
}

export type Direction = {
    vertical: 'upwards' | 'downwards',
    horizontal: 'left' | 'right'
}


export type DragState<T> = {
    canDrop: boolean;
    source: DragSource<T>;
    position: RelativePosition | null;
}

export type DropOutput<T> = {
    dragState: DragState<T> | null;
    manager: DragDropManager;
}

type DropState<T> = DropOutput<T> & {
    target: DropTarget | null;
    targetBoundingClientRect: DOMRect | null;
    source: DragSource<T> | null;
    canDrop: boolean;
    prevOverEv?: DragEvent;
    overEv?: DragEvent;
    position: RelativePosition | null;
    direction: Direction | null;
}

export type DropReturn<T, TDropRef extends HTMLElement> = [
    DropOutput<T>,
    React.RefObject<TDropRef>
];

export default function useDrop<T = {}, TDropRef extends HTMLElement = HTMLDivElement>(props: DropProps<T>): DropReturn<T, TDropRef> {
    const targetRef = useRef<TDropRef>(null);
    const { manager } = useContext(DragDropContext);

    const state = useLocalStore<DropState<T>>(() => ({
        manager,
        target: null,
        get targetBoundingClientRect(): DOMRect | null {
            if (state.target == null) return null;
            return state.target.el.getBoundingClientRect();
        },
        get source(): DragSource<T> | null {
            if (!state.target || !manager.isCurrentTarget(state.target))
                return null;
            return manager.currentSource;
        },
        get dragState(): DragState<T> | null {
            if (!state.source) return null;
            return {
                canDrop: state.canDrop,
                source: state.source,
                position: state.position
            };
        },
        get canDrop() {
            if (state.source == null) return false;
            if (props.droppable && !props.droppable()) return false
            if (props.accept && state.source.type
                && !props.accept.includes(state.source.type))
                return false;
            if (props.canDrop && !props.canDrop(state.source, { ...state.position! }, state.overEv)) return false;
            return true;
        },
        get position(): RelativePosition | null {
            if (!state.overEv || state.target == null) return null;
            const e = state.overEv;
            let rect = state.target.el.getBoundingClientRect();
            return {
                vertical: (e.y - rect.y) <= (rect.bottom - e.y) ? 'top' : 'bottom',
                horizontal: (e.x - rect.x) <= (rect.right - e.x) ? 'left' : 'right',
            };
        },
        get direction(): Direction | null {
            if (!state.prevOverEv || !state.overEv) return null;
            return {
                horizontal: state.prevOverEv.clientX < state.overEv.clientX ? 'right' : 'left',
                vertical: state.prevOverEv.clientY > state.overEv.clientY ? 'upwards' : 'downwards',
            }
        }
    }));

    const onDragEnter = useCallback((e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (manager.isCurrentTarget(state.target!)) return;
        manager.setCurrentTarget(state.target, () => {
            log('onDragEnter');
            props.onEnter?.(state.source!, state.position!, e)
        });
        runInAction(() => {
            state.overEv = e;
        });
        return false;
    }, []);

    const onDragLeave = useCallback((e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!manager.isCurrentTarget(state.target!)) {
            // target has been already changed
            props.onLeave?.(state.source!, e);
            log('onDragLeave');
            return
        };

        // check if the current position is inside the target
        let rect = state.targetBoundingClientRect!;
        if (e.x >= (rect.left + rect.width) || e.x <= rect.left || e.y >= (rect.top + rect.height) || e.y <= rect.top) {
            manager.setCurrentTarget(null, () => {
                log('onDragLeave');
                props.onLeave?.(state.source!, e);
            });
            return false;
        }
    }, []);

    const onDragOver = useCallback((e: DragEvent) => {
        e.preventDefault();
        // ignore events using threshold
        if (state.overEv
            && Math.abs(e.x - state.overEv.x) < (props.dragThreshold?.x ?? 5)
            && Math.abs(e.y - state.overEv.y) < (props.dragThreshold?.y ?? 5))
            return;
        return onOver(e);
    }, []);

    const onOver = debounce((e: DragEvent) => {
        if (manager.currentTarget == null) return true;
        log('onDragOver');
        runInAction(() => {
            state.prevOverEv = state.overEv;
            state.overEv = e;
        });
        if (!state.canDrop) return true;
        props.onOver?.(state.source!, state.position!, state.direction!, e);
        return false;
    }, 10, { leading: true });

    const onDrop = useCallback((e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        log('onDrop');
        if (state.canDrop) {
            props.onDrop?.(state.source!, { ...state.position! }, e);
        }

        manager.setCurrentSource(null);
        manager.setCurrentTarget(null);
        runInAction(() => {
            state.overEv = undefined;
            state.prevOverEv = undefined;
        });
        return false;
    }, []);

    const log = (msg: string) => {
        console.logDev(msg);
    }

    useEffect(() => {
        const dropElement = targetRef.current;
        if (dropElement == null)
            return;
        const target = manager.addTarget({
            el: dropElement,
            accept: props.accept
        });
        runInAction(() => state.target = target);

        dropElement.setAttribute('droppable', 'true');
        dropElement.addEventListener('dragenter', onDragEnter);
        dropElement.addEventListener('dragleave', onDragLeave);
        dropElement.addEventListener('dragover', onDragOver);
        dropElement.addEventListener('drop', onDrop);

        return () => {
            manager.deleteTarget(state.target!);

            dropElement.removeAttribute('droppable');
            dropElement.removeEventListener('dragenter', onDragEnter);
            dropElement.removeEventListener('dragleave', onDragLeave);
            dropElement.removeEventListener('dragover', onDragOver);
            dropElement.removeEventListener('drop', onDrop);
        }
    }, [targetRef]);

    return [state, targetRef];
}