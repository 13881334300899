import { observable, action } from 'mobx';
import { Entity } from '../..';
import VoximplantSettings from './Settings/VoximplantSettings';
import ZadarmaSettings from './Settings/Zadarma/ZadarmaSettings';
import OnlinePbxSettings from './Settings/OnlinePbx/OnlinePbxSettings';
import NovofonSettings from './Settings/Novofon/NovofonSettings';
import { ISerializable, Updatable } from '../../Entity';

export enum CallAccountType {
    Voximplant = 'voximplant',
    Zadarma = 'zadarma',
    OnlinePbx = 'onlinePbx',
    Novofon = 'novofon',
    MangoOffice = 'mangoOffice'
}

export default class CallAccount extends Entity implements ISerializable {
    constructor(callAccount?: Partial<CallAccount>) {
        super(callAccount);
        if (callAccount) this.update(callAccount);
    }

    @observable externalId: string;
    @observable type: CallAccountType;
    @observable active: boolean;
    @observable saveCallRecords: boolean;
    @observable settings: any;

    get voximplantSettings(): VoximplantSettings {
        return new VoximplantSettings(this.settings);
    }

    get zadarmaSettings(): ZadarmaSettings {
        return new ZadarmaSettings(this.settings);
    }

    get novofonSettings(): NovofonSettings {
        return new NovofonSettings(this.settings);
    }

    get onlinePbxSettings(): OnlinePbxSettings {
        return new OnlinePbxSettings(this.settings);
    }

    get mangoOfficeSettings(): MangoOfficeSettings {
        return new MangoOfficeSettings(this.settings);
    }

    clone() {
        return new CallAccount(this);
    }

    toJson() {
        return { ...this };
    }

    @action
    update(callAccount: Partial<CallAccount>, allowUndefined = false) {
        super.update(callAccount, allowUndefined);
    }

    static fromJson(json: any): CallAccount {
        return new CallAccount({
            ...json,
            settings: json.settingsJson
                ? JSON.parse(json.settingsJson)
                : undefined
        });
    }
}

export class MangoOfficeSettings extends Updatable {
    public salt: string;

    constructor(settings?: Partial<MangoOfficeSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<MangoOfficeSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}