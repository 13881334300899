import { action } from "mobx";
import {CustomFieldValue, PipelineField} from "../../..";

export default class PipelineFieldValue extends CustomFieldValue<PipelineField> {
    constructor(attr?: Partial<PipelineFieldValue>) {
        super(attr);
        if (attr) this.update(attr);
    }

   
    @action update(attr: Partial<PipelineFieldValue>, allowUndefined = false) {
        super.update(attr, allowUndefined);
    }

    copy(): PipelineFieldValue {
        return new PipelineFieldValue({
            id: this.id,
            customField: this.customField,
            valueString: this.valueString,
            valueNumeric: this.valueNumeric,
            valueBoolean: this.valueBoolean,
            valueDateTime: this.valueDateTime,
            valueDate: this.valueDate,
            valueList: this.valueList,
            valueMultiList: this.valueMultiList?.slice(),
        });
    }

    static fromJson(json: any) {
        const base = CustomFieldValue.fromJson(json);
        return new PipelineFieldValue({
            ...base,
            customField: json.field ? PipelineField.fromJson(json.field) : undefined
        });
    }
}