import IDisposable from '../../../utils/Disposable/IDisposable';
import HubSubscription from "./HubSubscription";

export default class HubUnsubscription implements IDisposable {
    protected unsubscribed: boolean = false;
    constructor(private subscription: HubSubscription<any>) {
    }

    public dispose(): void {
        if (this.unsubscribed)
            throw new Error('Already unsubscribed');
        this.subscription.connection.off(this.subscription.method, this.subscription.callback);
        this.unsubscribed = true;
        console.logDev(`[HubClient] Unsubscribed '${this.subscription.method}'`, this.subscription);
    }
}
