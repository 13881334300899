import { debounce } from 'lodash';
import React, { Key, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { combineClasses } from '../../utils';
import { Context } from '../AccelProvider/AccelProvider';
import TabPane, { TabPaneProps } from './components/TabPane';
import styles from './Tabs.module.scss';

type TabsProps = {
    defaultKey?: Key;
    size?: 'small' | 'middle' | 'large';
    activeKey?: Key;
    destroyInactiveTabPane?: boolean;
    destroyInactiveTabPaneKeys?: Key[];
    className?: string;
    tabNavClassName?: string;
    tabNavListClassName?: string;
    holderClassName?: string;
    onChange?: (key: string) => void;
    tabBarExtraContent?: {
        left?: React.ReactNode;
        right?: React.ReactNode;
    },
    tabProps?: (key: string) => any;
}
const Tabs: React.FC<TabsProps> & { TabPane: typeof TabPane } = ({ size, activeKey, defaultKey, children, className, tabNavClassName, tabNavListClassName, holderClassName, destroyInactiveTabPane, destroyInactiveTabPaneKeys, tabBarExtraContent, tabProps, onChange, }) => {
    const panes = useMemo(() => React.Children.toArray(children) as React.ReactElement<TabPaneProps, typeof TabPane>[], [children]);

    const [selectedKey, setSelectedKey] = useState<Key | null>();
    const selectedKeyRef = useRef<Key | null>();

    const tabListRef = useRef<HTMLDivElement>(null);
    const [tabMap] = useState<Map<Key, Element>>(new Map<Key, HTMLElement>());
    const [tabPaneCache] = useState<Map<Key, React.ReactNode>>(new Map<Key, React.ReactNode>());
    const { deviceStore } = useContext(Context);

    const scrollTo = useCallback((key: Key) => {
        let targetTabOffset = 0;
        for (const tab of Array.from(tabMap.keys())) {
            if (tab != key) {
                targetTabOffset += tabMap.get(tab)?.clientWidth ?? 0;
                continue;
            }
            break;
        }
        const list = tabListRef.current!;
        // need to scroll left
        if (list.scrollLeft >= targetTabOffset) {
            list.scrollLeft = targetTabOffset;
            return;
        }
        const totalWidth = list.scrollLeft + list.clientWidth;
        const hiddenWidth = totalWidth - targetTabOffset;
        const offset = tabMap.get(key)!.clientWidth - hiddenWidth;
        // if target tab is partially displayed
        if (offset > 0)
            list.scrollLeft += offset;
    }, []);

    const selectTab = useCallback((key: Key) => {
        if (selectedKey
            && (destroyInactiveTabPane === true
                || destroyInactiveTabPaneKeys?.includes(selectedKey))) {
            tabPaneCache.delete(selectedKey!);
        }
        setSelectedKey(key);
        selectedKeyRef.current = key;
        const pane = panes.find(x => (x.key as string).substring(2) == key);
        if (!pane) return;
        tabPaneCache.set(key, panes.find(x => (x.key as string).substring(2) == key));
        scrollTo(key)
        onChange?.(key as string);
    }, [selectedKey, tabPaneCache]);

    const renderTabPane = useCallback((key: Key) => {
        if (tabPaneCache.has(key)) return tabPaneCache.get(key);
        return null;
    }, [tabPaneCache]);

    useEffect(() => {
        if (activeKey === undefined) return;
        selectTab(activeKey);
    }, [activeKey]);

    useEffect(() => {
        if (activeKey) {
            selectTab(activeKey);
            return;
        }
        if (defaultKey) {
            selectTab(defaultKey);
            return;
        }
        if (panes.length > 0) {
            const firstActiveTab = panes.find(x => x.props.disabled !== true);
            if (firstActiveTab) selectTab((firstActiveTab.key as string).substring(2));
        }
    }, []);

    useEffect(() => {
        if (!tabListRef.current) return;
        const onWheel = (e: WheelEvent) => {
            e.preventDefault();
            e.stopPropagation();
            tabListRef.current!.scrollLeft = tabListRef.current!.scrollLeft + e.deltaY;
        };
        tabListRef.current.addEventListener('wheel', onWheel);
        return () => tabListRef.current?.removeEventListener('wheel', onWheel);
    }, []);

    useEffect(() => {
        const onResize = debounce(() => {
            if (selectedKeyRef.current && tabListRef.current)
                scrollTo(selectedKeyRef.current);
        }, 200);
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    useEffect(() => {
        if (!tabListRef.current) return;
        const el = tabListRef.current;
        const onTouchStart = (e: TouchEvent) => {
            if (!deviceStore.isTouch) return;
            const touch = e.targetTouches[0];
            const onTouchMove = (e: TouchEvent) => {
                const offset = touch.pageX - e.targetTouches[0].pageX;
                el.scrollLeft += offset / 10;
            };
            const onTouchEnd = (e: TouchEvent) => {
                el.removeEventListener('touchmove', onTouchMove);
                el.removeEventListener('touchend', onTouchEnd);
            };
            el.addEventListener('touchmove', onTouchMove);
            el.addEventListener('touchend', onTouchEnd);
        };
        el.addEventListener('touchstart', onTouchStart);
        return () => el.removeEventListener('touchstart', onTouchStart);
    }, []);

    return <div className={combineClasses(styles.tabs, 'm-0 p-0 fs-14 flex flex-col h-100', className)}>
        <div className={combineClasses(styles.tabs_nav, 'bg-white flex align-center', tabNavClassName)} data-size={size}>
            <div className={combineClasses(styles.tabs_nav_wrap, 'relative flex flex-auto align-center overflow-hidden')}>
                {tabBarExtraContent?.left}
                <div ref={tabListRef} className={combineClasses(styles.tabs_nav_list, 'relative flex flex-auto overflow-hidden', tabNavListClassName)} >
                    {panes.map(x => {
                        const key = (x.key as string).substr(2);
                        return <div key={key} ref={ref => tabMap.set(key, ref!)}
                            className={combineClasses(styles.tabs_tab, 'no-select',
                                selectedKey == key ? styles.tabs_tab__active : undefined,
                                x.props.disabled === true ? styles.tabs_tab__disabled : undefined,
                                x.props.tabClassName)}
                            onClick={e => x.props.disabled !== true && selectTab(key)}
                            {...tabProps?.(key)}>
                            {x.props.tab}
                            <div className={styles.tabs_tab_activity_bar}></div>
                        </div>;
                    })}
                </div>
                {tabBarExtraContent?.right}
            </div>
        </div>
        <div className={combineClasses(styles.tabs_content_holder, 'h-100', holderClassName)}>
            <div className={styles.tabs_content}>
                {panes.map(x => {
                    const key = (x.key as string).substr(2);
                    const isSelected = selectedKey == key;
                    return <div key={key}
                        className={combineClasses(styles.tabs_tabpane,
                            isSelected ? styles.tabs_tabpane__active : undefined,
                            x.props.className)}>
                        {renderTabPane(key)}
                    </div>
                })}
            </div>
        </div>
    </div>;
}

Tabs.TabPane = TabPane;
Tabs.defaultProps = {
    size: 'middle',
    destroyInactiveTabPane: false
}
export default Tabs;
export { TabPane };