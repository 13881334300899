
import { observable, action } from 'mobx';
import { Entity } from '../..';
import type { ForumReactionType } from '../reaction/ForumReactionType';

export default class ForumReaction extends Entity {
    constructor(reaction?: Partial<ForumReaction>) {
        super(reaction);
        if (reaction) this.update(reaction);
    }

    @observable type: ForumReactionType;

    static fromJson(json: any): ForumReaction {
        return new ForumReaction({
            ...json
        });
    }

    @action
    update(comment: Partial<ForumReaction>) {
        super.update(comment);
    }
}

