import React, { useMemo } from "react";
import { Button, message, Tooltip } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { AccelFile } from '../../../../models';
import { useDropzone } from 'react-dropzone';
import { FileSize, Localization } from '../../../../utils';
import Humanize from 'humanize-plus';
type Props = {
    loc: Localization;
    label?: React.ReactNode;
    maxFiles?: number;
    maxFileSize?: FileSize;
    disabled: boolean;
    forbiddenFileExtensions?: string[];
    iconClassname?: string;
    btnClassName?: string;
    onAttach: (files: AccelFile[]) => void;
}
const FileInput: React.FC<Props> = ({ loc, label, maxFiles, disabled, forbiddenFileExtensions, maxFileSize, iconClassname, btnClassName, onAttach }) => {
    const dropzone = useDropzone({
        onDrop: (x) => {
            let files = x.map(_x => AccelFile.fromFile(_x));
            if (forbiddenFileExtensions) {
                files = files.filter(x => !forbiddenFileExtensions!.includes(x.extension));
            }
            if (maxFileSize) {
                const hasIgnoredItems = files.some(x => x.fileSize.bytes > maxFileSize!.bytes);
                files = files.filter(x => x.fileSize.bytes <= maxFileSize!.bytes);
                if (hasIgnoredItems)
                    message.warning({ content: loc.word('File.maxFileSizeTemplate', { default: `Max file size: {0}`, args: [Humanize.fileSize(maxFileSize.bytes, 0)] }) });
            }
            onAttach(files);
        },
        disabled
    });

    const tooltipTitle = useMemo(() => {
        let tooltip = loc.word('Attach files');
        if (maxFiles)
            tooltip += ` (${loc.word('Max', { case: 'lower' })}: ${maxFiles})`;
        return tooltip;
    }, [maxFiles]);

    return <div {...dropzone.getRootProps()}>
        <input {...dropzone.getInputProps()} />
        <Tooltip placement='topRight' title={tooltipTitle}>
            <Button type='link'
                className={btnClassName ?? "file-input-btn"}
                size='large'
                disabled={disabled}
                icon={<PaperClipOutlined className={iconClassname} />}>
                {label}
            </Button>
        </Tooltip>
    </div>;
}

export default FileInput;