import { Updatable } from '../../..';

export default class MidtransSettings extends Updatable {
    public serverKey: string;
    public clientKey: string;
    public isTest: boolean;

    update(entity: Partial<MidtransSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}