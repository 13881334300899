import { action, observable } from "mobx";
import { Updatable } from "../../../..";

export default class WebinarRegistrationTermOfServiceFieldSettings extends Updatable {
    constructor(settings?: Partial<WebinarRegistrationTermOfServiceFieldSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable terms: string;
    @observable text: string;
    @observable termsDefaultAccepted: boolean;

    @action update(settings: Partial<WebinarRegistrationTermOfServiceFieldSettings>, allowUndefined: boolean = false) {
        super.update(settings, allowUndefined);
    }

    hasChanged(settings: WebinarRegistrationTermOfServiceFieldSettings) {
        return (this.text != settings.text) || (this.termsDefaultAccepted != settings.termsDefaultAccepted);
    }

    copy() {
        return new WebinarRegistrationTermOfServiceFieldSettings({
            ...this,
        });
    }

    static fromJson(json: any) {
        return new WebinarRegistrationTermOfServiceFieldSettings({
            ...json
        });
    }
}