import { action, computed } from 'mobx';
import moment from 'moment';
import Entity, { ISerializable } from '../../Entity';
import BaseFilter from '../../BaseFilter';
import Bowser from 'bowser';

export default class UserWebPushToken extends Entity implements ISerializable {
    constructor(n?: Partial<UserWebPushToken>) {
        super(n);
        if (n) this.update(n);
    }

    token: string;
    userAgent: string;

    createdDate: moment.Moment;

    @computed get parsedUserAgent() {
        if (!this.userAgent) return null;
        return Bowser.parse(this.userAgent);
    }

    @action
    update(n: Partial<UserWebPushToken>) {
        super.update(n);
    }

    toJson() {
        return {
            ...this
        };
    }

    static fromJson(json: any): UserWebPushToken {
        return new UserWebPushToken({
            id: json.id,
            token: json.token,
            userAgent: json.userAgent,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
        });
    }
}

export class UserWebPushTokenFilter extends BaseFilter<UserWebPushToken> {
    constructor(filter?: Partial<UserWebPushTokenFilter>) {
        super();
        if (filter) this.update(filter);
    }

    update(changes: Partial<UserWebPushTokenFilter>) {
        super.update(changes);
    }
}