import { Updatable } from '../../..';
import { PaymentGatewayPaymentMethodType, PaymentGatewayPaymentObjectType, PaymentGatewayTaxType, PaymentGatewayTaxationType } from '../PaymentGatewayInfo';

export default class TinkoffSettings extends Updatable {
    public terminalKey: string;
    public password: string;
    public tax: PaymentGatewayTaxType = PaymentGatewayTaxType.None;
    public taxation: PaymentGatewayTaxationType = PaymentGatewayTaxationType.Osn;
    public paymentMethod: PaymentGatewayPaymentMethodType = PaymentGatewayPaymentMethodType.FullPayment;
    public paymentObject: PaymentGatewayPaymentObjectType = PaymentGatewayPaymentObjectType.Payment;

    update(entity: Partial<TinkoffSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}