import { action, computed, observable } from 'mobx';

export default class RecyclableListStore<T extends { id: string }> {

    constructor(private buffer: number, defaultItems?: T[]) {
        if (defaultItems)
            this.push(defaultItems);
    }

    @computed get empty() {
        return this.length == 0;;
    }

    @computed get length() {
        return this.items.length;
    }

    @observable items: T[] = [];
    @computed get available() {
        return this.buffer - this.items.length;
    }

    @action push(items: T[]) {
        const copy = this.items.slice();
        if (this.available < items.length) {
            copy.splice(0, items.length - this.available);
        }
        copy.push(...items);
        this.items = copy;
    }

    @action remove(id: string) {
        const index = this.items.findIndex(x => x.id == id);
        if (index == -1) return;
        const copy = this.items.slice();
        copy.splice(index, 1);
        this.items = copy;
    }

    @action removeRange(ids: string[]) {
        const copy = this.items.slice();
        for (const id of ids) {
            const index = copy.findIndex(x => x.id == id);
            if (index == -1) continue;
            copy.splice(index, 1);
        }
        this.items = copy;
    }

    @action removeRangeByFilter(predicate: (item: T) => boolean) {
        const itemsToRemove = this.items.filter(predicate);
        this.removeRange(itemsToRemove.map(x => x.id));
    }

    @action clear() {
        this.items = [];
    }
}
