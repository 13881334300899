import { action, observable } from 'mobx';
import { CertItemSettings } from '../..';

export class CertItemImageSettings extends CertItemSettings {
    constructor(settings?: Partial<CertItemImageSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable imageUrl: string | null;

    @observable imageWidth: number;
    @observable imageHeight: number;

    @action recalculateScale() {
        this.scaleX = this.imageWidth == 0 ? 1 : this.width / this.imageWidth;
        this.scaleY = this.imageHeight == 0 ? 1 : this.height / this.imageHeight;
    }

    @action update(c: Partial<CertItemImageSettings>, allowUndefined = false) {
        super.update(c, allowUndefined);
    }

    clone() {
        return new CertItemImageSettings(this);
    }

    hasChanges(settings: CertItemImageSettings): boolean {
        return this.imageUrl != settings.imageUrl
            || this.imageWidth != settings.imageWidth
            || this.imageHeight != settings.imageHeight
            || super.hasChanges(settings);
    }

    static default(changes?: Partial<CertItemImageSettings>) {
        return new CertItemImageSettings({
            angle: 0,
            imageUrl: null,
            imageWidth: 0,
            imageHeight: 0,
            positionX: 0,
            positionY: 0,
            scaleX: 1,
            scaleY: 1,
            // TODO auto detect size of placeholder
            width: 416,
            height: 234,
            ...changes
        });
    }
}
