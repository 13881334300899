import { Updatable } from '../../..';

export default class CyberSourceSettings extends Updatable {
    public merchantId: string;
    public key: string;
    public secretKey: string;
    public testMode: boolean;

    constructor(settings?: Partial<CyberSourceSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<CyberSourceSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}