import Api from '../..';
import { FileManagerItemFilter } from '../../../models';
import { WatermarkSettings } from '../../../models/video/video';
import { CreateFileManagerDirectoryRequest, CreateFileManagerDirectoryResponse } from "./types/create-directory";
import { CreateFileManagerItemRequest, CreateFileManagerItemResponse } from "./types/create-item";
import { FetchFileManagerItemsRequest, FetchFileManagerItemsResponse } from "./types/fetch";
import { FetchFileManagerItemRequest, FetchFileManagerItemResponse } from './types/fetch-item';
import { UpdateFileManagerItemRequest, UpdateFileManagerItemResponse } from "./types/update-item";

export default class FileManagerApi {
	constructor(private api: Api) { }

	public async fetchItems(request: FetchFileManagerItemsRequest) {
		return await this.api.execute(request, FetchFileManagerItemsResponse);
	}

	public async fetchItem(request: FetchFileManagerItemRequest) {
		return await this.api.execute(request, FetchFileManagerItemResponse);
	}

	public async createItem(request: CreateFileManagerItemRequest) {
		return await this.api.execute(request, CreateFileManagerItemResponse);
	}

	public async createDirectory(request: CreateFileManagerDirectoryRequest) {
		return await this.api.execute(request, CreateFileManagerDirectoryResponse);
	}

	public async updateItem(request: UpdateFileManagerItemRequest) {
		return await this.api.execute(request, UpdateFileManagerItemResponse);
	}

	remove = (filter: FileManagerItemFilter) => this.api.post<string[]>(`/file-manager/delete`, { filter });
	move = (filter: FileManagerItemFilter, parentId: string | null) => this.api.post<string[]>(`/file-manager/move`, { filter, parentId });
	restore = (filter: FileManagerItemFilter) => this.api.post<string[]>(`/file-manager/restore`, { filter });
	setWatermark = (filter: FileManagerItemFilter, settings: WatermarkSettings) => this.api.post<string[]>(`/file-manager/watermark`, { filter, settings });
}
