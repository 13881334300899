import { observable, action, computed } from 'mobx';
import moment, { type Moment } from 'moment';
import { Contact, Entity } from '../..';
import type { TelegramGroupUserState } from '../../Application/TgGroup/TgGroupUser/TgGroupUser';
import { isEmpty } from '../../../utils';

export enum ContactCommunicationType {
    Email = 0,
    VK = 1,
    TG = 2,
    VB = 3,
    FB = 4,
    WA = 5,
    IG = 6
}

export default class ContactCommunication extends Entity {
    constructor(user?: Partial<ContactCommunication>) {
        super(user);
        if (user) this.update(user);
    }

    @observable externalId: string;
    @observable externalName: string;
    @observable externalFirstName: string;
    @observable externalLastName: string;
    @observable type: TelegramGroupUserState;

    @observable createdDate: Moment;
    @observable updatedDate: Moment | null;

    @observable contact: Contact | null;

    @action update(g: Partial<ContactCommunication>, allowUndefined = false) {
        super.update(g, allowUndefined);
    }

    @computed get hasNameOrFullName() {
        return this.externalName || this.externalFullName;
    }

    @computed get externalFullName() {
        const emptyFirstName = isEmpty(this.externalFirstName);
        const emptyLastName = isEmpty(this.externalLastName);
        if (emptyFirstName && emptyLastName)
            return null;
        if (emptyFirstName)
            return this.externalLastName;
        if (emptyLastName)
            return this.externalFirstName;
        return `${this.externalFirstName} ${this.externalLastName}`;
    }

    static fromJson(json: any): ContactCommunication {
        return new ContactCommunication({
            ...json,
            softDeletedDate: json.softDeletedDate ? moment(json.softDeletedDate) : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : null,
            contact: json.student ? Contact.fromJson(json.student) : null
        });
    }
}