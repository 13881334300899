import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { Entity, Scenario, Widget } from '..';
import { isEmpty } from '../../utils';
import { QuizSettings } from './QuizSettings';

export type FormTemplateType = 'empty' | 'feedback' | 'quiz' | 'test' | 'questionnaire' | 'survey';
export default class Quiz extends Entity {
    constructor(product?: Partial<Quiz>) {
        super(product);
        if (product) this.update(product);
    }

    @observable title: string;
    @observable description: string;
    // @observable settingsJson: string;
    @observable settings: QuizSettings;
    @observable additionalHtml: string;

    @observable createdDate: Moment;
    @observable updatedDate?: Moment;

    @observable snapshotDate?: Moment
    @observable snapshotId?: string;

    @observable sentCount: number;

    @observable scenario: Scenario;
    widget: Widget;

    @computed get isPublished() {
        return this.snapshotDate != undefined;
    }

    @computed get hasUnpublishedChanges() {
        if (!this.snapshotDate)
            return true;
        return (this.updatedDate ?? this.createdDate)?.isAfter(this.snapshotDate);
    }

    @computed get hasAdditionalHtml() {
        return !isEmpty(this.additionalHtml);
    }

    @action update(q: Partial<Quiz>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static fromJson(json: any): Quiz {
        const quiz = new Quiz({
            id: json.id,
            softDeleted: json.softDeleted,
            title: json.title,
            description: json.description,
            // settingsJson: json.settingsJson,
            settings: json.settingsJson ? QuizSettings.fromJson(JSON.parse(json.settingsJson)) : QuizSettings.default,
            additionalHtml: json.additionalHtml,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            snapshotDate: json.snapshotDate ? moment(json.snapshotDate) : undefined,
            snapshotId: json.snapshotId,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            widget: json.widget ? Widget.fromJson(json.widget) : undefined,
            sentCount: json.sentCount
        });

        if (quiz.scenario)
            quiz.scenario.update({ quiz });

        return quiz;
    }

    copy() {
        return new Quiz({
            id: this.id,
            title: this.title,
            description: this.description,
            settings: this.settings?.copy(),
            additionalHtml: this.additionalHtml,
            snapshotDate: this.snapshotDate,
            createdDate: this.createdDate,
            updatedDate: this.updatedDate,
            scenario: this.scenario?.copy()
        });
    }
}

