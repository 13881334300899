import { observable, action } from 'mobx';
import Contact from '../contact';
import Admin from '../../admin/Admin';
import moment from 'moment';
import Entity, { ISerializable } from '../../Entity';
import BaseFilter from '../../BaseFilter';
import PurchaseOrder from '../../Payment/PurchaseOrder/PurchaseOrder';
import PartnershipTransaction from '../../Partnership/PartnershipTransaction';

export type BonusTransactionType = 'deposit' | 'withdrawal';
export enum BonusTransactionDurationType {
    Day = 'day',
    Month = 'month'
}
export enum BonusTransactionValidityType {
    Endless = 'endless',
    Duration = 'duration',
    Date = 'date'
}
export default class StudentBonusTransaction extends Entity implements ISerializable {
    constructor(t?: Partial<StudentBonusTransaction>) {
        super();
        if (t) this.update(t);
    }

    @observable amount: number;
    @observable comment: string;
    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    @observable contact: Contact;
    @observable admin?: Admin;
    @observable order: PurchaseOrder;
    @observable type: BonusTransactionType;
    @observable transaction: PartnershipTransaction;
    @observable burnedTransaction: StudentBonusTransaction;

    @observable duration: number | null;
    @observable durationType: BonusTransactionDurationType | null;
    @observable validUntilDate: moment.Moment | null;

    clone(): StudentBonusTransaction {
        return new StudentBonusTransaction({
            ...this
        });
    }

    toJson() {
        return {
            id: this.id,
            amount: this.amount,
            type: this.type,
            comment: this.comment,
            studentId: this.contact?.id,
            orderId: this.order?.id,
            duration: this.duration,
            durationType: this.durationType,
            validUntilDate: this.validUntilDate
        }
    }

    @action update(partnership: Partial<StudentBonusTransaction>) {
        super.update(partnership);
    }

    static fromJson(json: any): StudentBonusTransaction {
        return new StudentBonusTransaction({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            validUntilDate: json.validUntilDate ? moment(json.validUntilDate) : null,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
            admin: json.adminCreator ? Admin.fromJson(json.adminCreator) : undefined,
            order: json.order ? PurchaseOrder.fromJson(json.order) : undefined,
            transaction: json.transaction ? PartnershipTransaction.fromJson(json.transaction) : undefined,
            burnedTransaction: json.burnedTransaction ? StudentBonusTransaction.fromJson(json.burnedTransaction) : undefined
        });
    }

    static default() {
        return new StudentBonusTransaction({
            amount: 0,
            type: 'deposit',
        });
    }
}

export class StudentBonusTransactionFilter extends BaseFilter<StudentBonusTransaction> {
    constructor(filter?: Partial<StudentBonusTransactionFilter>) {
        super();
        if (filter) this.update(filter);
    }

    createdDateFrom: moment.Moment;
    createdDateTo: moment.Moment;
    studentIds: string[];
    amountFrom: number;
    amountTo: number;
    /**
     * has admin => manual transaction
     */
    hasAdminId: boolean;
    adminId: string;
    type: BonusTransactionType;

    update(changes: Partial<StudentBonusTransactionFilter>) {
        super.update(changes);
    }
}