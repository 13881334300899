import { Updatable } from '../../..';

export default class MobbexSettings extends Updatable {
    public apiKey: string;
    public accessToken: string;
    public testMode: boolean;
    public widgetMode: boolean;

    constructor(settings?: Partial<MobbexSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<MobbexSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    toJson() {
        return {
            ...this
        }
    }
}