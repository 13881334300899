import { computed, observable } from 'mobx';
import { Gender, Lang } from '../enums';
import { Entity, AccelFile } from '.';
import { isEmpty } from '../utils';

export abstract class User extends Entity {
    constructor(user?: Partial<User>) {
        super(user);
    }

    @observable firstName: string;
    @observable lastName: string;
    @observable middleName: string;
    @observable phone: string;
    @observable email: string;
    @observable avatar: AccelFile | null;
    @observable gender: Gender | null;
    @observable birthday: Date | null;
    @observable lang: Lang;

    @computed get isFirstNameEmpty() {
        return isEmpty(this.firstName);
    }

    @computed get isLastNameEmpty() {
        return isEmpty(this.lastName);
    }

    @computed get hasName() {
        return !this.isFirstNameEmpty || !this.isLastNameEmpty;
    }

    @computed get fullName(): string {
        if (this.isFirstNameEmpty && this.isLastNameEmpty)
            return this.email;
        if (this.isFirstNameEmpty)
            return this.lastName;
        if (this.isLastNameEmpty)
            return this.firstName;
        return `${this.firstName} ${this.lastName}`;
    }

    @computed get fullNameNoEmail(): string {
        if (this.isFirstNameEmpty && this.isLastNameEmpty)
            return '';
        if (this.isFirstNameEmpty)
            return this.lastName;
        if (this.isLastNameEmpty)
            return this.firstName;
        return `${this.firstName} ${this.lastName}`;
    }
}