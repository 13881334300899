export enum ProductType {
    Digital = 'digital',
    Physical = 'physical',
    Service = 'service',
    Material = 'material',
    Subscription = 'subscription',
    Cohort = 'cohort',
}

export enum ProductContentType {
    Course = 'course',
    Library = 'library',
    AllCourses = 'allCourses',
    AllLibraries = 'allLibraries'
}