import DisposableStack from './DisposableStack';
import IDisposable from './IDisposable';

export default abstract class Disposable implements IDisposable {
    protected stack: DisposableStack;
    constructor() {
        this.stack = new DisposableStack();
    }

    protected toDispose(func: IDisposable | Function) {
        this.stack.push(func);
    }

    dispose(): void {
        this.stack.dispose();
    }
}