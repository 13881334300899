import React, { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { observer } from 'mobx-react';

import {
    InfoCircleOutlined
} from '@ant-design/icons';

import { notification } from 'antd';
import { FileUploader } from '../..';
import Humanize from 'humanize-plus';
import { FileUploadStatus } from '../../../enums';
import styles from './FileListItem.module.scss';
import { Draggable } from 'react-beautiful-dnd';
import { combineClasses, isEmpty } from '../../../utils';
import { FileProps } from '../Files';
import FileName from '../components/FileName/FileName';
import { FileUploaderHandler } from '../../FileUploader/FileUploader';
import FileDescriptionPopover from '../FileDescriptionPopover/FileDescriptionPopover';
import { VscGripper } from 'react-icons/vsc';
import FilePreview from '../components/FilePreview/FilePreview';
import SpaceSeparator from '../../SpaceSeparator/SpaceSeparator';
import DateTime from '../../DateTime/DateTime';
import FileActions from '../components/FileActions/FileActions';

const FileListItem: React.FC<FileProps> = ({ file, ...props }) => {

    const uploaderHandler = useRef<FileUploaderHandler>();
    const [progress, setProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState<FileUploadStatus>(file?.nativeFile != null ? FileUploadStatus.Uploading : FileUploadStatus.Uploaded);

    const retryUpload = useCallback(() => {
        setProgress(0);
        setUploadStatus(FileUploadStatus.Uploading);
    }, []);

    const handleFileReadyToUpload = useCallback(async () => {
        const res = await props.beforeUpload?.(file);
        if (res !== undefined && res === false) return;  // cancel uploading
        await uploaderHandler.current?.upload();
    }, []);

    const fileSize = useMemo(() => (props.size == 'lg' || props.size == 'xl') ? 'large' : props.size == 'md' ? 'middle' : 'small', [props.size]);

    const fileContent = useMemo(() => <div className={combineClasses(styles.fl_list_item, 'flex align-center flex-1 mb-5 mt-5')}
        data-draggable={props.draggable}
        data-size={props.size}>

        <div className='flex flex-center flex-1 w-100'>

            <FilePreview view='list'
                file={file}
                fileProvider={props.fileProvider}
                loc={props.loc}
                size={props.size}
                progress={progress}
                uploadStatus={uploadStatus}
                onPreview={() => props.onPreview(file)}
                onRetryUpload={() => retryUpload()}
                fileIconColor={props.fileIconColor}
                className={styles.fl_list_item__preview} />

            <div className='flex-1 flex align-center ellipsis'>
                <div className='flex-1 flex flex-col justify-center ml-10 ellipsis'>
                    <FileName file={file} editable={props.editable} useExtension onRename={async name => await props.onChange?.(file, { name }) ?? true} loc={props.loc} className={styles.fl_list_item__name} />
                    <div className='fs-12'>
                        {uploadStatus == FileUploadStatus.Error
                            ? <span className='fs-12 color-danger'>{props.loc.word('File.uploadFailedLabel', { default: 'Upload failed' })}</span>
                            : <SpaceSeparator separator='dot' wrap={false}>
                                {props.dateCreatedVisible == true && <DateTime date={file.createdDate} format='LLL' className='color-secondary' />}
                                <span className='color-secondary'>{Humanize.fileSize(file.size)}</span>
                                {(props.descriptionVisible == true && uploadStatus == FileUploadStatus.Uploaded)
                                    && <FileDescriptionPopover
                                        loc={props.loc}
                                        file={file}
                                        onSave={async description => await props.onChange?.(file, { description }) ?? true}>
                                        <InfoCircleOutlined className={combineClasses('ml-5', !isEmpty(file.description) ? 'color-success' : undefined)} />
                                    </FileDescriptionPopover>
                                }
                            </SpaceSeparator>}
                    </div>
                </div>
                <div className='ml-10'>
                    <FileActions
                        file={file}
                        theme='dark'
                        size={fileSize}
                        deletable={props.deletable}
                        downloadable={props.downloadable}
                        replaceable={props.replaceable}
                        uploadStatus={uploadStatus}
                        loc={props.loc}
                        onDownload={() => props.onDownload?.(file)}
                        onRemove={() => props.onRemove?.(file)}
                        onReplace={() => props.onReplace?.(file)}
                        onUpload={() => retryUpload()}
                        onAbort={() => uploaderHandler.current?.abort()}
                    />
                </div>
            </div>
        </div>
    </div>, [file, props]);

    return <>
        {props.draggable
            ? <Draggable draggableId={file.key} index={props.index}>
                {(provided, snap) => (
                    <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={provided.draggableProps.style}
                        className={combineClasses('flex align-center', snap.isDragging ? 'shadow-sm' : undefined)}>

                        {props.draggable == true &&
                            <div {...provided.dragHandleProps} className={combineClasses(styles.fl_drag_handle, 'flex fs-18')}>
                                <VscGripper />
                            </div>}

                        {fileContent}
                    </div>)}
            </Draggable>
            : fileContent}

        {uploadStatus == FileUploadStatus.Uploading &&
            <FileUploader handler={x => uploaderHandler.current = x}
                autostart={false}
                file={file}
                onReady={handleFileReadyToUpload}
                onProgress={x => setProgress(x.percent)}
                onComplete={async ({ file }) => {
                    file.update(file);
                    setUploadStatus(FileUploadStatus.Uploaded);
                    props.onUploaded?.(file);
                }}
                uploadArgs={props.uploadArgs}
                onAbort={() => {
                    if (file.nativeFile) {
                        setUploadStatus(FileUploadStatus.Error);
                        props.onAborted?.(file);
                    }
                }}
                onError={(a) => {
                    setUploadStatus(FileUploadStatus.Error);
                    notification.error({ message: props.loc.word('Error occured'), duration: 2 });
                }} />}
    </>;
}

export default observer(FileListItem);

