import ContactLicense from './contact-license';
import { BaseFilter } from '../..';

export default class ContactLicenseFilter extends BaseFilter<ContactLicense> {
    constructor(filter?: Partial<ContactLicenseFilter>) {
        super();
        if (filter) this.update(filter);
    }

    studentId: string;
    isActive: boolean;
    isExpired: boolean;

    update(changes: Partial<ContactLicenseFilter>) {
        super.update(changes);
    }
}