import { action, observable } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { QuizItem } from '../..';
import Entity from '../../Entity';
import { QuizPageSettings } from './QuizPageSettings';

export default class QuizPage extends Entity {
    constructor(item?: Partial<QuizPage>) {
        super(item);
        if (item) this.update(item);
    }

    @observable quizId: string;
    @observable settings: QuizPageSettings;
    @observable position: number;

    @observable createdDate: Moment;
    @observable updatedDate: Moment;

    @observable items: QuizItem[];

    @action update(q: Partial<QuizPage>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static fromJson(json: any): QuizPage {
        return new QuizPage({
            id: json.id,
            softDeleted: json.softDeleted,
            settings: json.settingsJson ? QuizPageSettings.fromJson(JSON.parse(json.settingsJson)) : QuizPageSettings.default,
            quizId: json.quizId,
            position: json.position,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            items: json.items ? json.items.map(QuizItem.fromJson) : undefined
        });
    }

    clone() {
        return new QuizPage({
            id: this.id,
            settings: this.settings.clone(),
            quizId: this.quizId,
            position: this.position,
            createdDate: this.createdDate,
            updatedDate: this.updatedDate,
            // items: this.items.map(x => x.clone())
        });
    }
}