import { observable, action } from 'mobx';
import { BaseFilter, Contact, Entity } from '..';
import moment from 'moment';
import { ISerializable } from '../Entity';

export default class PartnershipMember extends Entity implements ISerializable {
    constructor(partnership?: Partial<PartnershipMember>) {
        super();
        if (partnership) this.update(partnership);
    }

    @observable isActive: boolean;
    @observable navigationCount: number;
    @observable registrationCount: number;
    @observable amount: number;
    @observable fee: number;
    @observable availableFee: number;

    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    @observable acceptedDate: moment.Moment | null;

    @observable contact: Contact | null;
    @observable code: string;

    toJson() {
        return {
            id: this.id,
            acceptedDate: this.acceptedDate,
            studentId: this.contact?.id,
        };
    }

    clone() {
        return new PartnershipMember({
            ...this,
            contact: this.contact?.clone(),
        });
    }

    @action update(partnership: Partial<PartnershipMember>) {
        super.update(partnership);
    }

    static fromJson(json: any): PartnershipMember {
        return new PartnershipMember({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : null,
            acceptedDate: json.acceptedDate ? moment(json.acceptedDate) : null,
        });
    }
}

export class PartnershipMemberFilter extends BaseFilter<PartnershipMember> {
    constructor(filter?: Partial<PartnershipMemberFilter>) {
        super();
        if (filter) this.update(filter);
    }

    createdFromDate: moment.Moment | null;
    createdToDate: moment.Moment | null;
    statisticFromDate: moment.Moment | null;
    statisticToDate: moment.Moment | null;
    acceptedFromDate: moment.Moment | null;
    acceptedToDate: moment.Moment | null;
    code: string;

    update(changes: Partial<PartnershipMemberFilter>) {
        super.update(changes);
    }
}