import { action } from 'mobx';
import { Entity } from '../..';
import { UrlParameter } from '../../Url/UrlParameter';

type SiteSessionBrowser = {
    clientBrowser: string;
    clientBrowserVersion: string;
    clientHeight: number;
    clientOs: string;
    clientOsVersion: string;
    clientWidth: number;
    isMobile: boolean;
    userAgent: string;
}

type SiteSessionLocation = {
    city: string;
    country: string;
    countryCode: string;
    ip: string;
    isEurope: boolean;
    latitude: number;
    longitude: number;
}

type SiteSession = {
    isBounced: boolean;
    startDate: Date;
    viewCount: number

    browser: SiteSessionBrowser,
    location: SiteSessionLocation

    //enter
    enterSiteGuestSessionViewId: string;
    enterTitle: string;
    enterUrl: string;
    enterUrlParameters: UrlParameter[];

    //exit
    exitSiteGuestSessionViewId: string;
    exitTitle: string;
    exitUrl: string;


    //source
    referer: string;
    refererHost: string;
    refererTopDomain: string;
}

type SiteSessionView = {
    createdDate: Date;
    duration: number;
    loadTimeMS: number;
    nextSiteGuestSessionViewId: string;
    pageTitle: string;
    previousSiteGuestSessionViewId: string;
    siteId: string;
    siteName: string;
    sitePageId: string;
    url: string;
    urlClean: string;
    urlParameters: UrlParameter[];
}


export default class SiteView extends Entity {
    constructor(siteView?: Partial<SiteView>) {
        super(siteView);
        if (siteView) this.update(siteView);
    }

    session: SiteSession;
    view: SiteSessionView;

    static fromJson(json: any): SiteView {
        return new SiteView({
            ...json,
            date: json.date ? new Date(json.date) : undefined,
        });
    }

    @action
    update(siteView: Partial<SiteView>) {
        super.update(siteView);
    }
}

