

import { action, computed } from 'mobx';
import moment from 'moment';
import { BillingPlan, Entity, SchoolBilling } from '../..';
import { Currency } from '../../../enums';

export default class BillingPlanUsage extends Entity {
    constructor(usage?: Partial<BillingPlanUsage>) {
        super(usage);
        if (usage) this.update(usage);
    }

    invoiceId: string;
    fallbackInvoiceId: string;
    fallbackAmount: number;
    fee: number;

    exchangeCurrency: Currency;
    exchangeRate: number | null;

    createdDate: moment.Moment;
    updatedDate: moment.Moment | null;
    syncDate: moment.Moment | null;

    schoolBilling: SchoolBilling;
    plan: BillingPlan;
    fallbackPlan: BillingPlan;

    /* For Prodamus is Percent Of Fee Covers Fallback Amount */
    @computed get hasRedFlag() {
        return this.fee * 0.25 >= this.fallbackAmount;
    }

    
    @computed get feeInExchangeCurrency() {
        return this.fee * (this.exchangeRate ?? 1);
    }

    @computed get fallbackAmountInExchangeCurrency() {
        return this.fallbackAmount * (this.exchangeRate ?? 1);
    }

    static fromJson(json: any): BillingPlanUsage {
        return new BillingPlanUsage({
            id: json.id,
            invoiceId: json.invoiceId,
            fallbackInvoiceId: json.fallbackInvoiceId,
            fallbackAmount: json.fallbackAmount,
            fee: json.fee,
            exchangeCurrency: json.exchangeCurrency,
            exchangeRate: json.exchangeRate,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : null,
            syncDate: json.syncDate ? moment(json.syncDate) : null,
            schoolBilling: json.schoolBilling ? SchoolBilling.fromJson(json.schoolBilling) : undefined,
            plan: json.plan ? BillingPlan.fromJson(json.plan) : undefined,
            fallbackPlan: json.fallbackPlan ? BillingPlan.fromJson(json.fallbackPlan) : undefined,
        });
    }

    @action
    update(usage: Partial<BillingPlanUsage>, allowUndefined = false) {
        super.update(usage, allowUndefined);
    }
}

