import { Lang } from '../../enums';

export declare type NumberDelimeter = 'space' | 'comma' | 'dot' | 'plain';

export class NumberConverter {
    static format(value: number, delimeter: NumberDelimeter, precision: number = 2, truncateIfZero: boolean = true): string {
        const delimeterSymbol = NumberConverter.getDelimeterSymbol(delimeter);

        const fixed = value.toFixedValue(precision, truncateIfZero);
        const parts = fixed.split('.');
        const intPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delimeterSymbol);
        if (parts.length == 1) return intPart;
        return `${intPart}.${parts[1]}`;
    }

    static locFormat(value: number, lang: Lang, precision: number = 2, truncateIfZero: boolean = true): string {
        const delimeter = NumberConverter.getDelimeterByLang(lang);
        return NumberConverter.format(value, delimeter, precision, truncateIfZero);
    }

    private static getDelimeterSymbol(delimeter: NumberDelimeter) {
        switch (delimeter) {
            case 'comma': return ',';
            case 'dot': return '.';
            case 'space': return ' ';
            default: return '';
        }
    }

    private static getDelimeterByLang(lang: Lang): NumberDelimeter {
        switch (lang) {
            case Lang.en: ;
            case Lang.id: return 'comma';
            default: return 'space';
        }
    }
}