import { observable, action, computed } from 'mobx';
import { AdminPartnerTransaction, BillingPromoCode, Entity } from '..';

export enum HowDidYouHearAboutUs {
    Internet = 'internet',
    WordOfMouth = 'wordOfMouth',
    SocialMedia = 'socialMedia',
    Other = 'other'
}

export enum OrganisationActivity {
    ExistingOnlineSchool = 'existingOnlineSchool',
    CreatingOnlineSchool = 'creatingOnlineSchool',
    Studying = 'studying',
    TechnicalSpecialist = 'technicalSpecialist',
    Other = 'other'
}

export enum EmployeesNumber {
    LessThan10 = 'lessThan10',
    From10To25 = 'from10To25',
    From50To100 = 'from50To100',
    MoreThan100 = 'moreThan100'
}

export enum LeadsNumber {
    LessThan1k = 'lessThan1k',
    From1kTo5k = 'from1kTo5k',
    From5kTo10k = 'from5kTo10k',
    From10kTo50k = 'from10kTo50k',
    From50kTo100k = 'from50kTo100k',
    MoreThan100k = 'moreThan100k'
}

export default class SchoolMetadata extends Entity {
    constructor(b?: Partial<SchoolMetadata>) {
        super(b);
        if (b) this.update(b);
    }

    @observable organisationActivity: OrganisationActivity;
    @observable organisationActivityOther: string;

    @observable howDidYouHearAboutUs: HowDidYouHearAboutUs;
    @observable howDidYouHearAboutUsOther: string;

    @observable employeesNumber: EmployeesNumber;
    @observable leadsNumber: LeadsNumber;

    @observable partnerBonusAmount: number;
    @observable partnerBonusTransaction: AdminPartnerTransaction | null;

    @observable utmSource: string | null;
    @observable utmMedium: string | null;
    @observable utmCampaign: string | null;
    @observable utmTerm: string | null;
    @observable utmContent: string | null;

    @observable billingPromoCode: BillingPromoCode | null;

    @computed get hasUtm() {
        return this.utmSource != null
            || this.utmMedium != null
            || this.utmCampaign != null
            || this.utmTerm != null
            || this.utmContent != null
    }

    clone() {
        return new SchoolMetadata({
            ...this,
            partnerBonusTransaction: this.partnerBonusTransaction ? this.partnerBonusTransaction.clone() : null,
            billingPromoCode: this.billingPromoCode ? this.billingPromoCode.clone() : null
        });
    }

    @action update(metadata: Partial<SchoolMetadata>, allowUndefined = false) {
        super.update(metadata, allowUndefined);
    }

    static fromJson(json: any) {
        return new SchoolMetadata({
            id: json.id,
            organisationActivity: json.organisationActivity,
            organisationActivityOther: json.organisationActivityOther,
            howDidYouHearAboutUs: json.howDidYouHearAboutUs,
            howDidYouHearAboutUsOther: json.howDidYouHearAboutUsOther,
            employeesNumber: json.employeesNumber,
            leadsNumber: json.leadsNumber,
            partnerBonusAmount: json.partnerBonusAmount,
            partnerBonusTransaction: json.partnerBonusTransaction ? AdminPartnerTransaction.fromJson(json.partnerBonusTransaction) : null,
            utmSource: json.utmSource,
            utmMedium: json.utmMedium,
            utmCampaign: json.utmCampaign,
            utmTerm: json.utmTerm,
            utmContent: json.utmContent,
            billingPromoCode: json.billingPromoCode ? BillingPromoCode.fromJson(json.billingPromoCode) : null
        });
    }
}
