
import { observable, action } from 'mobx';
import { Entity, FlowDate, Scenario } from '..';

export default class Flow extends Entity {
    constructor(flow?: Partial<Flow>) {
        super(flow);
        if (flow) this.update(flow);
    }

    @observable title: string;
    @observable denyAccessContentAfterEnd: boolean;
    @observable denyAccessContentBeforeStart: boolean;
    @observable flowDates: FlowDate[];

    @observable scenario?: Scenario;

    static fromJson(json: any): Flow {
        const flow = new Flow({
            ...json,
            flowDates: json.flowDates ? json.flowDates.map(FlowDate.fromJson) : undefined,
            scenario: json.scenarios && json.scenarios.length > 0 ? Scenario.fromJson(json.scenarios[0]) : undefined,
        });
        if (flow.scenario) flow.scenario.update({ flow });
        return flow;
    }

    @action
    update(flow: Partial<Flow>) {
        super.update(flow);
    }
}

