import { action, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';


export class RatingItemSettings extends QuizItemSettings {
    constructor(s?: Partial<RatingItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable starCount: number;

    @action update(q: Partial<RatingItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }


    static build(def?: Partial<RatingItemSettings>) {
        return new RatingItemSettings({
            ...QuizItemSettings.default,
            starCount: 5,
            ...def
        });
    }

    clone() {
        return new RatingItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return RatingItemSettings.build(json);
    }
}
