import { action, observable } from "mobx";
import { Updatable } from '../../../..';

export default class WebinarRegistrationEmailFieldSettings extends Updatable {
    constructor(settings?: Partial<WebinarRegistrationEmailFieldSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable confirmation: boolean;

    @action update(settings: Partial<WebinarRegistrationEmailFieldSettings>) {
        super.update(settings);
    }

    hasChanged(settings: WebinarRegistrationEmailFieldSettings) {
        return this.confirmation != settings.confirmation;
    }

    copy() {
        return new WebinarRegistrationEmailFieldSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return new WebinarRegistrationEmailFieldSettings({
            ...json
        });
    }
}