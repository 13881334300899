

import { observable, action } from 'mobx';
import { Updatable } from '../..';

export default class BillingPlanPrice extends Updatable {
    constructor(p?: Partial<BillingPlanPrice>) {
        super();
        if (p) this.update(p);
    }

    @observable bracket: number;
    @observable amount: number;

    static fromJson(json: any): BillingPlanPrice {
        return new BillingPlanPrice({
            bracket: json.bracket,
            amount: json.amount,
        });
    }

    @action
    update(plan: Partial<BillingPlanPrice>) {
        super.update(plan);
    }
}

