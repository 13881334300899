import React, { } from "react";
import { Popover, Typography } from 'antd';
import { AccelFile } from '../../../models';
import Comment from '../../Comment/Comment';
import { Localization } from '../../../utils';

type FileDescriptionTooltipProps = {
    file: AccelFile;
    canEdit?: boolean;
    loc: Localization;
    onSave: (description: string) => Promise<boolean>;
}
const FileDescriptionPopover: React.FC<FileDescriptionTooltipProps> = ({ loc, file, canEdit, onSave, children }) => {
    return <Popover
        content={<>
            <Comment text={file.description}
                emptyText={<Typography.Text type='secondary'>
                    {loc.word('FileList.emptyDescriptionMsg', { default: 'No description' })}
                </Typography.Text>}
                disableFiles
                placeholder={loc.word('FileList.descriptionPlaceholder', { default: 'Enter description' })}
                onSend={async text => onSave?.(text)}
                canRemove={false}
                canEdit={canEdit} />
        </>}
        title={loc.word('Description')}
        trigger='hover'>
        {children as React.ReactElement}
    </Popover>
}

export default FileDescriptionPopover;