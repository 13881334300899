import { action, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';


export class CheckboxItemSettings extends QuizItemSettings {
    constructor(s?: Partial<CheckboxItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    /** markdown */
    @observable label: string;
    @observable labelHtml: string;

    @action update(q: Partial<CheckboxItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<CheckboxItemSettings>) {
        return new CheckboxItemSettings({
            ...QuizItemSettings.default,
            label: '',
            ...settings
        });
    }

    clone() {
        return new CheckboxItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return CheckboxItemSettings.build(json);
    }
}
