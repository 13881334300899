import { observable, action } from 'mobx';
import { Entity, TopicComment, Contact, AccelFile, ForumReaction } from '../..';

export default class ForumTopic extends Entity {
    constructor(topic?: Partial<ForumTopic>) {
        super(topic);
        if (topic) this.update(topic);
    }

    @observable title: string;
    @observable text: string;

    @observable createdDate: Date;
    @observable updatedDate?: Date;
    @observable lastActivityDate?: Date;

    @observable lastComment: TopicComment | null;

    @observable commentCount: number;
    @observable contact: Contact;
    @observable files: AccelFile[];

    @observable likeCount: number;
    @observable dislikeCount: number;
    @observable currentUserReaction: ForumReaction | null;

    static fromJson(json: any): ForumTopic {
        return new ForumTopic({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            lastActivityDate: json.lastActivityDate ? new Date(json.lastActivityDate) : undefined,
            lastComment: json.lastComment ? TopicComment.fromJson(json.lastComment) : null,
            contact: json.student ? Contact.fromJson(json.student) : null,
            files: json.files ? json.files.map(AccelFile.fromJson) : [],
            currentUserReaction: json.currentUserReaction ? ForumReaction.fromJson(json.currentUserReaction) : null
        });
    }

    @action
    update(branch: Partial<ForumTopic>) {
        super.update(branch);
    }
}

