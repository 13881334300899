import React, { useMemo } from "react";
import { combineClasses } from '../../utils';
import './file-icons.scss';

export declare type FileIconSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export declare type FileIconColor = '#ffffff' | '#f1f1f1';
type FileIconProps = {
    size?: FileIconSize;
    extension?: string;
    filename?: string;
    className?: string;
    bgColor?: FileIconColor; // idk another way lol
}
const FileIcon: React.FC<FileIconProps> = ({ extension, size, filename, bgColor, className }) => {

    const dataType = useMemo(() => {
        if (filename != null)
            return filename.split('.').pop()!;
        if (extension != null)
            if (extension.startsWith('.'))
                return extension.substring(1);
        return extension;
    }, [filename, extension]);

    const sizeClassName = useMemo(() => {
        return size == null ? '' : `file-icon-${size}`;
    }, [size]);

    return <div className={combineClasses('file-icon shadow-xs', sizeClassName, className)} data-type={dataType} data-bg-color={bgColor}>
        {dataType && <div className='file-icon-ext'>{dataType.toUpperCase()}</div>}
    </div>;
}
FileIcon.defaultProps = {
    bgColor: '#ffffff',
    size: 'md'
}
export default FileIcon;