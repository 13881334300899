import Humanize from 'humanize-plus';
import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { Entity } from '..';
import { FileSize, isEmpty } from '../../utils';
import KeyGenerator from '../../utils/key-generator/key-generator';
import { ISerializable } from '../Entity';

export enum FileType {
    Other = 'other',
    Image = 'image',
    Video = 'video',
    Audio = 'audio',
    Doc = 'doc',
    Pdf = 'pdf',
    Zip = 'zip',
    Ppt = 'ppt',
    Xls = 'xls',
    Json = 'json',
    Csv = 'csv',
}

export enum FileSource {
    Amazon = 'amazon',
}

export type AudioMeta = {
    durationInSec?: number;
    codecName?: string;
    codecLongName?: string;
    bitrate?: number;
    formatName?: string;
    channels?: number;
    frequencyLevels?: number[];
}

export default class AccelFile extends Entity implements ISerializable {
    constructor(file?: Partial<AccelFile>) {
        super(file);
        if (file) this.update(file);
    }

    @observable name: string;
    @observable description: string;
    @observable cloudKey: string;
    size: number;
    mimetype: string;
    type: FileType;
    source: FileSource;
    additionalData: any;
    extension: string;
    createdDate: Moment;
    videoThumbnailUrl: string;
    originalName: string;
    @observable isPublic: boolean;
    @observable isVoice: boolean;

    @computed get audioMeta() {
        return this.additionalData as AudioMeta | undefined;
    }

    set audioMeta(meta: AudioMeta | undefined) {
        this.additionalData = meta;
    }

    /**
     * remove 'size' and rename
     */
    get fileSize(): FileSize {
        return FileSize.fromBytes(this.size);
    }

    get fileSizeString(): string {
        return Humanize.fileSize(this.size);
    }

    @observable key: string;
    @observable nativeFile: File | null;
    @observable previewImage?: string;
    @observable previewAudio?: string;

    @computed get isUploaded() {
        return !isEmpty(this.cloudKey);
    }
    /**
     * external file source
     */
    @observable url?: string;

    get isExternalFile() {
        return !isEmpty(this.url);
    }

    get isImage() {
        return ['.bmp', '.dib', '.rle', '.jpg', '.jpeg', '.jpe', '.jfif', '.gif', '.tif', '.tiff', '.png', '.svg', '.pjpeg', '.webp', '.ico'].includes(this.extension);
    }

    get isAudio() {
        return ['.mp3', '.ogg', '.webm', '.m4a'].includes(this.extension);
    }

    get filename() {
        return this.name + this.extension;
    }

    replaceFile(file: File) {
        this.update({
            ...AccelFile.fromFile(file),
            id: this.id,
            name: this.name,
            description: this.description,
            createdDate: this.createdDate,
            isPublic: this.isPublic,
        }, true);
    }

    @action
    update(file: Partial<AccelFile>, allowUndefined = false) {
        super.update(file, allowUndefined);
    }

    toJson() {
        return {
            ...this,
            additionalData: JSON.stringify(this.additionalData),
            nativeFile: undefined,
        }
    }

    clone(): AccelFile {
        return new AccelFile({
            ...this
        });
    }

    static fromJson(json: any) {
        return new AccelFile({
            ...json,
            key: json.id,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            convertData: json.convertDataJson ? JSON.parse(json.convertDataJson) : undefined,
            additionalData: json.additionalData ? JSON.parse(json.additionalData) : undefined,
        });
    }

    static fromFile(file: File, overrides?: Partial<AccelFile>) {
        const index = file.name.lastIndexOf('.');
        const base = new AccelFile({
            key: KeyGenerator.generate(10),
            source: FileSource.Amazon,
            nativeFile: file,
            size: file.size,
            mimetype: file.type,
            name: file.name.substring(0, index),
            extension: file.name.substring(index),
            createdDate: moment(),
            ...overrides
        });
        if (base.isImage && base.nativeFile)
            base.previewImage = URL.createObjectURL(base.nativeFile);
        if (base.isAudio && base.nativeFile && base.nativeFile.type.includes('audio'))
            base.previewAudio = URL.createObjectURL(base.nativeFile);
        return base;
    }
}
