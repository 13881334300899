import { Updatable } from '../../..';

export default class BePaidSettings extends Updatable {
    public shopId: string;
    public secretKey: string;
    public publicKey: string;
    
    public testMode: boolean;
    public widgetMode: boolean;

    update(entity: Partial<BePaidSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}