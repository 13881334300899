import { useState } from 'react';

export type DrawerState<T> = {
    visible: boolean;
    data?: T;
}

export type DrawerController<T> = DrawerState<T> & {
    close: () => void;
    open: (data?: T) => void;
    set: (state: DrawerState<T>) => void;
}

export default function useDrawer<T = {}>(defaultState?: DrawerState<T>) {
    const [state, setState] = useState<DrawerState<T>>(defaultState ?? { visible: false });
    return {
        get visible() { return state.visible; },
        get data() { return state.data; },
        close: () => {
            setState({ visible: false });
        },
        open: (data?: T) => {
            setState({ visible: true, data: data ?? state.data });
        },
        set: (changes: Partial<DrawerState<T>>) => {
            const newState = { ...state, ...changes };
            setState(newState);
        }
    } as DrawerController<T>;
}
