import { useCallback, useMemo } from "react";
import { SelectProps as AntSelectProps } from "antd";
import SelectLabel from "../SelectLabel/SelectLabel";
import React from "react";

// тип для Option в AntSelect (вместо string используется объект с item для сущностей)
export type SelectOption<TEntity> = {
    label: React.ReactElement<{ item: TEntity }>;
    value: string;
}

// общие пропсы для всех AntSelect
export type BaseSelectProps<TEntity> = Omit<AntSelectProps<SelectOption<TEntity>>, 'value' | 'defaultValue' | 'onChange' | 'mode'>
    & {
        mode?: 'multiple' | 'tags' | 'mono';
        value?: TEntity | TEntity[] | null;
        defaultValue?: TEntity | TEntity[] | null;
        menuFooter?: React.ReactNode;
        inline?: boolean;
        isOptionDisabled?: (item: TEntity) => boolean;
        itemLabel: (item: TEntity) => React.ReactNode;
        itemLabelValue?: (item: TEntity) => React.ReactNode;
        itemKey?: (item: TEntity) => string;
        onChange?: (items: TEntity[]) => void;
        onChangeSingle?: (item: TEntity | null) => void;
        /**
         * use to create entity from label in `tags` mode
         * null is used in case of no item was created (e.g. not valid etc.)
         */
        createItem?: (label: string) => TEntity | null;
    }

const useSelect = function <TEntity>(props: BaseSelectProps<TEntity>, dataSource: TEntity[], itemKey: (item: TEntity) => string) {

    const { itemLabel, itemLabelValue, isOptionDisabled, mode: _mode, value: _value, defaultValue, onChange: _onChange, onChangeSingle, createItem } = props;

    const transformItems = useCallback((items: TEntity[], selected: boolean = false) => {
        return items.map(x => ({
            label: <SelectLabel item={x} children={(selected && itemLabelValue) ? itemLabelValue(x) : itemLabel(x)} />,
            value: itemKey(x),
            disabled: isOptionDisabled?.(x)
        } as SelectOption<TEntity>));
    }, [itemLabel, itemLabelValue, itemKey, isOptionDisabled]);

    const value = useMemo<SelectOption<TEntity>[]>(() => {
        const data = _value ?? defaultValue;
        if (data == null)
            return [] as SelectOption<TEntity>[];
        if (Array.isArray(data))
            return transformItems(data, true);
        return transformItems([data], true);
    }, [_value, defaultValue]);

    const options = useMemo<SelectOption<TEntity>[]>(() => transformItems(dataSource), [dataSource]);

    // item.label.props.item - сущность которая хранится в label (с помощью общей обертки AntSelectLabel)
    const handleChange = useCallback((value: SelectOption<TEntity> | SelectOption<TEntity>[] | undefined) => {
        if (value == null) {
            _onChange?.([]);
            onChangeSingle?.(null);
            return;
        }

        // if mode is 'tags' and createItem is defined, then we should create item when passed into select
        const shouldCreateItem = _mode === 'tags' && createItem != null;

        if (Array.isArray(value)) {
            const items = value.map((x) => x.label?.props?.item ?? (shouldCreateItem ? createItem(x.value) : null)).filter(x => x != null) as TEntity[];
            _onChange?.(items);
            if (value.length > 0)
                onChangeSingle?.(items[0]);
        }
        else {
            const item = value.label?.props?.item ?? (shouldCreateItem ? createItem(value.value) : null) as TEntity;
            _onChange?.(item == null ? [] : [item]);
            onChangeSingle?.(item);
        }
    }, [_onChange, onChangeSingle, _mode, createItem]);

    // mode prop override to use mono instead of undefined
    return { value, options, mode: _mode === 'mono' ? undefined : _mode, handleChange };
}
export default useSelect;