import React, { } from 'react';
import { Dropdown, Menu } from 'antd';

export type AudioWaveformLevel = number;

export type AudioWaveformProps = {
    playbackRate: number;
    onPlaybackRateChange?: (rate: number) => void;
    className?: string;
}
export const PlaybackRateSelect: React.FC<AudioWaveformProps> = ({ playbackRate, className, onPlaybackRateChange }) => {

    return <Dropdown trigger={['click']}
        overlay={<Menu selectedKeys={[playbackRate?.toString() ?? '1']}>
            {[0.5, 1, 1.5, 2].map((rate, i) => <Menu.Item key={rate} onClick={e => onPlaybackRateChange?.(rate)}>
                {rate.toPrecision(2)}x
            </Menu.Item>)}
        </Menu>}>
        <span className={className}>{playbackRate.toPrecision(2)}x</span>
    </Dropdown>;
};

PlaybackRateSelect.defaultProps = {
    playbackRate: 1
}