import { observable, action, computed } from 'mobx';
import moment, { type Moment } from 'moment';
import { Entity, UserNotificationType } from '..';

export default class UserNotification extends Entity {
    constructor(n?: Partial<UserNotification>) {
        super(n);
        if (n) this.update(n);
    }

    @observable type: UserNotificationType;
    @observable payload: any;

    @observable createdDate: Moment;
    @observable updatedDate: Moment;
    @observable seenDate: Moment | null;

    @computed get isSeen() {
        return this.seenDate != null;
    }

    @observable adminTaskId: string;
    @observable courseItemId: string;
    @observable libraryItemId: string;
    @observable taskAnswerId: string;
    @observable courseItemTaskId: string;
    @observable schoolContentItemCommentId: string;
    @observable orderId: string;
    @observable orderPaymentId: string;
    @observable scenarioId: string;
    @observable chatConversationId: string;
    @observable courseId: string;
    @observable libraryId: string;
    @observable contactId: string;
    @observable adminId: string;
    @observable schoolContentItemProgressRatingId: string;

    //#region Partnership
    @observable partnershipTransactionId: string;
    @observable partnershipTransactionRequestId: string;
    @observable studentBonusTransactionId: string;
    //#endregion

    static fromJson(json: any): UserNotification {
        return new UserNotification({
            id: json.id,
            type: json.type,
            payload: json.payloadJson ? JSON.parse(json.payloadJson) : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            seenDate: json.seenDate ? moment(json.seenDate) : null,
            adminId: json.adminId,
            contactId: json.studentId,
            adminTaskId: json.adminTaskId,
            courseItemId: json.courseItemId,
            libraryItemId: json.libraryItemId,
            courseItemTaskId: json.courseItemTaskId,
            schoolContentItemCommentId: json.schoolContentItemCommentId,
            orderId: json.orderId,
            orderPaymentId: json.orderPaymentId,
            scenarioId: json.scenarioId,
            chatConversationId: json.chatConversationId,
            courseId: json.courseId,
            libraryId: json.libraryId,
            taskAnswerId: json.taskAnswerId,
            schoolContentItemProgressRatingId: json.schoolContentItemProgressRatingId,
            partnershipTransactionId: json.partnershipTransactionId,
            partnershipTransactionRequestId: json.partnershipTransactionRequestId,
            studentBonusTransactionId: json.studentBonusTransactionId
        });
    }

    @action
    update(n: Partial<UserNotification>) {
        super.update(n);
    }
}

