import { Updatable } from '../../..';

export default class SberbankSettings extends Updatable {
    public login: string;
    public password: string;
    public callbackToken: string;
    public isTest: boolean;

    update(entity: Partial<SberbankSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}