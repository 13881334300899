import React, { useMemo, useState } from 'react';
import { combineClasses, isEmpty } from '../../utils';
import styles from './GradientPlaceholder.module.scss';

type Props = {
    /**
     * uniq string to select gradient
     */
    uniq?: string;
    width?: string | number;
    height?: string | number;
    gradient?: string;
    className?: string
}
const GradientPlaceholder: React.FC<Props> = ({ uniq, className, gradient, height, width }) => {

    const [gradientCount] = useState(11);
    const selectedGradient = useMemo<number | undefined>(() => {
        if (!isEmpty(gradient)) return undefined;
        if (!uniq) return 0;
        let sum = 0;
        for (let i = 0; i < uniq.length; i++) {
            sum += uniq.charCodeAt(i);
        }
        return sum % gradientCount;
    }, [uniq, gradient]);

    const customGradient = useMemo(() => {
        if (isEmpty(gradient)) return undefined;
        return gradient;
    }, [gradient]);

    return <div style={{ height, width, background: customGradient }}
        className={combineClasses(styles.gradient_placeholder, className)}
        data-gradient={selectedGradient}></div>;
}

export default GradientPlaceholder;