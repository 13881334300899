import { action, observable } from 'mobx';
import { Entity } from '..';

export default class Category extends Entity {
    constructor(category?: Partial<Category>) {
        super(category);
        if (category) this.update(category);
    }
    @observable name: string;
    @observable courseCount: number;
    @observable librariesCount: number;

    @action
    update(category: Partial<Category>) {
        super.update(category);
    }

    static fromJson(json: any) {
        return new Category({
            ...json
        });
    }
}