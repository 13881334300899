import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { Entity, QuizPage } from '../..';
import { QuizItemType } from './QuizItemType';
import QuizItemSettingsParser from './settings';
import QuizItemSettings from './settings/QuizItemSettings';

export default class QuizItem<T extends QuizItemSettings = QuizItemSettings> extends Entity {
    constructor(item?: Partial<QuizItem>) {
        super(item);
        if (item) this.update(item);
    }

    @observable title: string;
    @observable description: string;
    @observable settings: T;
    @observable type: QuizItemType;
    @observable position: number;

    @observable createdDate: Moment;
    @observable updatedDate: Moment;

    @observable pageId: string;
    @observable page: QuizPage;

    @action update(q: Partial<QuizItem>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static fromJson(json: any): QuizItem {
        return new QuizItem({
            id: json.id,
            title: json.title,
            description: json.description,
            settings: QuizItemSettingsParser.parse(json.type, json.settingsJson),
            position: json.position,
            type: json.type,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            softDeleted: json.softDeleted,
            pageId: json.pageId,
            page: json.page ? QuizPage.fromJson(json.page) : undefined,
        });
    }

    clone(c?: Partial<QuizItem>): QuizItem {
        return new QuizItem({
            title: this.title,
            description: this.description,
            settings: this.settings.clone(),
            position: this.position,
            type: this.type,
            createdDate: this.createdDate,
            pageId: this.pageId,
            page: this.page,
            ...c
        });
    }

    @computed get supportsAnswerSort() {
        switch (this.type) {
            case QuizItemType.UploadFile:
                return false;
            default:
                return true;
        }
    }

    @computed get answerSortName() {
        if (!this.supportsAnswerSort)
            return undefined;

        let fieldName = `Items.${this.id}`;
        let fieldValue = '';
        switch (this.type) {
            case QuizItemType.Checkbox:
                fieldValue = 'BooleanValue';
                break;
            case QuizItemType.DateTime:
                fieldValue = 'DateTimeValue';
                break;
            case QuizItemType.Rating:
            case QuizItemType.Number:
            case QuizItemType.Slider:
                fieldValue = 'NumericValue';
                break;
            case QuizItemType.DropdownList:
            case QuizItemType.MonoSelect:
                fieldValue = 'ListLabelValue';
                break;
            case QuizItemType.MultiSelect:
                fieldValue = 'MultiListLabelValue';
                break;
            case QuizItemType.Email:
            case QuizItemType.NameField:
            case QuizItemType.Phone:
            case QuizItemType.TextArea:
            case QuizItemType.TextField:
            case QuizItemType.Url:
                fieldValue = 'StringValue';
                break;
        };
        return `${fieldName}.${fieldValue}`;
    }
} 