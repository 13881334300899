
import { action, observable } from 'mobx';
import { Entity } from '..';

export default class LocalizationLanguage extends Entity {
    constructor(item?: Partial<LocalizationLanguage>) {
        super(item);
        if (item) this.update(item);
    }

    @observable name: string;
    @observable prefix: string;

    static fromJson(json: any): LocalizationLanguage {
        return new LocalizationLanguage({
            ...json,
        });
    }

    @action update(language: Partial<LocalizationLanguage>) {
        super.update(language);
    }
}