import React, { useContext, useEffect, useRef } from 'react';
import { Context } from '../AccelProvider/AccelProvider';
import S3Uploader, { AbortEventArgs, BeginEventArgs, CompleteEventArgs, ErrorEventArgs, ProgressEventArgs } from '../../utils/s3-uploader/s3-uploader'
import { AccelFile } from '../../models';
import type { UploadFileArgs } from '../../api/methods/file';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import { DisposableStack } from '../../utils';
import { useAudioContext } from '../hooks/useAudioRecorder';

export type FileUploaderHandler = {
    upload: () => Promise<boolean>;
    abort: () => Promise<boolean>;
}

type Props = {
    file: AccelFile;
    autostart?: boolean;
    uploadArgs?: UploadFileArgs;
    /**
     * @deprecated use handlerRef instead via FileUploader.useHandler
     */
    handler?: (handler: FileUploaderHandler) => void;
    handlerRef?: React.RefObject<FileUploaderHandler>;
    onReady?: () => void;
    onBegin?: (args: BeginEventArgs) => void;
    onProgress?: (args: ProgressEventArgs) => void;
    onComplete?: (args: CompleteEventArgs) => void;
    onPrepare?: () => void;
    onError?: (args: ErrorEventArgs) => void;
    onAbort?: (args: AbortEventArgs) => void;
    children?: (state: FileUploaderState) => React.ReactNode;
}

export type FileUploaderState = {
    file: AccelFile;
    percent: number;
    status: 'idle' | 'uploading' | 'aborted' | 'completed' | 'error';
}

function FileUploader({ file, autostart, uploadArgs, handler, handlerRef, onReady, onPrepare, onAbort, onBegin, onComplete, onError, onProgress, children }: Props) {
    const { api } = useContext(Context);

    const store = useLocalStore(() => ({
        uploader: new S3Uploader(api),
        disposableStack: new DisposableStack(),
        state: {
            file,
            percent: 0,
            status: file.id != null && file.nativeFile == null
                ? 'completed'
                : 'idle'
        } as FileUploaderState,
        setState: (state: Partial<FileUploaderState>) => {
            runInAction(() => {
                store.state = { ...store.state, ...state };
            });
        }
    }));
    const [getAudioMeta] = useAudioContext();

    useEffect(() => {
        const h: FileUploaderHandler = {
            upload: () => {
                return store.uploader.upload();
            },
            abort: () => {
                return store.uploader.abort();
            }
        };
        handler?.(h);
        if (handlerRef)
            //@ts-ignore
            handlerRef.current = h;
    }, []);

    useEffect(() => {

        if (!file.nativeFile) {
            onError?.({ errors: [] });
            return;
        }
        const args = uploadArgs ?? {};

        store.disposableStack.push(store.uploader.onBegin.on(x => {
            file.update({ id: x.id });
            store.setState({
                percent: 0,
                status: 'uploading'
            });
            onBegin?.(x);
        }));
        store.disposableStack.push(store.uploader.onProgress.on(x => {
            store.setState({
                percent: x.percent,
            });
            onProgress?.(x);
        }));
        store.disposableStack.push(store.uploader.onComplete.on(x => {
            file.update({
                nativeFile: null,
                cloudKey: x.file.cloudKey,
                type: x.file.type,
                isPublic: args.isPublic ?? x.file.isPublic
            }, true);
            store.setState({
                percent: 100,
                status: 'completed'
            });
            onComplete?.(x);
        }));
        store.disposableStack.push(store.uploader.onPrepare.on(x => {
            onPrepare?.();
        }));
        store.disposableStack.push(store.uploader.onAborted.on(x => {
            store.setState({
                status: 'aborted'
            });
            onAbort?.(x);
        }));
        store.disposableStack.push(store.uploader.onError.on(x => {
            store.setState({
                status: 'error'
            });
            onError?.(x);
        }));

        (async () => {
            if (file.nativeFile) {
                args.fileId = file.id;

                if (args.isPublic == null)
                    args.isPublic = file.isImage;

                if (file.isAudio) {
                    try {
                        const audioMeta = await getAudioMeta(file.nativeFile);
                        args.meta = audioMeta;
                        file.update({ additionalData: audioMeta });
                    } catch (e) {
                        // skip decoding and continue without metadata
                        console.warn(`Couldn't decode audio meta`, e);
                    }
                }

                store.uploader.setFile(file.nativeFile, args);

                if (autostart !== false)
                    store.uploader.upload();

                onReady?.();
            }
        })();

        return () => store.disposableStack.dispose();
    }, []);

    if (!children) return null;
    return <>{children(store.state)}</>;
}

FileUploader.useHandler = () => {
    const ref = useRef<FileUploaderHandler>(null);
    return ref;
}

export default observer(FileUploader);