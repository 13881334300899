import { observable, action } from 'mobx';
import { Entity, Course, AccelFile, Library } from '..';

export enum SchoolContentItemType {
    Folder = 'folder',
    Item = 'item'
}

export enum SchoolContentSectionType {
    Course = 'course',
    Library = 'library'
}

export default class SchoolContentItem extends Entity {
    constructor(item?: Partial<SchoolContentItem>) {
        super(item);
        if (item) this.update(item);
    }

    @observable title: string;
    @observable description: string;
    @observable isPublished: boolean;
    @observable cover: AccelFile | null;

    @observable createdDate: Date;
    @observable updatedDate: Date;
    @observable position: number;
    @observable parentId: string | null;
    @observable parent: SchoolContentItem | null;
    @observable course: Course | null;
    @observable library: Library | null;
    @observable type: SchoolContentItemType;
    @observable sectionType: SchoolContentSectionType;

    static fromJson(json: any): SchoolContentItem {
        return new SchoolContentItem({
            id: json.id,
            title: json.title,
            description: json.description,
            isPublished: json.isPublished,
            type: json.type,
            sectionType: json.sectionType,
            position: json.position,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            cover: json.cover ? AccelFile.fromJson(json.cover) : null,
            parentId: json.parentId,
            parent: json.parent ? SchoolContentItem.fromJson(json.parent) : json.parent,
            course: json.course ? Course.fromJson(json.course) : json.course,
            library: json.library ? Library.fromJson(json.library) : json.library
        });
    }

    @action
    update(changes: Partial<SchoolContentItem>) {
        super.update(changes);
    }
}

