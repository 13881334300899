import React from "react";
import { Input, InputRef, InputNumber } from 'antd';
import EditableField, { EditableFieldCommonProps, CancelSource } from '../CustomEditableField/CustomEditableField';
import { Localization, KeyGenerator } from '../../../utils';
import { observer } from 'mobx-react';
import { Context } from '../../AccelProvider/AccelProvider';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type Props = {
    value?: any;
    dashed?: boolean;
    blur?: boolean;
    onBlur?: (self: EditableField<number>, e: React.FocusEvent<Element>) => void;
    autofocus?: boolean;
    inputSize?: SizeType,
    placeholder?: string | undefined,
    loc?: Localization,
    className?: string;
    min?: number;
    max?: number;
}
@observer
export class NumberEditableField extends React.Component<Props & EditableFieldCommonProps<number>> {
    static contextType = Context;
    context!: React.ContextType<typeof Context>;

    get loc(): Localization { return this.context.loc }
    get keygen(): KeyGenerator { return this.context.keygen }
    editInputRef: InputRef | any | null;

    static defaultProps: Partial<Props & EditableFieldCommonProps<number>> = {
        dashed: false,
        blur: true,
        clickOutside: false,
        autofocus: true,
        inputSize: 'middle',
    }

    handleChange(self: EditableField<number>, e: any) {
        self.value = parseInt(e);
        self.onChange({} as any);
    }

    handleBlur(self: EditableField<number>, e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (this.props.onBlur)
            this.props.onBlur(self, e);
        if (this.props.blur != true)
            return;
        self.value = parseInt(e.target.value);
        self.onSave(e.nativeEvent, 'custom');
    }

    handleSaveClick(self: EditableField<number>, e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        self.onSave(e.nativeEvent, 'button');
    }

    handleCancelClick(self: EditableField<number>, e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        self.onCancel(e.nativeEvent);
        return false;
    }

    setEditInputRef(input: InputRef | any | null) {
        this.editInputRef = input;
        if (input != null && this.props.autofocus == true)
            input.focus();
    }

    handleClick(self: EditableField<number>, e: React.MouseEvent<Element, MouseEvent>): void {
        if (this.props.editTrigger != 'click')
            return;
        e.preventDefault();
        e.stopPropagation();
        self.editing = true;
    }

    handleDoubleClick(self: EditableField<number>, e: React.MouseEvent<Element, MouseEvent>): void {
        if (this.props.editTrigger != 'dblclick')
            return;
        e.preventDefault();
        e.stopPropagation();
        self.editing = true;
    }

    handleCancel(self: EditableField<number>, e: Event, source: CancelSource) {
        if (this.props.onCancel)
            this.props.onCancel(self, e, source);
    }

    getInputTitle() {
        switch (this.props.editTrigger) {
            case 'click': return this.loc.word('Click to edit');
            case 'dblclick': return this.loc.word('Double click to edit');
            default: return '';
        }
    }

    render() {
        const id = this.keygen.generate();
        return <>
            {this.props.value}
            <EditableField
                {...this.props}
                id={id}
                editTrigger='custom'
                onCancel={this.handleCancel.bind(this)}
                render={self => {
                    return <InputNumber size={this.props.inputSize} title={this.getInputTitle()} readOnly value={self.value} defaultValue={this.props.defaultValue}
                        data-dashed={this.props.dashed}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        onClick={e => this.handleClick(self, e)}
                        onDoubleClick={e => this.handleDoubleClick(self, e)}
                        style={{ width: this.props.width }} />;
                }}
                renderEdit={self => {
                    // we shouldn't set value when using formitem's validation rules
                    const props = this.props.rules ? {} : { value: self.value };
                    return (
                        <InputNumber {...props}
                            ref={this.setEditInputRef.bind(this)}
                            onChange={e => this.handleChange(self, e)}
                            onBlur={e => this.handleBlur(self, e)}
                            size={this.props.inputSize}
                            disabled={self.saving || this.props.disabled}
                            placeholder={this.props.placeholder}
                            style={{ width: this.props.width }}
                            min={this.props.min}
                            max={this.props.max} />
                    );
                }}
                className={this.props.className}
            />
        </>;
    }
}
