export enum PaymentGatewayType {
    Prodamus = 'prodamus',
    YandexCheckout = 'yandexCheckout',
    RoboKassa = 'roboKassa',
    Stripe = 'stripe',
    PayPal = 'payPal',
    Cash = 'cash',
    CloudPayments = 'cloudPayments',
    WireTransfer = 'wireTransfer',
    NewStripe = 'newStripe',
    Midtrans = 'midtrans',
    Tinkoff = 'tinkoff',
    Sberbank = 'sberbank',
    Fondy = 'fondy',
    SquareUp = 'squareUp',
    BePaid = 'bePaid',
    AuthorizeNet = 'authorizeNet',
    SmartGlocal = 'smartGlocal',
    BitPay = 'bitPay',
    EcPay = 'ecPay',
    TinkoffInstallment = 'tinkoffInstallment',
    Mercadopago = 'mercadopago',
    CyberSource = 'cyberSource',
    WayForPay = 'wayForPay',
    Mobbex = 'mobbex'
}
