import { Updatable } from '../../..';

export default class MercadopagoSettings extends Updatable {
    public publicKey: string;
    public accessToken: string;
    public widgetMode: boolean;

    constructor(settings?: Partial<MercadopagoSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<MercadopagoSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}