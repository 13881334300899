import { AccelFile, Entity } from '../../';
import { observable, action } from 'mobx';

export default class CourseImage extends Entity {
    constructor(courseImage?: Partial<CourseImage>) {
        super(courseImage);
        if (courseImage) this.update(courseImage);
    }
    @observable file: AccelFile;

    static fromJson(json: any): CourseImage {
        return new CourseImage({
            id: json.id,
            file: json.file ? AccelFile.fromJson(json.file) : undefined
        });
    }

    @action
    update(courseImage: Partial<CourseImage>) {
        super.update(courseImage);
    }
}
