export { }

declare global {
  interface URL {
    toDecodedString(): string;
  }
}

if (!URL.prototype.toDecodedString) {
  URL.prototype.toDecodedString = function (): string {
    return decodeURI(this.toString());
  }
}