import { DependencyList, useEffect } from "react";

export function useClipboardFiles(cb: (file: File) => void, enabled: boolean = true, deps?: DependencyList) {
    useEffect(() => {
        if (enabled === false) return;

        const onPaste = (e: globalThis.ClipboardEvent) => {
            const fileData = Array.from(e.clipboardData!.items)
                .find(x => x.kind == 'file');
            const file = fileData?.getAsFile();
            if (!file) return;
            cb(file);
        }
        document.addEventListener('paste', onPaste);
        return () => {
            document.removeEventListener('paste', onPaste);
        }
    }, deps);
}