import { observable, action } from 'mobx';
import { CourseItemTask, Entity } from '../../../..';
import CourseItemTaskFieldSettingsFactory from './settings/CourseItemTaskFieldSettingsFactory';
import CourseItemTaskFieldListSettings from './settings/CourseItemTaskFieldListSettings';
import { ISerializable } from '../../../../Entity';


export enum CourseItemTaskFieldType {
    TextField = 'textField',
    TextArea = 'textArea',
    Checkbox = 'checkbox',
    MultiSelect = 'multiSelect',
    MonoSelect = 'monoSelect'
}

export default class CourseItemTaskField extends Entity implements ISerializable {
    constructor(field?: Partial<CourseItemTaskField>) {
        super(field);
        if (field) this.update(field);
    }
   

    @observable title: string;
    @observable placeholder: string;
    @observable description: string;
    @observable required: boolean;
    @observable position: number;
    @observable type: CourseItemTaskFieldType;
    @observable task: CourseItemTask;
    @observable settings: any;

    get listSettings() {
        return this.settings as CourseItemTaskFieldListSettings;
    }

    get isListType() {
        return this.type == CourseItemTaskFieldType.MonoSelect || this.type == CourseItemTaskFieldType.MultiSelect;
    }

    toJson() {
        return {
            id: this.id,
            title: this.title,
            placeholder: this.placeholder,
            description: this.description,
            required: this.required,
            position: this.position,
            type: this.type,
            taskId: this.task?.id,
            additionalData: this.settings ? JSON.stringify(this.settings) : undefined,
        };
    }

    @action update(field: Partial<CourseItemTaskField>, allowUndefined = false) {
        super.update(field, allowUndefined);
    }

    static fromJson(json: any) {
        return new CourseItemTaskField({
            id: json.id,
            title: json.title,
            placeholder: json.placeholder,
            description: json.description,
            required: json.required,
            position: json.position,
            type: json.type,
            task: json.task ? CourseItemTask.fromJson(json.task) : undefined,
            settings: CourseItemTaskFieldSettingsFactory.build(json.type, json.additionalData),
        });
    }
}