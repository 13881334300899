export { }


declare global {
    interface Number {
        inBetween(from: number, to: number, includeBounds?: boolean): boolean;
        toFixedValue(fixed: number, truncateIfZero: boolean): string;
    }
}

if (!Number.prototype.inBetween) {
    Number.prototype.inBetween = function (from: number, to: number, includeBounds: boolean = true): boolean {
        if (includeBounds) {
            return this >= from && this <= to;
        } else {
            return this > from && this < to;
        }
    }
}

if (!Number.prototype.toFixedValue) {
    Number.prototype.toFixedValue = function (fixed: number, truncateIfZero: boolean): string {
        const intPart = Math.trunc(this as number);
        const fractPart = (this as number) - intPart;
        if (truncateIfZero === true && fractPart == 0)
            return intPart.toString();
        return this.toFixed(fixed);
    }
}