import { Course, CourseItem, Contact, Entity } from '../..';
import { action, observable, computed } from 'mobx';
import { isEmpty } from '../../../utils';

export declare type ContactCourseState = 'notStarted' | 'inProgress' | 'ended';

export default class ContactCourse extends Entity {
    constructor(item?: Partial<ContactCourse>) {
        super(item);
        if (item) this.update(item);
    }

    createdDate: Date;
    updatedDate: Date;
    lastActivityDate: Date;
    @observable beginDate?: Date;
    @observable endDate?: Date;
    @observable state: ContactCourseState;
    @observable progress: number;
    @observable contact: Contact;
    @observable course: Course;
    @observable currentCourseItem: CourseItem;
    @observable feedback: string;
    @observable score: number;

    @computed get hasFeedback() {
        return !isEmpty(this.feedback);
    }

    static fromJson(json: any): ContactCourse {
        return new ContactCourse({
            id: json.id,
            softDeleted: json.softDeleted,
            state: json.state,
            progress: json.progress,
            feedback: json.feedback,
            score: json.score,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
            course: json.course ? Course.fromJson(json.course) : undefined,
            currentCourseItem: json.currentCourseItem ? CourseItem.fromJson(json.currentCourseItem) : undefined,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            lastActivityDate: json.lastActivityDate ? new Date(json.lastActivityDate) : undefined,
            beginDate: json.beginDate ? new Date(json.beginDate) : undefined,
            endDate: json.endDate ? new Date(json.endDate) : undefined,
        });
    }

    @action
    update(course: Partial<ContactCourse>) {
        super.update(course);
    }
}