import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Popconfirm, Row, Typography } from 'antd';
import { AccelFile, User } from '../../models';
import { DateTime, Loc, SpaceSeparator, UserAvatar } from '..';
import { Context } from '../AccelProvider/AccelProvider';
import CommentFormEditor from './CommentFormEditor';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FileListSize, FileListViewType } from '../Files/Files';
import { FileSize, combineClasses, combineRefs } from '../../utils';
import { observer } from 'mobx-react';

export declare type CommentFormHandler = {
    focus: () => void;
    blur: () => void;
    hasUploadingFiles: () => boolean;
    cancelVoiceRecording: () => void;
    setText: (text: string) => void;
    insertText: (text: string) => void;
    setFiles: (files: AccelFile[]) => void;
    setVoices: (files: AccelFile[]) => void;
};

export type CommentFilesProps = {
    forbiddenExtensions?: string[];
    view?: FileListViewType;
    size?: FileListSize;
    draggable?: boolean;
    maxFiles?: number;
    maxFileSize?: FileSize;
};

export type CommentFormProps = {
    handlerRef?: React.MutableRefObject<CommentFormHandler | undefined>;

    author?: User;
    editing?: boolean;
    canCancelEditing?: boolean;
    disabled?: boolean;
    sendDisabled?: boolean;

    defaultText?: string;
    defaultFiles?: AccelFile[];
    defaultVoices?: AccelFile[];

    text?: string;
    files?: AccelFile[];
    voices?: AccelFile[];

    useEmoji?: boolean;
    useVoice?: boolean;
    useFiles?: boolean;
    fileProps?: CommentFilesProps;
    /**
     * 'combined' - can send voice and text/files at the same time
     *
     * 'split' - can send only voice or text/files
     */
    voiceMode?: 'combined' | 'split';
    audioPlayerStyle?: React.CSSProperties;

    placeholder?: string;
    emptyText?: React.ReactNode;
    textRenderer?: (text: string) => React.ReactNode;

    autoFocus?: boolean;
    autoSize?: { minRows?: number; maxRows?: number };
    maxLength?: number;
    clearAfterSending?: boolean;
    enterToSubmit?: boolean;

    className?: string;
    style?: React.CSSProperties;
    editorClassName?: string;
    editorStyle?: React.CSSProperties;
    inputClassName?: string;
    iconClassname?: string;
    borderless?: boolean;

    actionAddons?: (handler: CommentFormHandler) => React.ReactNode[];
    authorRender?: (user: User) => React.ReactNode;
    extraFirstInOrderControl?: (editing: boolean) => React.ReactNode;

    onSend?: (text: string, files: AccelFile[], replyRemoved?: boolean) => Promise<boolean>;
    onTextChange?: (text: string) => void;
    onFilesChange?: (
        files: AccelFile[],
        changes: {
            added?: AccelFile[];
            deleted?: AccelFile[];
        }
    ) => void;
    onVoicesChange?: (
        files: AccelFile[],
        changes: {
            added?: AccelFile[];
            deleted?: AccelFile[];
        }
    ) => void;

    // voice recording
    micDeviceId?: string;
    audioPlayerSize?: SizeType;
    onVoiceRecordingStarted?: () => void;
    onVoiceRecordingEnded?: (file: AccelFile) => void;
    onVoiceRecordingCanceled?: () => void;
};

/**
 * Comment editor with edit/remove/cancel controls and readonly mode
 */
const CommentForm: React.FC<CommentFormProps & {
    createdDate?: moment.Moment;
    updatedDate?: moment.Moment;
    canEdit?: boolean;
    canRemove?: boolean;
    authorClassName?: string;
    extra?: (editing: boolean) => React.ReactNode;
    onRemove?: () => Promise<boolean>;
    onCancel?: () => void;
    canReply?: boolean;
    replyTo?: React.ReactNode;
    onReply?: () => void;
    onReplyRemoved?: () => void;
}> = ({ author, authorClassName, createdDate, updatedDate, canEdit, canRemove, canCancelEditing = true, onRemove, onCancel, replyTo, onReply, onReplyRemoved, canReply, extra, ...props }) => {
    const ctx = useContext(Context);
    const { loc, user } = ctx;
    const formRef = useRef<CommentFormHandler>();

    useEffect(() => {
        if (props.handlerRef && formRef.current)
            combineRefs(props.handlerRef, formRef)(formRef.current);
    }, []);

    const [editing, setEditing] = useState(props.editing ?? false);
    // clone value props to support cancelling
    const [editingText, setEditingText] = useState(props.text ?? props.defaultText ?? '');
    const [editingFiles, setEditingFiles] = useState((props.files ?? props.defaultFiles ?? []).slice());
    const [editingVoices, setEditingVoices] = useState((props.voices ?? props.defaultVoices ?? []).slice());
    const [editingReplyRemoved, setEditingReplyRemoved] = useState(false);

    useEffect(() => {
        setEditingText(props.defaultText!);
    }, [props.defaultText]);

    useEffect(() => {
        setEditingFiles(props.defaultFiles!);
    }, [props.defaultFiles]);

    useEffect(() => {
        setEditingVoices(props.defaultVoices!);
    }, [props.defaultVoices]);

    const edit = useCallback(() => {
        setEditing(true);
    }, []);

    const send = useCallback(async (a: string, b: AccelFile[]) => {
        if (!props.onSend) {
            console.error('CommentForm.onSend is not defined');
            return false;
        }
        const res = await props.onSend(a, b, editingReplyRemoved);
        if (res) {
            setEditing(props.editing === true ? true : false);

            if (props.clearAfterSending == true) {
                onReplyRemoved?.();
            }
        }
        return res;
    }, [props.onSend, props.clearAfterSending, onReplyRemoved, props.editing]);

    const cancelEdit = useCallback(() => {
        // reset edited values with default values
        formRef.current?.setText(props.text ?? props.defaultText ?? '');
        formRef.current?.setFiles((props.files ?? props.defaultFiles ?? []).slice());
        formRef.current?.setVoices((props.voices ?? props.defaultVoices ?? []).slice());
        setEditing(false);
        setEditingReplyRemoved(false);
        onCancel?.();
    }, [props, onCancel]);

    return <div className={combineClasses('flex', props.className)}>
        {author && <UserAvatar user={author} size={40} className="mr-10" />}
        <div className="flex-1">
            <div className="flex gap-10">
                {(author != null && user != null && !editing)
                    ? props.authorRender != undefined
                        ? props.authorRender(author)
                        : <Typography.Text className={authorClassName}>{author.id === user?.id ? loc.word('You') : author.fullName}</Typography.Text>
                    : null}
                {(replyTo != null && !editingReplyRemoved) && <Row align="middle">{replyTo}</Row>}
            </div>

            <CommentFormEditor
                {...props}
                handlerRef={formRef}
                defaultText={editingText}
                defaultFiles={editingFiles}
                defaultVoices={editingVoices}
                onSend={send}
                clearAfterSending={props.clearAfterSending ?? false}
                borderless={editing ? props.borderless : true}
                editing={editing}
            />

            <div className="fs-11 text-placeholder">
                <SpaceSeparator separator={'dot'} align="center">
                    {createdDate && <span>
                        <Loc word="Sent" />
                        &nbsp;
                        <DateTime date={createdDate} />
                    </span>}

                    {updatedDate && <span>
                        <Loc word="Updated" />
                        &nbsp;
                        <DateTime date={updatedDate} />
                    </span>}

                    {props.extraFirstInOrderControl?.(editing)}

                    {(editing && canCancelEditing !== false) && <Button type="link" size="small" className="fs-11" onClick={cancelEdit}>
                        {loc.word('Global.cancel', { case: 'lower' })}
                    </Button>}

                    {(!editing && canReply) && <Button disabled={editing} type="link" size="small" className="fs-11" onClick={() => onReply?.()}>
                        {loc.word('Reply', { case: 'lower' })}
                    </Button>}

                    {(!editing && canEdit) && <Button disabled={editing} type="link" size="small" className="fs-11" onClick={edit}>
                        {loc.word('Edit', { case: 'lower' })}
                    </Button>}

                    {(!editing && canRemove) && <Popconfirm
                        title={<Loc
                            word="Comment.delete.confirm"
                            default="Are you sure you want to delete this comment?" />}
                        onConfirm={onRemove}>
                        <Button disabled={editing} type="link" size="small" className="fs-11">
                            {loc.word('Remove', { case: 'lower' })}
                        </Button>
                    </Popconfirm>}
                </SpaceSeparator>
            </div>
        </div>
        {extra?.(editing)}
    </div>
};

export default observer(CommentForm);
