import { FileType } from '../../models';
import mime from 'mime-types';

export function fileTypeToExtensions(type: FileType) {
    switch (type) {
        case FileType.Image:
            return ['.bmp', '.dib', '.rle', '.jpg', '.jpeg', '.jpe', '.jfif', '.gif', '.tif', '.tiff', '.png', '.svg', '.pjpeg', '.webp', '.ico'];
        case FileType.Doc:
            return ['.doc', '.docx'];
        case FileType.Audio:
            return ['.mp3', '.wav'];
        case FileType.Pdf:
            return ['.pdf'];
        case FileType.Ppt:
            return ['.ppt', '.pptx'];
        case FileType.Video:
            return ['.webm', '.mkv', '.avi', '.mp4', '.m4v', '.ogm', '.wmv', '.mpg', '.ogv', '.mov', '.asx', '.mpeg', '.mxf'];
        case FileType.Xls:
            return ['.xls', '.xlsx'];
        case FileType.Zip:
            return ['.zip', '.rar'];
        case FileType.Json:
            return ['.json'];
        case FileType.Csv:
            return ['.csv'];
        case FileType.Other:
        default:
            return [];
    }
}

export function toMimeTypes(types: FileType[], includeExtensions = false) {
    const mimeTypes = types.map(x => {
        const exts = fileTypeToExtensions(x);
        const mimeTypes  = exts.map(y => mime.lookup(y)).filter(y => y !== false) as string[];
        return includeExtensions ? [...mimeTypes, ...exts] : mimeTypes;
    });
    return mimeTypes;
}

export function toMimeTypesString(types: FileType[], includeExtensions = false) {
    const mimeTypes = toMimeTypes(types, includeExtensions);
    // join works with array of arrays
    return mimeTypes.join(',');
}