import { action, observable, computed } from 'mobx';
import { Contact, Entity, AccelFile, SchoolContentItemComment, CourseItem, LibraryItem, SchoolContentSectionType } from '../..';
import { isEmpty } from '../../../utils';

export default class SchoolContentItemProgressRating extends Entity {
    constructor(item?: Partial<SchoolContentItemProgressRating>) {
        super(item);
        if (item) this.update(item);
    }
    @observable comment: string;
    @observable contact: Contact;
    @observable createdDate: Date;
    @observable updatedDate: Date;
    @observable comments: SchoolContentItemComment[];
    @observable commentsCount: number;
    @observable rating: number;
    @observable files: AccelFile[];
    @observable courseItem: CourseItem;
    @observable libraryItem: LibraryItem;
    @observable sectionType: SchoolContentSectionType;

    @computed get hasMoreComments() {
        return this.commentsCount > this.comments.length;
    }

    @computed get isCommentEmpty() {
        return isEmpty(this.comment) && this.files.length == 0;
    }

    @action
    update(comment: Partial<SchoolContentItemProgressRating>) {
        super.update(comment);
    }

    clone() {
        return new SchoolContentItemProgressRating({
            ...this,
            files: this.files.slice(),
        });
    }

    static fromJson(json: any): SchoolContentItemProgressRating {
        return new SchoolContentItemProgressRating({
            ...json,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            comments: json.comments ? json.comments.map(SchoolContentItemComment.fromJson) : undefined,
            files: json.files ? json.files.map(AccelFile.fromJson) : [],
            courseItem: json.courseItem ? CourseItem.fromJson(json.courseItem) : undefined,
            libraryItem: json.libraryItem ? LibraryItem.fromJson(json.libraryItem) : undefined
        });
    }
}
