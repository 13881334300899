import { observable } from "mobx";
import { isEmpty } from "../../../utils";
import BaseChannelSettings from "./BaseChannelSettings";

export default class FbChannelSettings extends BaseChannelSettings {
    @observable pageId: string;
    @observable pageName: string;

    constructor(settings?: Partial<FbChannelSettings>) {
        super();
        if (settings) this.update(settings);
    }

    get isValid() {
        return !isEmpty(this.accessToken) && this.hasPage;
    }

    get hasPage() {
        return !isEmpty(this.pageId) && !isEmpty(this.pageName);
    }

    update(entity: Partial<FbChannelSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    hasChanges(settings: FbChannelSettings) {
        return super.hasChanges(settings)
            || this.pageId !== settings.pageId;
    }

    clone() {
        return new FbChannelSettings(this);
    }
}