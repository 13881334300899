
import { Currency } from '../../../enums/currency';
import { BaseFilter, PaymentGatewayType } from '../..';
import BillingPaymentMethod from './BillingPaymentMethod';
import { observable } from 'mobx';

export default class BillingPaymentMethodFilter extends BaseFilter<BillingPaymentMethod> {
    constructor(filter?: Partial<BillingPaymentMethodFilter>) {
        super();
        if (filter) this.update(filter);
    }

    @observable gatewayType?: PaymentGatewayType;

    update(changes: Partial<BillingPaymentMethodFilter>) {
        super.update(changes);
    }
}