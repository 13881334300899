import { action, computed, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';


export class NameFieldItemSettings extends QuizItemSettings {
    constructor(s?: Partial<NameFieldItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @computed get hasRequiredField() {
        if (this.showFirstName && this.firstNameRequired) return true;
        if (this.showMiddleName && this.middleNameRequired) return true;
        if (this.showLastName && this.lastNameRequired) return true;
        return false;
    }

    @observable showFirstName: boolean;
    @observable firstNameRequired: boolean;
    @observable firstNamePlaceholder: string;

    @observable showMiddleName: boolean;
    @observable middleNameRequired: boolean;
    @observable middleNamePlaceholder: string;

    @observable showLastName: boolean;
    @observable lastNameRequired: boolean;
    @observable lastNamePlaceholder: string;

    @action update(q: Partial<NameFieldItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<NameFieldItemSettings>) {
        return new NameFieldItemSettings({
            ...QuizItemSettings.default,
            showFirstName: true,
            firstNameRequired: true,

            showMiddleName: true,
            middleNameRequired: false,

            showLastName: true,
            lastNameRequired: false,
            ...settings
        });
    }

    clone() {
        return new NameFieldItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return NameFieldItemSettings.build(json);
    }
}
