import { observable, action } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { Entity, Flow } from '..';

export default class FlowDate extends Entity {
    constructor(item?: Partial<FlowDate>) {
        super(item);
        if (item) this.update(item);
    }

    @observable name: string;
    @observable beginDate: Moment;
    @observable endDate: Moment;
    @observable flow: Flow;

    public get duration() {
        if (this.beginDate != null && this.endDate != null) {
            const days = Math.round((this.endDate.toDate().getTime() - this.beginDate.toDate().getTime()) / (1000 * 3600 * 24));
            return days < 0 ? 0 : days;
        }
        return 0;
    }


    static fromJson(json: any): FlowDate {
        return new FlowDate({
            ...json,
            beginDate: json.beginDate ? moment(json.beginDate) : undefined,
            endDate: json.endDate ? moment(json.endDate) : undefined,
            flow: json.flow ? Flow.fromJson(json.flow) : undefined,
        });
    }

    @action
    update(item: Partial<FlowDate>) {
        super.update(item);
    }
}

