import { observable } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { AccelFile, Entity, Video, Widget } from '../..';
import AccelFileConversion from '../../file/Conversion/AccelFileConversion';

export enum WebinarContentType {
    File = 'file',
    Presentation = 'presentation',
    Stream = 'stream',
    Widget = 'widget',
    Video = 'video',
}

export default class WebinarContent extends Entity {
    constructor(file?: Partial<WebinarContent>) {
        super(file);
        if (file) this.update(file);
    }

    @observable createdDate: Moment;
    @observable file: AccelFile;
    @observable type: WebinarContentType;
    @observable conversion: AccelFileConversion;
    // stream | video
    @observable url: string;
    @observable widget: Widget;
    @observable video: Video;

    static fromJson(json: any): WebinarContent {
        return new WebinarContent({
            id: json.id,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            file: json.file ? AccelFile.fromJson(json.file) : undefined,
            type: json.type,
            url: json.url,
            conversion: json.conversion ? AccelFileConversion.fromJson(json.conversion) : undefined,
            widget: json.widget ? Widget.fromJson(json.widget) : undefined,
            video: json.video ? Video.fromJson(json.video) : undefined,
        });
    }

    update(file: Partial<WebinarContent>, allowUndefined: boolean = false) {
        super.update(file, allowUndefined);
    }
}
