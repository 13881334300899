import { observable, action } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { AccelFile, Entity } from '../..';

export enum FileConversionType {
    PdfToImages = 'pdfToImages'
}

export enum FileConversionStatus {
    Pending = 'pending',
    InProgress = 'inProgress',
    Success = 'success',
    Error = 'error'
}

export default class AccelFileConversion extends Entity {
    constructor(conversion?: Partial<AccelFileConversion>) {
        super(conversion);
        if (conversion) this.update(conversion);
    }

    createdDate: Moment;
    type: FileConversionType;
    @observable status: FileConversionStatus;
    @observable data: any;
    @observable progress: number;
    file: AccelFile;

    static fromJson(json: any) {
        return new AccelFileConversion({
            id: json.id,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            type: json.type,
            status: json.status,
            data: json.dataJson ? JSON.parse(json.dataJson) : undefined,
            progress: json.progress,
            file: json.file ? AccelFile.fromJson(json.file) : undefined
        });
    }

    @action
    update(conversion: Partial<AccelFileConversion>, allowUndefined = false) {
        super.update(conversion, allowUndefined);
    }
}
