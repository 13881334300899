import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from 'antd';
import styles from './DropdownPopover.module.scss';
import { combineClasses } from '../../utils';

type DropdownSelectProps = {
    content: any;
    transparent?: boolean;
    shadow?: boolean;
    placement?: "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight";
    disabled?: boolean;
    trigger?: ('click' | 'hover' | 'contextMenu')[];
    arrow?: boolean;
    defaultOpen?: boolean;
    open?: boolean;
    onOpenChange?: (visible: boolean) => void;
    overlayClassName?: string;
}

/**
 * @deprecated better use Popover instead
 * @param param0 
 * @returns 
 */
const DropdownPopover: React.FC<DropdownSelectProps> = ({ transparent, shadow, arrow, children, placement, content, trigger, disabled, defaultOpen, open, onOpenChange, overlayClassName }) => {

    const [visible, setVisible] = useState(open ?? defaultOpen ?? false);

    useEffect(() => {
        if (open !== undefined)
            setVisible(open);
    }, [open]);

    return <Dropdown placement={placement}
        trigger={trigger}
        disabled={disabled}
        open={visible}
        arrow={arrow}
        onOpenChange={x => {
            setVisible(x);
            onOpenChange?.(x);
        }}
        overlayClassName={combineClasses(overlayClassName,
            styles.dropdown_select,
            shadow === true ? 'shadow' : undefined,
            transparent === true ? styles.transparent : undefined)}
        overlay={
            <Menu>
                <Menu.Item key='first'>
                    {content}
                </Menu.Item>
            </Menu>
        }>
        {children}
    </Dropdown>;
}

DropdownPopover.defaultProps = {
    trigger: ['click'],
    placement: 'bottomLeft'
}

export default DropdownPopover;