
import { observable, action } from 'mobx';
import { Entity, Contact, AccelFile, ForumReaction } from '../..';

export default class TopicComment extends Entity {
    constructor(comment?: Partial<TopicComment>) {
        super(comment);
        if (comment) this.update(comment);
    }

    @observable text: string;

    @observable createdDate: Date;
    @observable updatedDate?: Date;
    @observable reply: TopicComment | null;
    @observable contact: Contact;
    @observable files: AccelFile[];
    @observable sequenceNumber: number;

    @observable likeCount: number = 0;
    @observable dislikeCount: number = 0;
    @observable currentUserReaction: ForumReaction | null;

    static fromJson(json: any): TopicComment {
        return new TopicComment({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            reply: json.replyToComment ? TopicComment.fromJson(json.replyToComment) : null,
            files: json.files ? json.files.map(AccelFile.fromJson) : [],
            contact: json.student ? Contact.fromJson(json.student) : null,
            currentUserReaction: json.currentUserReaction ? ForumReaction.fromJson(json.currentUserReaction) : null
        });
    }

    @action
    update(comment: Partial<TopicComment>) {
        super.update(comment);
    }
}

