import React, { useContext, useMemo } from "react";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { Context } from "../AccelProvider/AccelProvider";


type DateRangePickerProps = RangePickerProps & {
    pickerRef?: React.RefObject<any>;
    /**
     * Show future periods to select
     */
    futureRange?: boolean;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ pickerRef, futureRange, ...props }) => {
    const { loc } = useContext(Context);

    const ranges = useMemo<any>(() => {
        if (props.ranges) return props.ranges;
        if (futureRange === true)
            return {
                [`1 ${loc.wordPlural(1, { one: 'days_1', two: 'days_2', other: 'days_5' })}`]: [moment(), moment().add(1, 'days')],
                [`15 ${loc.wordPlural(15, { one: 'days_1', two: 'days_2', other: 'days_5' })}`]: [moment(), moment().add(15, 'days')],
                [loc.word('Month')]: [moment(), moment().add(1, 'months')],
                [`3 ${loc.wordPlural(3, { one: 'months_1', two: 'months_2', other: 'months_5' })}`]: [moment(), moment().add(3, 'months')],
                [`6 ${loc.wordPlural(6, { one: 'months_1', two: 'months_2', other: 'months_5' })}`]: [moment(), moment().add(6, 'months')],
            };
        return {
            [loc.word('DateRange.todayLabel', { default: 'Today' })]: [moment().startOf('day'), moment().endOf('day')],
            [loc.word('DateRange.thisWeekLabel', { default: 'This week' })]: [moment().startOf('week'), moment().endOf('week')],
            [loc.word('DateRange.lastWeekLabel', { default: 'Last week' })]: [moment().add(-1, 'weeks').startOf('week'), moment().add(-1, 'weeks').endOf('week')],
            [loc.word('DateRange.thisMonthLabel', { default: 'This month' })]: [moment().startOf('month'), moment().endOf('month')],
            [loc.word('DateRange.lastMonthLabel', { default: 'Last month' })]: [moment().add(-1, 'months').startOf('month'), moment().add(-1, 'months').endOf('month')],
        };
    }, [futureRange, props.ranges]);

    return <DatePicker.RangePicker
        {...props}
        ref={pickerRef}
        //@ts-ignore
        showTime={props.showTime ?? { format: "HH:mm", defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }}
        placeholder={props.placeholder ?? [loc.word('DateRange.startDatePlaceholder', { default: 'Start date' }), loc.word('DateRange.endDatePlaceholder', { default: 'End date' })]}
        ranges={ranges}
    />;
}

export default DateRangePicker;