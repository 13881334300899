import React, { useCallback, useEffect, useRef } from 'react';
import { Breadcrumb as AntBreadcrumb, BreadcrumbProps as AntBreadcrumbProps, } from 'antd';
import { debounce } from 'lodash';
import AntBreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';

type Props = AntBreadcrumbProps & {
    wrapperClassname?: string;
    wrap?: boolean;
}
const Breadcrumb: React.FC<Props> & { Item: typeof AntBreadcrumbItem } = ({ children, className, itemRender, params, separator, style, prefixCls, wrapperClassname }) => {

    // const wrapperRef = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     scrollRight();
    //     const onResize = debounce(() => scrollRight(), 100, { maxWait: 100 });
    //     window.addEventListener('resize', onResize);
    //     return () => {
    //         window.removeEventListener('resize', onResize);
    //     };
    // }, []);

    // const scrollRight = useCallback(() => {
    //     setTimeout(() => {
    //         if (wrapperRef.current)
    //             wrapperRef.current.scrollLeft = 9999;
    //     }, 10);
    // }, []);

    return <div className={wrapperClassname}>
        <AntBreadcrumb separator={separator} className={className} params={params} itemRender={itemRender}  >
            {children}
        </AntBreadcrumb>
    </div>;
}
Breadcrumb.Item = AntBreadcrumbItem;
export default Breadcrumb;