import { type Moment } from 'moment';
import ContactCourse, { ContactCourseState } from "./contact-course";
import { BaseFilter } from '../..';

export default class ContactCourseFilter extends BaseFilter<ContactCourse> {
    constructor(filter?: Partial<ContactCourseFilter>) {
        super();
        if (filter) this.update(filter);
    }

    id: string;
    studentId: string;
    courseId: string;
    currentCourseItemId: string;
    beginDateFrom: Moment | null;
    beginDateTo: Moment | null;
    endDateFrom: Moment | null;
    endDateTo: Moment | null;
    state: ContactCourseState;
    hasFeedback: boolean;

    update(changes: Partial<ContactCourseFilter>) {
        super.update(changes);
    }
}