import { Updatable } from '../../..';
import { ISerializable } from '../../../Entity';

export default class WayForPaySettings extends Updatable implements ISerializable {
    accountId: string;
    secretKey: string;
    widgetMode: boolean;

    constructor(settings?: Partial<WayForPaySettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<WayForPaySettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    toJson() {
        return {
            ...this
        }
    }
}