import { ApiListResponse, ApiRequest, HttpMethod } from '../../..';
import { FileManagerItem, FileManagerItemFilter, FileManagerItemType } from '../../../../models';

export class FetchFileManagerItemsRequest extends ApiRequest {
	public method = HttpMethod.GET;
	public fields = `{
        id,
        name,
		updatedDate,
		createdDate,
		type,
		parentId,
		softDeleted,
		duration,
		size,
		folderCount,
		itemCount,
		file {
			id,
			name,
			size,
			type,
			mimeType,
			cloudKey,
			extension,
			isPublic
		},
		video {
            id,
            name,
            shortDescription,
			createdDate,
			updatedDate,
            primaryImage {
				id,
            	file {
                    id,
					name,
					size,
					type,
					mimeType,
					cloudKey,
					extension,
					isPublic
                }
            },
            currentVideoStream {
				id,
				duration,
				progress,
				width,
				height,
				state,
				subState,
				errorMessage,
				createdDate,
				file {
					id,
					name,
					size,
					type,
					mimeType,
					cloudKey,
					extension,
					isPublic
                }
            }
        },
		site{
			id
		}
    }`;
	public url: string = '/file-manager';
	public body = {
		...this.filter
	};

	constructor(public filter?: FileManagerItemFilter, fields?: string) {
		super();
		this.fields = fields || this.fields;
	}
}

export class FetchFileManagerItemsResponse extends ApiListResponse<FileManagerItem, FileManagerItemFilter> {
	public path: FileManagerItem[];

	constructor(response: any) {
		super(response);
		this.filter = new FileManagerItemFilter(this.body.filter);
		this.items = this.body.items.map(FileManagerItem.fromJson);
		//@ts-ignore extra field
		this.path = this.body.path.map((x: any) => FileManagerItem.fromJson({ ...x, type: FileManagerItemType.Directory }));
	}
}
