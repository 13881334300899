import { action, computed, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';

export class IndentItemSettings extends QuizItemSettings {
    constructor(s?: Partial<IndentItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable height: number;


    @action update(q: Partial<IndentItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<IndentItemSettings>) {
        return new IndentItemSettings({
            ...QuizItemSettings.default,
            height: 20,
            ...settings
        });
    }

    clone() {
        return new IndentItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return IndentItemSettings.build(json);
    }
}
