import { SpaceProps } from 'antd/lib/space';
import React, { ReactNode, useMemo } from "react";
import { combineClasses } from '../../utils';
import styles from './SpaceSeparator.module.scss';

type SpaceSeparatorProps = SpaceProps & {
    separator: 'comma' | 'verticalBar' | 'dot' | 'space' | 'slash' | 'arrowRight' | 'hyphen';
    placeholder?: ReactNode;
    inline?: boolean;
    wrap?: boolean;
    className?: string;
    itemClassName?: string;
}
const SpaceSeparator: React.FC<SpaceSeparatorProps> = (props) => {

    const children = useMemo(() => {
        return React.Children.toArray(props.children).filter(x => !!x);
    }, [props.children]);

    if (children.length == 0) return <>{props.placeholder ?? null}</>;
    return <div className={combineClasses(styles.space_separator, props.className)} data-wrap={props.wrap} data-inline={props.inline} data-separator={props.separator}>
        {children.map((x, i) => <div key={i} className={combineClasses(styles.item, props.itemClassName)}>{x}</div>)}
    </div>;
}
SpaceSeparator.defaultProps = {
    separator: 'dot',
    inline: true,
    wrap: true
}
export default SpaceSeparator;

