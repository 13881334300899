import { action, observable } from "mobx";
import { Updatable } from "../..";

export default class WebinarRegistrationDesignSettings extends Updatable {
    constructor(settings?: Partial<WebinarRegistrationDesignSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable backgroundColor: string;
    @observable textColor: string;

    @observable buttonBackgroundColor: string;
    @observable buttonTextColor: string;
    @observable buttonText: string;

    @observable logoUrl: string;
    @observable coverUrl: string;

    @action update(settings: Partial<WebinarRegistrationDesignSettings>, allowUndefined: boolean = false) {
        super.update(settings, allowUndefined);
    }

    hasChanges(settings: WebinarRegistrationDesignSettings) {
        return this.backgroundColor != settings.backgroundColor
            || this.textColor != settings.textColor
            || this.buttonTextColor != settings.buttonTextColor
            || this.buttonText != settings.buttonText
            || this.buttonBackgroundColor != settings.buttonBackgroundColor
            || this.logoUrl != settings.logoUrl
            || this.coverUrl != settings.coverUrl;
    }

    copy() {
        return new WebinarRegistrationDesignSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return new WebinarRegistrationDesignSettings({
            ...json
        });
    }
}