import { observable, action, toJS } from 'mobx';
import Entity from '../../Entity';
import AccelFile from '../../file/accel-file';
import moment from 'moment';
import { KeyGenerator } from '../../../utils';

export declare type SiteFileConfirmationType = 'content' | 'file';
export default class SiteFileConfirmation extends Entity {

    constructor(confirm?: Partial<SiteFileConfirmation>) {
        super(confirm);
        if (confirm) this.update(confirm);
    }

    @observable name: string;
    @observable path: string;
    @observable type: SiteFileConfirmationType;
    @observable content: string;
    @observable file: AccelFile;

    @observable createdDate: moment.Moment;
    @observable updatedDate: moment.Moment;

    _key = KeyGenerator.generate(5);

    hasChanges(b: SiteFileConfirmation) {
        return this.name != b.name
            || this.path != b.path
            || this.type != b.type
            || this.content != b.content
            || this.file?.id != b.file?.id;
    }

    clone() {
        return new SiteFileConfirmation({
            ...this
        })
    }

    toJson() {
        return toJS({
            id: this.id,
            name: this.name,
            path: this.path,
            type: this.type,
            content: this.content,
            fileId: this.file?.id,
        });
    }

    @action
    update(confirm: Partial<SiteFileConfirmation>, allowUndefined?: boolean) {
        super.update(confirm, allowUndefined);
    }

    static default() {
        return new SiteFileConfirmation({
            type: 'file'
        });
    }

    static fromJson(json: any): SiteFileConfirmation {
        return new SiteFileConfirmation({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            file: json.file ? AccelFile.fromJson(json.file) : null,
        });
    }
}

