import moment from 'moment';
import { BaseFilter } from '../../models';
import isEmpty from './isEmpty';

function toCleanObject(f: BaseFilter<any>) {
    const newObj = {};
    Object.keys(f).forEach(key => {
        // skip empty values
        if (isEmpty(f[key]) && f[key] !== null) return;
        let valueToCopy = f[key];

        if (typeof f[key] === "object"
            // skip arrays (usually string arrays)
            && !Array.isArray(f[key])
            // skip dates
            && f[key] instanceof Date === false) {

            // convert moment to Date
            if (moment.isMoment(f[key])) {
                valueToCopy = f[key].toDate();
            }
            // exception for softDeleted field
            else if (f[key] === null) {
                valueToCopy = null;
            }
            else {
                // recurse
                valueToCopy = toCleanObject(f[key]);
                if (f[key] instanceof BaseFilter === true)
                    removeFilterDefaults(valueToCopy);
            }
        }
        // copy value
        newObj[key] = valueToCopy;
    });
    return newObj;
}

function removeFilterDefaults(filter: any) {
    if (!filter || typeof filter !== 'object' || Array.isArray(filter)) return;
    if (filter.skip == 0)
        delete filter['skip'];
    if (filter.take == 25)
        delete filter['take'];
    if (filter.useBaseFilter == true)
        delete filter['useBaseFilter'];
    if (filter.softDeleted == false)
        delete filter['softDeleted'];
    if (filter.useSort == true)
        delete filter['useSort'];
    if (filter.useItemsTotal == true)
        delete filter['useItemsTotal'];
    delete filter['itemsTotal'];
    if (isEmpty(filter.search))
        delete filter['search'];
}

export function toSelectStringFilter(f: BaseFilter<any>) {
    return toQueryStringFilter(f).replaceAll('\"', '\'');
}

export function toQueryStringFilter(f: BaseFilter<any>) {
    const filter = toCleanObject(f);
    removeFilterDefaults(filter);
    return JSON.stringify(filter);
}