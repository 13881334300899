import React from 'react';

export default function combineRefs(...refs: Array<any>): React.RefCallback<any> {
    return (target: any) => {
        refs.forEach((ref: any) => {
            if (!ref) return;
            if (typeof ref === 'function') {
                ref(target);
            } else {
                ref.current = target;
            }
        });
    };
}