import { AudioOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import styles from './VoiceInput.module.scss';
import VoiceRecordDuration from './components/VoiceRecordDuration/VoiceRecordDuration';
import { TimeSpan } from '../../../../utils';
import Loc from '../../../Loc/Loc';
import VoiceRecordButton from './components/VoiceRecordButton/VoiceRecordButton';
import VoiceRecordLock from './components/VoiceRecordLock/VoiceRecordLock';
import { debounce } from 'lodash';
import useAudioRecorder, { type MediaAudioTrackConstraints, useAudioContext } from '../../../hooks/useAudioRecorder';
import { AccelFile } from '../../../../models';
import { useHandler, withHandler } from '../../../HOC/withHander2';
import { Context } from '../../../AccelProvider/AccelProvider';
import { useDevice } from '../../../hooks/useDevice';
import VoiceRecordTouchSlideToCancel from './components/VoiceRecordCancel/VoiceRecordCancel';

export type VoiceInputHandler = {
    cancelRecording: () => void;
}
type Props = {
    deviceId?: string;
    iconClassname?: string;
    disabled?: boolean;
    onRecordingStarted?: () => void;
    onRecordingEnded: (file: AccelFile) => void;
    onRecordingCanceled?: () => void;
}
const VoiceInput = withHandler<Props, VoiceInputHandler>(({ deviceId, handler, iconClassname, disabled, onRecordingStarted, onRecordingEnded, onRecordingCanceled }) => {

    const { loc } = useContext(Context);
    const ref = useRef<HTMLDivElement>(null);
    const [outside, setOutside] = React.useState(false);
    const [recording, setRecording] = React.useState(false);
    const [locked, setLocked] = React.useState(false);
    /**
     * swipe up to lock
     */
    const [dragUpDistance, setDragUpDistance] = React.useState(0);
    /**
     *  swipe left to cancel (only on touch device)
     */
    const [dragLeftDistance, setDragLeftDistance] = React.useState(0);

    const [getAudioMeta] = useAudioContext();

    const audioConstraints = useMemo<MediaAudioTrackConstraints>(() => ({ noiseSuppression: true, echoCancellation: true, deviceId }), [deviceId]);

    const {
        startRecording,
        stopRecording,
        recordingBlob,
        recordingTime,
    } = useAudioRecorder(audioConstraints,
        () => {
            notification.error({ message: loc.word('Comment.voice.micAccessError', { default: 'Microphone not found or not allowed' }), duration: 2 });
            console.error('Microphone not found or not allowed');
        }, undefined);

    useHandler(handler, {
        cancelRecording: () => {
            cancelledRef.current = true;
            stopRecording();
        }
    }, [stopRecording]);

    useEffect(() => {
        if (!recordingBlob) return;
        if (!cancelledRef.current && (lockedRef.current || !outsideRef.current)) {
            (async () => {
                const meta = await getAudioMeta(recordingBlob);
                const ext = meta.formatName?.includes('webm') ? 'webm' : 'mp4';
                const nativeFile = new File([recordingBlob], `voice.${ext}`, { type: meta.formatName });
                onRecordingEnded(AccelFile.fromFile(nativeFile, { additionalData: meta, isVoice: true }));
            })();
        } else {
            onRecordingCanceled?.();
        }

        recordingRef.current = false;
        lockedRef.current = false;
        outsideRef.current = false;
        cancelledRef.current = false;
        setRecording(false);
        setLocked(false);
        setOutside(false);
        setDragUpDistance(0);
        setDragLeftDistance(0);
    }, [recordingBlob, onRecordingCanceled, onRecordingEnded]);

    const lockedRef = useRef(false);
    const outsideRef = useRef(false);
    const buttonPressedRef = useRef(false);
    const recordClickedDownRef = useRef(false);
    const recordTimerRef = useRef<any>(null);
    const cancelledRef = useRef(false);
    const recordingRef = useRef(false);
    const elPositionRef = useRef<DOMRect>();
    const initialMousePositionRef = useRef<{ x: number, y: number }>({ x: 0, y: 0 });

    useEffect(() => {
        if (disabled) return;
        const mouseDownHandler = (e: MouseEvent) => {
            recordClickedDownRef.current = true;
            initialMousePositionRef.current = { x: e.clientX, y: e.clientY };
            elPositionRef.current = ref.current!.getBoundingClientRect();

            recordTimerRef.current = setTimeout(async () => {
                if (await startRecording()) {
                    onRecordingStarted?.();
                    buttonPressedRef.current = true;
                    cancelledRef.current = false;
                    setDragLeftDistance(0);
                    setDragUpDistance(0);
                    setRecording(true);
                    recordingRef.current = true;
                    return;
                }
                console.error('Start recording error. Microphone not found or not allowed.');
            }, 150);
        }
        ref.current?.addEventListener('pointerdown', mouseDownHandler);

        return () => {
            ref.current?.removeEventListener('pointerdown', mouseDownHandler);
        }
    }, [startRecording, onRecordingStarted, disabled]);

    useEffect(() => {
        const mouseMoveHandler = debounce((e: MouseEvent) => {
            if (!buttonPressedRef.current || lockedRef.current) return;

            const mouseDragDistance = initialMousePositionRef.current.y - e.clientY - (initialMousePositionRef.current.y - (elPositionRef.current?.top ?? 0));
            setDragUpDistance(Math.max(0, Math.min(mouseDragDistance, 100)));
            if (mouseDragDistance >= 50) {
                setLocked(true);
                setOutside(true);
                lockedRef.current = true;
                outsideRef.current = true;
            }
        }, 30, { leading: true, trailing: true, maxWait: 30 });
        document.addEventListener('mousemove', mouseMoveHandler);

        const mouseLeaveHandler = () => {
            if (!buttonPressedRef.current || lockedRef.current) return;
            setOutside(true);
            outsideRef.current = true;
        }
        ref.current?.addEventListener('mouseleave', mouseLeaveHandler);

        const mouseEnterHandler = () => {
            if (!buttonPressedRef.current || locked) return;
            setOutside(false);
            outsideRef.current = false;
        }
        ref.current?.addEventListener('mouseenter', mouseEnterHandler);

        return () => {
            document.removeEventListener('mousemove', mouseMoveHandler);
            ref.current?.removeEventListener('mouseleave', mouseLeaveHandler);
            ref.current?.removeEventListener('mouseenter', mouseEnterHandler);
        }
    }, []);


    useEffect(() => {
        const touchMoveHandler = debounce((e: TouchEvent) => {
            console.logDev('touch move', e.touches[0]);
            if (!buttonPressedRef.current || lockedRef.current) return;

            const touchDragUpDistance = initialMousePositionRef.current.y - e.touches[0].clientY - (initialMousePositionRef.current.y - (elPositionRef.current?.top ?? 0));
            const touchDragLeftDistance = initialMousePositionRef.current.x - e.touches[0].clientX - (initialMousePositionRef.current.x - (elPositionRef.current?.left ?? 0));

            setDragUpDistance(Math.max(0, touchDragUpDistance));
            setDragLeftDistance(Math.max(0, touchDragLeftDistance));
            if (touchDragUpDistance >= 50) {
                setLocked(true);
                setOutside(true);
                lockedRef.current = true;
                outsideRef.current = true;
            }

            if (touchDragLeftDistance >= 100) {
                cancelledRef.current = true;
                stopRecording();
            }
        }, 30, { leading: true, trailing: true, maxWait: 30 });
        document.addEventListener('touchmove', touchMoveHandler);

        return () => {
            document.removeEventListener('touchmove', touchMoveHandler);
        }
    }, [stopRecording]);

    useEffect(() => {
        const mouseUpHandler = () => {
            // record btn was not pressed
            if (!recordClickedDownRef.current) return;
            recordClickedDownRef.current = false;

            clearTimeout(recordTimerRef.current);
            if (!recordingRef.current && !buttonPressedRef.current && !lockedRef.current) {
                // TODO make a notification
                console.log('Please hold the mouse button pressed to record voice message');
                return;
            }
            buttonPressedRef.current = false;
            if (!lockedRef.current) {
                lockedRef.current = false;
                stopRecording();
            }
        }
        document.addEventListener('mouseup', mouseUpHandler);
        document.addEventListener('touchend', mouseUpHandler);

        return () => {
            document.removeEventListener('mouseup', mouseUpHandler);
            document.removeEventListener('touchend', mouseUpHandler);
        }
    }, [stopRecording]);

    const device = useDevice();

    return <div ref={ref} className={styles.voice_input} data-recording={recording}>
        <div className={styles.voice_input_overlay}>
            <VoiceRecordLock locked={locked} recording={recording} percentToLock={dragUpDistance == 0 ? 100 : (dragUpDistance / 75) * 100} />
            <VoiceRecordDuration duration={TimeSpan.fromSeconds(recordingTime)} />
            {locked
                ? <Button className='p-0'
                    type='link'
                    onClick={() => {
                        cancelledRef.current = true;
                        stopRecording();
                    }}>
                    <Loc word='Comment.voice.cancel' default='Cancel' />
                </Button>
                : device.isTouch
                    ? <VoiceRecordTouchSlideToCancel recording={recording} locked={locked} cancelProgress={dragLeftDistance} />
                    : <Loc word='Comment.voice.cancel.help' default='Release outside this field to cancel' className='text-placeholder' />}

            <VoiceRecordButton outside={outside} locked={locked} onStopRecording={stopRecording} />
        </div>
        <Button type='link' icon={<AudioOutlined className={iconClassname} />} disabled={disabled} size='large' className={styles.voice_input_record_btn}></Button>
    </div>;
});

export default VoiceInput;