import React, { useCallback } from "react";
import { Button, Popover } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css';

type Props = {
    disabled: boolean;
    iconClassname?: string;
    onSelect: (emoji: string) => void;
}
const EmojiInput: React.FC<Props> = ({ disabled, iconClassname, onSelect }) => {

    const addEmoji = useCallback((emoji: string) => {
        onSelect(emoji)
    }, [onSelect]);

    return <Popover content={<Picker native
        onSelect={(data) => addEmoji(data['native'])}
        sheetSize={16}
        emojiSize={16}
        showSkinTones={false}
        showPreview={false} />}
        overlayClassName="p-0"
        trigger={'click'}
    >
        <Button type='link'
            size='large'
            className="emoji-picker-btn"
            disabled={disabled}
            icon={<SmileOutlined className={iconClassname} />} />
    </Popover>;
}

export default EmojiInput;