
import { action, observable } from 'mobx';
import { LocalizationLanguage, LocalizationWord, Entity } from '../';

export default class LocalizationTranslation extends Entity {
    constructor(item?: Partial<LocalizationTranslation>) {
        super(item);
        if (item) this.update(item);
    }

    @observable id: string;
    @observable text: string;
    @observable suggestedText?: string;
    @observable language: LocalizationLanguage;
    @observable word: LocalizationWord;
    @observable isVerified: boolean;

    static fromJson(json: any): LocalizationTranslation {
        return new LocalizationTranslation({
            ...json,
            language: json.language ? LocalizationLanguage.fromJson(json.language) : undefined,
            word: json.word ? LocalizationWord.fromJson(json.word) : undefined,
        });
    }

    @action update(translation: Partial<LocalizationTranslation>, allowUndefined = false) {
        super.update(translation, allowUndefined);
    }
}