import { action, observable } from "mobx";
import moment, { type Moment } from "moment";
import { Entity } from "../..";

export default class PipelineTag extends Entity {
    constructor(t?: Partial<PipelineTag>) {
        super(t);
        if (t) this.update(t);
    }
    @observable name: string;
    @observable color: string;
    @observable position?: number;

    createdDate: Moment;
    updatedDate?: Moment;

    static fromJson(json: any) {
        return new PipelineTag({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
        });
    }

    @action
    update(t: Partial<PipelineTag>) {
        super.update(t);
    }

    clone(t?: Partial<PipelineTag>): PipelineTag {
        return new PipelineTag({
            ...this,
            ...t
        });
    }
}