import React, { useState } from 'react';
import { HomeOutlined, RollbackOutlined, UploadOutlined } from '@ant-design/icons';
import { Spin, Modal, notification } from 'antd';
import { Localization, FileProvider } from '../../utils';
import FileManagerStore, { ViewFileManagerItem } from './FileManagerStore';
import styles from './FileManager.module.scss';
import { AccelFile, FileManagerItem, FileType } from '../../models';
import { action, reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import FileManagerOneItem from './FileManagerItem';
import { DragDropProvider, useDrop } from '../DnD';
import { FileItemStore } from '../../stores';
import { Context } from '../AccelProvider/AccelProvider';


export enum FileManagerMode {
	Free = "Free",
	SelectOneURL = "SelectOneUrl",
	SelectFileId = "SelectFileId",
	SelectFile = "SelectFile"
}

export type FileManagerProps = {
	visible?: boolean;
	accept?: FileType,
	onClose?: () => void,
	mode?: FileManagerMode
	onSelected?: (selectedData: string | number | AccelFile | null) => void;
};

/**
 * @deprecated remove later
 */
@observer
export default class FileManager extends React.Component<FileManagerProps> {
	static contextType = Context;
	context: React.ContextType<typeof Context>;

	loc: Localization;;
	fileManagerStore: FileManagerStore;
	fileProvider: FileProvider;
	fileItemStore: FileItemStore;
	mode: FileManagerMode = this.props.mode || FileManagerMode.Free;
	backButtonRef: React.RefObject<HTMLDivElement> = React.createRef();

	constructor(props: FileManagerProps, context: any) {
		super(props, context);
		this.loc = context.loc;
		this.fileProvider = context.fileProvider;
		this.fileManagerStore = new FileManagerStore(context.api, context.loc, context.keygen);
	}

	@action
	showModal() {
		this.fileManagerStore.isVisible = true;
		this.fileManagerStore.updateFilter({ fileType: this.props.accept ?? undefined });
		this.fileManagerStore.fetchFiles();
	}

	@action
	closeModal() {
		this.fileManagerStore.isVisible = false;
		if (typeof this.props.onClose == "function") this.props.onClose();
	}

	async uploadFiles(files: File[]) {
		await this.fileManagerStore.uploadFiles(files);
	}

	async createDirectory() {
		var createResult = await this.fileManagerStore.createFileManagerDirectory();
		if (createResult.success == false || createResult.response.errors.length > 0) {
			notification.error({
				message: this.loc.word("Folder creation error"),
				description: createResult.response.errors[0].message
			});
		}
	}

	renameSelected() {
		var item = this.fileManagerStore.selectedItems[0];
		if (!item) return;
		if (item.itemHandler?.rename) item.itemHandler?.rename();
	}

	onSelected() {
		if (!this.props.onSelected || this.fileManagerStore.selectedItems.length == 0)
			return;
		switch (this.mode) {
			case FileManagerMode.SelectOneURL:
				var item = this.fileManagerStore.selectedItems[0];
				var itemUrl = this.fileProvider.getUrlByKey(item.file!.cloudKey);
				this.props.onSelected(itemUrl ?? null);
				this.closeModal();
				break;
			case FileManagerMode.SelectFileId:
				var item = this.fileManagerStore.selectedItems[0];
				this.props.onSelected(item.file!.id);
				this.closeModal();
				break;
			case FileManagerMode.SelectFile:
				var item = this.fileManagerStore.selectedItems[0];
				this.props.onSelected(item.file!);
				this.closeModal();
				break;
		}
	}


	deleteSelected(item?: ViewFileManagerItem) {
		Modal.confirm({
			okText: this.loc.word("Delete"),
			title: this.loc.word("Are you sure you want to delete this files?"),
			cancelText: this.loc.word("Cancel"),
			onOk: () => {
				var items = item ? [item] : this.fileManagerStore.selectedItems;
				items.forEach(async x => {
					var deleteResult = await this.fileManagerStore.removeItemFromServer(x);
					if (deleteResult.success) {
						this.fileManagerStore.removeItemFromList(x);
					} else if (deleteResult.success == false) {
						if (deleteResult.response.errors.length > 0) {
							notification.error({
								message: this.loc.word("Deletion error"),
								description: deleteResult.response.errors[0].message
							});
						}
					}

				})
			}
		});
	}

	holder() {
		return <DragDropProvider>
			<div className={styles.fileManagerHolder}>
				{this.path()}

				{
					!this.fileManagerStore.isLoaded ?
						<div className={styles.fileManagerTreeHolder + " " + styles.fileManagerTreeHolderLoading}>
							<Spin></Spin>
						</div>
						:
						<div className={styles.fileManagerTreeHolder} ref={this.backButtonRef}>
							{this.fileManagerStore.currentParentId && <BackButton store={this.fileManagerStore} />}
							{this.fileManagerStore.items.length > 0 ?
								this.fileManagerStore.items.map(x => <FileManagerOneItem key={x.id} item={x} fileManager={this} managerStore={this.fileManagerStore} />) :
								<div className={styles.fileManagerNoFileAlert}>{this.loc.word("You have no uploaded files")}</div>}
						</div>
				}


				<div className={styles.fileManagerFooter}>
					{this.menu()}
					{this.dropzone()}
				</div>
			</div>
		</DragDropProvider>
	}

	path() {
		return (
			<div className={styles.fileManagerPath}>
				<a className={styles.fileManagerPathItem} onClick={() => this.fileManagerStore.changeParent()}>	<HomeOutlined /></a>
				{this.fileManagerStore.path.map((x: FileManagerItem, index: number) => {
					//@ts-ignore
					if (index == this.fileManagerStore.path.length - 1) return <a key={x.id} disabled className={styles.fileManagerPathItem}>{x.name}</a>
					return <a key={x.id} className={styles.fileManagerPathItem} onClick={() => this.fileManagerStore.changeParent(x.id)}>{x.name}</a>;
				})}
			</div>
		);
	}

	menu() {
		return <div className={styles.menu}>
			{
				this.fileManagerStore.selectedItems.length == 0
					? <a onClick={() => this.createDirectory()}>{this.loc.word("Create Folder")}</a>
					: <>
						<span>{this.loc.word("Files selected")}: {this.fileManagerStore.selectedItems.length}</span>
						{this.fileManagerStore.selectedItems.length == 1 && <a onClick={() => this.renameSelected()}>{this.loc.word("Rename")}</a>}
						<a onClick={() => this.deleteSelected()}>{this.loc.word("Delete")}</a>
					</>
			}
		</div>
	}

	get dropzoneAccept() {
		switch (this.props.accept) {
			case FileType.Image: return 'image/jpeg, image/pjpeg, image/png, image/gif, image/icon, image/jpg, image/svg+xml, image/svg, image/webp';
			case FileType.Video: return 'video/x-flv, video/mp4, video/quicktime';
			case FileType.Pdf: return 'application/pdf';
			case FileType.Audio:
			case FileType.Other:
			default: return undefined;
		}
	}

	dropzone() {
		return (
			<Dropzone onDrop={files => this.uploadFiles(files)} accept={this.dropzoneAccept}>
				{({ getRootProps, getInputProps }) => (
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<div className={styles.dropzone_holder}>
							<UploadOutlined className="mr-20" />
							<span>{this.loc.word('FileUploader.uploadMsg', { default: 'Click or drag files to upload' })}</span>
						</div>
					</div>
				)}
			</Dropzone>
		);
	}


	componentDidMount() {
		var a = this.fileManagerStore;
		reaction(
			() => this.props.visible,
			() => {
				runInAction(() => {
					this.fileManagerStore.isVisible = this.props.visible == true;
				})
				if (this.props.visible) this.showModal()
				else (this.closeModal())
			}
		)
	}

	render() {
		return <Modal
			width="80vw"
			keyboard={false}
			bodyStyle={{ height: '600px' }}
			title={this.loc.word('File Manager')}
			open={this.fileManagerStore.isVisible}
			destroyOnClose={true}
			onCancel={() => this.closeModal()}
			footer={null}>
			{this.holder()}
		</Modal>
	}
}



const BackButton: React.FC<{ store: FileManagerStore }> = ({ store }) => {
	const [isDropActive, setIsDropActive] = useState(false);

	const [, dropRef] = useDrop<ViewFileManagerItem, HTMLDivElement>({
		onDrop: async (src, _, e) => {
			src.data.itemHandler?.changeParent(src.data, store.parentFolder)
		},
		onEnter: () => setIsDropActive(true),
		onLeave: () => setIsDropActive(false)
	});

	const classes = [styles.fileManagerTreeItem];
	if (isDropActive) classes.push(styles.fileManagerTreeItemDropActive);

	return <div ref={dropRef} className={classes.join(" ")} onClick={() => store.goOneLevelUp()}>
		<div className={styles.fileManagerTreeItemGoUp}>
			<RollbackOutlined />
		</div>
	</div>
}
