import React from "react";

type Props = {
    item: any;
}
/**
 * хак для обертки label в AntSelect для того, чтобы передать в него item (вместо title)
 * @param param0 
 * @returns 
 */
const AntSelectLabel: React.FC<Props> = ({ children }) => {
    return <>
        {children}
    </>;
}
export default AntSelectLabel;