import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { User } from '../../models';
import { AccelContext, Context } from '../AccelProvider/AccelProvider';
import { observer } from 'mobx-react';
import { Avatar } from 'antd';
import { combineClasses, isEmpty } from '../../utils';

const defaultColors = [
    '#2ecc71', // emerald
    '#3498db', // peter river
    '#8e44ad', // wisteria
    '#e67e22', // carrot
    '#e74c3c', // alizarin
    '#1abc9c', // turquoise
    '#2c3e50', // midnight blue
];

type UserAvatarProps = {
    user: User,
    shape?: 'circle' | 'square';
    size?: 'large' | 'small' | 'default' | number;
    color?: string;
    useShortName?: boolean;
    className?: string;
    ctx?: AccelContext;
    resize?: { w?: number, h?: number };
}

type UserAvatarState = {
    avatarUrl: string | null;
    shortName: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ user, ...props }) => {
    const fileProvider = props.ctx 
        ? props.ctx.fileProvider 
        : useContext(Context).fileProvider;

    const shortName = useMemo(() => {
        let str = '';
        if (!isEmpty(user.firstName))
            str += user.firstName[0].toUpperCase();
        if (!isEmpty(user.lastName))
            str += user.lastName[0].toUpperCase();
        if (isEmpty(str) && !isEmpty(user.email))
            return user.email[0].toUpperCase();
        return str;
    }, [user]);

    const getState = (): UserAvatarState => {
        return {
            avatarUrl: user.avatar != null
                ? fileProvider.getUrl(user.avatar) ?? null
                : null,
            shortName: props.useShortName == false ? user.fullName : shortName
        };
    }
    const [state, setState] = useState<UserAvatarState>(getState());

    useEffect(() => {
        setState(getState());
    }, [user]);

    const getRandomColor = useCallback(() => {
        const str = !isEmpty(user.email) ? user.email : user.fullName;
        if (isEmpty(str)) return defaultColors[0];
        let sum = 0;
        for (let i = 0; i < str.length; i++) {
            sum += str.charCodeAt(i);
        }
        const i = sum % defaultColors.length;
        return defaultColors[i];
    }, [user]);

    const getFontSize = useCallback(() => {
        if (typeof props.size == 'number')
            return props.size / 2.5;
        return undefined;
    }, [props.size]);

    const style = useMemo(() => {
        if (state.avatarUrl) return undefined;
        return {
            backgroundColor: props.color ?? getRandomColor(),
            fontSize: getFontSize()
        } as React.CSSProperties;
    }, [getRandomColor, getFontSize]);    
    
    const url = useMemo(() => {
        let url = state.avatarUrl;
        if (url?.startsWith('http') && props.resize) {
            try {
                const parsedUrl = new URL(url);
                if (props.resize.h) parsedUrl.searchParams.append('d', `h${props.resize.h}`);
                else if (props.resize.w) parsedUrl.searchParams.append('d', `w${props.resize.w}`);
                url = parsedUrl.toString();
            } catch { }
        }
        return url;
    }, [props.resize, state.avatarUrl]);

    return <Avatar
        className={combineClasses(props.className, 'flex flex-center')}
        shape={props.shape}
        size={props.size}
        style={style}
        src={url ?? undefined}
        onError={() => {
            setState({
                ...state,
                avatarUrl: null
            });
            return false;
        }}>
        {state.shortName}
    </Avatar>;
}

UserAvatar.defaultProps = {
    shape: 'circle',
    size: 'default',
    useShortName: true,
    resize: { w: 85, h: 85 }
};

export default observer(UserAvatar);
