import React, { useMemo } from "react";
import getNewRelicScript from './getNewRelicScript';

export type NewRelicProps = {
	key: string;
	appId: string;
	accountId: string;
}

const NewRelic: React.FC<NewRelicProps> = (props) => {

	const script = useMemo(() => getNewRelicScript(props), []);

	return React.createElement("script", { type: "text/javascript", dangerouslySetInnerHTML: { __html: script } });
};

export default NewRelic;