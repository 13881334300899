import { isEmpty } from "../../../utils";
import { ApplicationSettings } from "./ApplicationSettings";

export default class TgGroupsApplicationSettings extends ApplicationSettings {

    constructor(s?: Partial<TgGroupsApplicationSettings>) {
        super();
        if (s) this.update(s);
    }

    public accessToken: string;
    public botId: string;
    public botName: string;

    get isValid() {
        return !isEmpty(this.accessToken) && this.hasBot;
    }

    get hasBot() {
        return !isEmpty(this.botId) && !isEmpty(this.botName);
    }

    update(entity: Partial<TgGroupsApplicationSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    static fromJson(json: any): TgGroupsApplicationSettings {
        return new TgGroupsApplicationSettings({ ...json });
    }
}