export function saveAs(url: string, targetBlank: boolean = false, filename: string = '') {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
        document.body.appendChild(link); // firefox fix
        link.download = filename;
        link.href = url;
        if (targetBlank)
            link.target = '_blank';
        link.click();
        document.body.removeChild(link);
    } else {
        location.replace(url);
    }
}