import { RefSelectProps, Select } from "antd";
import { observer } from "mobx-react";
import React, { useRef } from "react";
import SelectFooter from "../components/SelectFooter/SelectFooter";
import useSelect, { BaseSelectProps } from "../components/SelectBase/SelectBase";
import styles from '../Select.module.scss';
import { combineClasses, combineRefs } from "../../../utils";

type PropsToOmit = 'options' | 'labelInValue' | 'dropdownRender' | 'mode';
export type SelectProps<T> = Omit<BaseSelectProps<T>, PropsToOmit>
    & {
        mode?: 'multiple' | 'tags' | 'mono';
        selectRef?: React.RefObject<RefSelectProps>;
        dataSource: T[];
    }
function AntSelect<T>(props: SelectProps<T>) {

    const { dataSource, itemLabel, itemLabelValue, itemKey, mode: _mode, selectRef, value: _value, defaultValue, menuFooter, inline, onChange, onChangeSingle, ...selectProps } = props;

    const internalSelectRef = useRef<RefSelectProps>(null);

    const { mode, value, options, handleChange } = useSelect(props, props.dataSource, x => itemKey?.(x) ?? x as any);

    return <Select
        {...selectProps}
        mode={mode}
        ref={combineRefs(internalSelectRef, selectRef)}
        value={value}
        options={options}
        onChange={handleChange}
        labelInValue
        dropdownRender={menuFooter
            ? menu => <SelectFooter footer={menuFooter} children={menu} />
            : undefined}
        bordered={!inline}
        className={combineClasses(styles.accel_ant_select, selectProps.className)}
        data-size={props.size}
        showAction={['focus', 'click']}
    />;
}

AntSelect.useRef = () => {
    const ref = useRef<RefSelectProps>(null);
    return ref;
}

(AntSelect as React.FC<SelectProps<any>>).defaultProps = {
    showArrow: true
}
export default observer(AntSelect);