import { useEffect, useRef, useState } from 'react';
import { Timer, TimeSpan } from '../../utils';

type TimerController = {
    set: (fn: () => void, span: TimeSpan, fireImmediately?: boolean) => void;
    setInterval: (fn: () => void, span: TimeSpan, fireImmediately?: boolean) => void;
    clear: () => void;
    isActive: boolean;
}
export function useTimer() {
    const timer = useRef<Timer>(new Timer());
    const [controller] = useState<TimerController>({
        set: (fn, span, fireImmediately = false) => {
            controller.isActive = true;
            timer.current.set(fn, span, fireImmediately = false);
        },
        setInterval: (fn, span, fireImmediately = false) => {
            controller.isActive = true;
            timer.current.setInterval(fn, span, fireImmediately);
        },
        clear: () => {
            timer.current.clear();
            controller.isActive = false;
        },
        isActive: false,
    });

    useEffect(() => {
        return () => controller.clear();
    }, []);

    return controller;
}
