import { throttle } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { TimeSpan } from '../../../utils';
import { useRefCallback } from './../useRefCallback';

export type Dimensions = {
    height: number;
    width: number;
}

function getDimensions(el: HTMLElement | null): Dimensions {
    if (el) {
        return {
            width: el.clientWidth,
            height: el.clientHeight,
        }
    } else {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }
}

type DimensionsHandler = {
    recalc: () => void;
}
export default function useDimensions<TElement extends HTMLElement>(props:
    {
        srcEl?: TElement,
        throttleDelay?: TimeSpan,
        onChange?: (size: Dimensions) => void;

    } = {}, deps: ReadonlyArray<any> = [])
    : [Dimensions, (node: any) => void, DimensionsHandler] {
    const [setSourceRef, sourceRef, mounted] = useRefCallback(props.srcEl);

    const [dimensions, _setDimensions] = useState<Dimensions>(getDimensions(sourceRef.current));
    const setDimensions = useCallback((dim: Dimensions) => {
        _setDimensions(dim);
        props.onChange?.(dim);
    }, [props]);

    useEffect(() => {
        const setDimensionsFn = () => {
            setDimensions(getDimensions(sourceRef.current));
        };
        const handleResize = props.throttleDelay
            ? throttle(setDimensionsFn, props.throttleDelay.milliseconds)
            : setDimensionsFn;
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (mounted) {
            setDimensions(getDimensions(sourceRef.current));
        }
    }, [mounted, ...deps]);

    return [dimensions, setSourceRef, {
        recalc: () => setDimensions(getDimensions(sourceRef.current))
    }];
}