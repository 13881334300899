import { action, observable } from 'mobx';
import { Entity, Course } from '../..';

export default class CoursePlan extends Entity {
    constructor(plan?: Partial<CoursePlan>) {
        super();
        if (plan) this.update(plan);
    }

    @observable name: string;
    description: string;
    @observable disableTimeLimits: boolean;
    isPopular: boolean;
    course: Course;

    @action
    update(changes: Partial<CoursePlan>) {
        super.update(changes);
    }

    static fromJson(json: any) {
        return new CoursePlan({
            ...json,
            course: json.course ? Course.fromJson(json.course) : undefined,
        });
    }
}