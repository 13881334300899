import { Entity } from '..';
import { action } from 'mobx';

export default class Package extends Entity {
    constructor(item?: Partial<Package>) {
        super(item);
        if (item) this.update(item);
    }
    name: string;
    shortDescription: string;
    duration: number;
    createdDate: Date;
    updatedDate: Date;
    softDeleted: boolean;

    static fromJson(json: any): Package {
        return new Package({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
        });
    }

    @action
    update(item: Partial<Package>) {
        super.update(item);
    }
}