import { observable, action } from 'mobx';
import { Entity, AccelFile, SitePage } from '..';
import { isDev } from '../../enums';
import SiteDomain from './domain/site-domain';
import SiteCounters from './site-counters';
import SiteFileConfirmation from './FileConfirmation/SiteFileConfirmation';
import { isArraysEqual } from '../../utils';
import { ISerializable } from '../Entity';

export default class Site extends Entity implements ISerializable {
    constructor(site?: Partial<Site>) {
        super(site);
        if (site) this.update(site);
    }

    @observable name: string;
    @observable shortDescription: string;
    @observable subdomain: string;
    @observable enabled: boolean;

    @observable headerIntegrationCode: string;
    @observable bodyIntegrationCode: string;

    @observable createdDate: Date;
    @observable updatedDate: Date;

    @observable favicon: AccelFile | null;
    @observable logo: AccelFile | null;
    @observable siteDomain: SiteDomain | null;
    @observable counters: SiteCounters | null;

    @observable formTerms: string;
    @observable formTermsEnabled: boolean;
    @observable formTermsDefaultAccepted: boolean;

    @observable checkoutFormTerms: string;
    @observable checkoutFormTermsEnabled: boolean;
    @observable checkoutFormTermsDefaultAccepted: boolean;

    @observable confirmations: SiteFileConfirmation[];
    @observable robotsTxt: string;

    @observable page404: SitePage | null;
    @observable pagesCount: number;

    getSiteDomain(defaultSiteDomain: string, protocol?: 'http' | 'https') {
        if (!protocol)
            protocol = isDev ? 'http' : 'https';
        if (this.siteDomain != null)
            return `${protocol}://${this.siteDomain.name}`;
        return this.getSiteTechDomain(defaultSiteDomain, protocol);
    }

    getSiteTechDomain(defaultSiteDomain: string, protocol?: 'http' | 'https') {
        if (!protocol)
            protocol = isDev ? 'http' : 'https';
        return `${protocol}://${this.subdomain}${defaultSiteDomain}`;
    }

    @action
    update(site: Partial<Site>, allowUndefined = false) {
        super.update(site, allowUndefined);
    }

    clone(changes?: Partial<Site>) {
        return new Site({
            ...this,
            confirmations: this.confirmations.map(x => x.clone()),
            ...changes
        });
    }

    hasChanges(site: Site) {
        return this.name != site.name
            || this.shortDescription != site.shortDescription
            || this.subdomain != site.subdomain
            || this.siteDomain?.id != site.siteDomain?.id
            || this.favicon?.id != site.favicon?.id
            || this.logo?.id != site.logo?.id
            || this.formTerms != site.formTerms
            || this.formTermsEnabled != site.formTermsEnabled
            || this.formTermsDefaultAccepted != site.formTermsDefaultAccepted
            || this.checkoutFormTerms != site.checkoutFormTerms
            || this.checkoutFormTermsEnabled != site.checkoutFormTermsEnabled
            || this.checkoutFormTermsDefaultAccepted != site.checkoutFormTermsDefaultAccepted
            || !isArraysEqual(this.confirmations, site.confirmations, (a, b) => !a.hasChanges(b))
            || this.bodyIntegrationCode != site.bodyIntegrationCode
            || this.enabled != site.enabled
            || this.headerIntegrationCode != site.headerIntegrationCode
            || this.robotsTxt != site.robotsTxt
            || this.page404?.id != site.page404?.id;
    }

    toJson() {
        throw new Error('not implemented');
    }

    static fromJson(json: any): Site {
        return new Site({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,

            favicon: json.favicon ? AccelFile.fromJson(json.favicon) : null,
            logo: json.logo ? AccelFile.fromJson(json.logo) : null,
            siteDomain: json.siteDomain ? SiteDomain.fromJson(json.siteDomain) : null,

            counters: json.counters !== undefined
                ? SiteCounters.fromJson(json.counters)
                : undefined,
            confirmations: json.confirmations ? json.confirmations.map(SiteFileConfirmation.fromJson) : [],
            page404: json.page404 ? SitePage.fromJson(json.page404) : null
        });
    }
}

