import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { Site, SiteComponentType, SitePage } from '../..';
import { Designable } from '../../Entity';

export default class SiteComponent extends Designable {
    constructor(item?: Partial<SiteComponent>) {
        super(item);
        if (item) this.update(item);
    }

    @observable name: string;
    @observable type: SiteComponentType;
    @observable createdDate: Moment;
    @observable updatedDate: Moment;
    @observable headerPages: SitePage[];
    @observable footerPages: SitePage[];
    @observable site: Site;

    static fromJson(json: any): SiteComponent {
        const item = new SiteComponent({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            site: json.site ? Site.fromJson(json.site) : undefined,
            headerPages: json.headerPages ? json.headerPages.map(SitePage.fromJson) : [],
            footerPages: json.footerPages ? json.footerPages.map(SitePage.fromJson) : []
        });
        return item;
    }

    @action
    update(changes: Partial<SiteComponent>) {
        super.update(changes);
    }

    @computed get pages() {
        switch (this.type) {
            case SiteComponentType.Header:
                return this.headerPages ?? [];
            case SiteComponentType.Footer:
                return this.footerPages ?? [];
            default:
                return [];
        }
    }

    @computed get pageIds() {
        return this.pages.map(i => i.id);
    }

    @action
    updatePages(pages: SitePage[]) {
        switch (this.type) {
            case SiteComponentType.Header:
                this.headerPages = [...pages];
                break;
            case SiteComponentType.Footer:
                this.footerPages = [...pages];
                break;
        }
    }
}