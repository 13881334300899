import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { observer } from 'mobx-react';
import { AccelFile } from '../../models';
import styles from './FileList.module.scss';
import FileGridItem from './FileGridItem/FileGridItem';
import FileListItem from './FileListItem/FileListItem';
import { Context } from '../AccelProvider/AccelProvider';
import type { UploadFileArgs } from '../../api/methods/file';
import FileStore from './FileStore';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Modal, notification } from 'antd';
import { useDrawer } from '..';
import { FileProvider, Localization } from '../../utils';
import { FileIconColor } from '../FileIcon/FileIcon';

export declare type FileListViewType = 'list' | 'grid';
export declare type FileListSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type FilesProps = {
    files: AccelFile[];
    /**
     * Can edit filename
     */
    editable?: boolean;
    downloadable?: boolean;
    draggable?: boolean;
    uploadArgs?: UploadFileArgs;
    view?: FileListViewType;
    size?: FileListSize;
    deletable?: boolean;
    replacable?: boolean;
    dateCreatedVisible?: boolean;
    descriptionVisible?: boolean;
    beforeUpload?: (file: AccelFile) => Promise<void | boolean>;
    beforeRemove?: (file: AccelFile) => Promise<void | boolean>;
    onRemoved?: (file: AccelFile) => void;
    onAborted?: (file: AccelFile) => void;
    onUploaded?: (file: AccelFile) => void;
    onDownload?: (file: AccelFile) => void;
    onDrop?: (srcIndex: number, dstIndex: number) => void;
    removeFromServer?: boolean;
    acceptFiles?: string;
    fileIconColor?: FileIconColor;
}

export type FileProps = {
    loc: Localization;
    fileProvider: FileProvider;
    file: AccelFile;
    index: number;
    size: FileListSize;
    editable: boolean;
    deletable: boolean;
    downloadable: boolean;
    draggable: boolean;
    uploadArgs: UploadFileArgs;
    replaceable: boolean;
    descriptionVisible: boolean;
    dateCreatedVisible: boolean;
    fileIconColor: FileIconColor;
    beforeUpload?: (file: AccelFile) => Promise<void | boolean>;
    onUploaded?: (file: AccelFile) => void;
    onAborted?: (file: AccelFile) => void;
    onRemove?: (file: AccelFile) => void;
    onChange?: (file: AccelFile, changes: Partial<AccelFile>) => Promise<boolean>;
    onReplace?: (file: AccelFile) => void;
    onDownload?: (file: AccelFile) => void;
    onPreview: (file: AccelFile) => void;
}

const Files: React.FC<FilesProps> = (props) => {
    const ctx = useContext(Context);
    const [store] = useState(new FileStore(ctx.api));

    const fileReplaceInput = useRef<any>(null);
    const [fileToReplace, setFileToReplace] = useState<AccelFile | null>(null);

    const previewModal = useDrawer<AccelFile>();

    const remove = useCallback(async (file: AccelFile) => {
        if (props.beforeRemove != null)
            if (await props.beforeRemove(file) == false)
                return;
        if (props.removeFromServer == true) {
            const res = await store.remove(file.id);
            if (res.success) props.onRemoved?.(file);
        } else {
            props.onRemoved?.(file);
        }
    }, []);

    const save = useCallback(async (file: AccelFile, changes: Partial<AccelFile>) => {
        const res = await store.save({ ...changes, id: file.id });
        file.update(changes);
        return res.success;
    }, []);

    const replaceFile = useCallback((nativeFile: File) => {
        if (!fileToReplace) return;

        const file = AccelFile.fromFile(nativeFile);
        if (file.extension != fileToReplace.extension) {
            setFileToReplace(null);
            notification.error({
                message: ctx.loc.word('ErrorFileReplaceExtensionError', { default: 'The file extensions to replace must match the extension of the original file' }),
                duration: 3
            })
            return;
        }

        fileToReplace.replaceFile(nativeFile);
        setFileToReplace(null);
    }, [fileToReplace]);

    const previewFile = useCallback((file: AccelFile) => {
        if (file.isImage) {
            previewModal.open(file);
            return;
        }
        store.preview(file);
    }, []);

    const downloadFile = useCallback(async (file: AccelFile) => {
        await store.download(file);
        props.onDownload?.(file);
    }, []);

    const fileProps = useMemo<Omit<FileProps, "file" | "index">>(() => ({
        fileProvider: ctx.fileProvider,
        loc: ctx.loc,
        editable: props.editable ?? true,
        downloadable: props.downloadable ?? true,
        uploadArgs: props.uploadArgs ?? {},
        size: props.size!,
        deletable: props.deletable ?? true,
        replaceable: props.replacable ?? false,
        dateCreatedVisible: props.dateCreatedVisible ?? false,
        descriptionVisible: props.descriptionVisible ?? false,
        draggable: props.draggable ?? false,
        fileIconColor: props.fileIconColor ?? '#ffffff',
        onRemove: file => remove(file),
        onAborted: props.onAborted,
        onUploaded: props.onUploaded,
        onChange: (file, changes) => save(file, changes),
        beforeUpload: props.beforeUpload,
        onDownload: file => downloadFile(file),
        onReplace: file => {
            setFileToReplace(file);

            // dirty hack (apply file ext for file input accept)
            setTimeout(() => {
                fileReplaceInput.current?.click();
            }, 50);
        },
        onPreview: file => previewFile(file),
    }), [props]);

    return <>
        <div className={styles.fl} data-view={props.view}>
            {props.view == 'grid'
                ? props.files.map((x, i) => <FileGridItem key={x.key}
                    file={x}
                    index={i}
                    {...fileProps}
                />)
                : <>
                    {props.draggable
                        ? <DragDropContext
                            onDragEnd={result => {
                                if (!result.destination) return;
                                props.onDrop?.(result.source.index, result.destination.index);
                            }}>
                            <Droppable droppableId='fl_droppable' isDropDisabled={!props.draggable}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {props.files.map((x, i) => <FileListItem key={x.key}
                                            file={x}
                                            index={i}
                                            {...fileProps}
                                        />)}
                                        {provided.placeholder}
                                    </div>)}
                            </Droppable>
                        </DragDropContext>
                        : props.files.map((x, i) => <FileListItem key={x.key}
                            file={x}
                            index={i}
                            {...fileProps}
                        />)}
                </>}
        </div>

        {props.replacable && <input ref={fileReplaceInput}
            type='file'
            accept={fileToReplace?.extension ?? props.acceptFiles}
            className='d-none'
            onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!e.target.files || e.target.files.length == 0) return;
                replaceFile(e.target.files[0]!);
            }} />}

        {previewModal.visible && <Modal
            className={styles.fl_preview_modal}
            open={previewModal.visible}
            width={800}
            onOk={() => previewModal.close()}
            onCancel={() => previewModal.close()}
            footer={null}>
            <img className='w-100' src={ctx.fileProvider.getUrl(previewModal.data!) ?? undefined} />
        </Modal>}
    </>;
}

Files.defaultProps = {
    uploadArgs: {},
    files: [],
    downloadable: true,
    editable: true,
    draggable: false,
    view: 'list',
    size: 'md',
    removeFromServer: true,
    replacable: false,
    deletable: true,
    descriptionVisible: false,
    dateCreatedVisible: false
};

export default observer(Files);
