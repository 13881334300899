import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { BillingPlan, Entity } from '../..';
import { PromoCodeType } from '../../Product/PromoCode/PromoCode';

export default class BillingPromoCode extends Entity {
    constructor(code?: Partial<BillingPromoCode>) {
        super(code);
        // TODO: makeObservable
        if (code) this.update(code);
    }

    //TODO predefined

    @observable name: string;
    @observable code: string;
    @observable type: PromoCodeType;
    @observable description: string;
    @observable bonusBalance?: number;
    @observable trialDaysCount?: number;
    @observable beginDate: moment.Moment | null;
    @observable endDate: moment.Moment | null;
    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;
    @observable billingPlan: BillingPlan | null;

    @computed get endless() {
        return !this.beginDate && !this.endDate;
    }

    clone() {
        return new BillingPromoCode({
            ...this
        });
    }

    static fromJson(json: any): BillingPromoCode {
        return new BillingPromoCode({
            id: json.id,
            name: json.name,
            code: json.code,
            type: json.type,
            description: json.description,
            bonusBalance: json.bonusBalance,
            trialDaysCount: json.trialDaysCount,
            beginDate: json.beginDate ? moment(json.beginDate) : null,
            endDate: json.endDate ? moment(json.endDate) : null,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            billingPlan: json.billingPlan ? BillingPlan.fromJson(json.billingPlan) : null
        });
    }

    @action
    update(t: Partial<BillingPromoCode>, allowUndefined = false) {
        super.update(t, allowUndefined);

    }
}

