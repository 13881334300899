import { QuizItemType } from '../QuizItemType';
import { CheckboxItemSettings } from './CheckboxItemSettings';
import { DateTimeItemSettings } from './DateTimeItemSettings';
import { DropdownListItemSettings } from './DropdownListItemSettings';
import { HeaderItemSettings } from './HeaderItemSettings';
import { HiddenFieldItemSettings } from './HiddenFieldItemSettings';
import { ImageItemSettings } from './ImageItemSettings';
import { IndentItemSettings } from './IndentItemSettings';
import { NameFieldItemSettings } from './NameFieldItemSettings';
import { NumberItemSettings } from './NumberItemSettings';
import { ParagraphItemSettings } from './ParagraphItemSettings';
import QuizItemSettings from './QuizItemSettings';
import { RatingItemSettings } from './RatingItemSettings';
import { SelectListItemSettings } from './SelectListItemSettings';
import { SeparatorItemSettings } from './SeparatorItemSettings';
import { SliderItemSettings } from './SliderItemSettings';
import { SocialItemSettings } from './SocialItemSettings';
import { UploadItemSettings } from './UploadItemSettings';
import { VideoItemSettings } from './VideoItemSettings';

export {
    QuizItemSettings,
    NumberItemSettings,
    DropdownListItemSettings,
    SelectListItemSettings,
    RatingItemSettings,
    UploadItemSettings,
    DateTimeItemSettings,
    SliderItemSettings,
    HiddenFieldItemSettings,
    NameFieldItemSettings,
    CheckboxItemSettings,
    HeaderItemSettings,
    ParagraphItemSettings,
    SeparatorItemSettings,
    IndentItemSettings,
    ImageItemSettings,
    VideoItemSettings,
    SocialItemSettings,
}

export default class QuizItemSettingsParser {
    static parse(type: QuizItemType, settingsJson?: string): QuizItemSettings {
        const s = settingsJson ? JSON.parse(settingsJson) : undefined;
        switch (type) {
            case QuizItemType.Number:
                return NumberItemSettings.fromJson(s);
            case QuizItemType.DropdownList:
                return DropdownListItemSettings.fromJson(s);
            case QuizItemType.MultiSelect:
            case QuizItemType.MonoSelect:
                return SelectListItemSettings.fromJson(s);
            case QuizItemType.UploadFile:
                return UploadItemSettings.fromJson(s);
            case QuizItemType.DateTime:
                return DateTimeItemSettings.fromJson(s);
            case QuizItemType.Slider:
                return SliderItemSettings.fromJson(s);
            case QuizItemType.HiddenField:
                return HiddenFieldItemSettings.fromJson(s);
            case QuizItemType.NameField:
                return NameFieldItemSettings.fromJson(s);
            case QuizItemType.Checkbox:
                return CheckboxItemSettings.fromJson(s);
            case QuizItemType.Header:
                return HeaderItemSettings.fromJson(s);
            case QuizItemType.Paragraph:
                return ParagraphItemSettings.fromJson(s);
            case QuizItemType.Separator:
                return SeparatorItemSettings.fromJson(s);
            case QuizItemType.Indent:
                return IndentItemSettings.fromJson(s);
            case QuizItemType.Image:
                return ImageItemSettings.fromJson(s);
            case QuizItemType.Video:
                return VideoItemSettings.fromJson(s);
            case QuizItemType.Social:
                return SocialItemSettings.fromJson(s);
            default:
                return QuizItemSettings.fromJson(s);
        }
    }
}