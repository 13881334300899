import { action, observable } from 'mobx';
import { Contact, CourseItemTaskAnswerComment, Entity, AccelFile, StudentCourseItemTaskAnswerField } from '../../../..';
import { isEmpty } from '../../../../../utils';
import { type Moment } from 'moment';
import moment from 'moment';

export enum CourseItemTaskAnswerStatus {
    Default = 'default',
    Pending = 'pending',
    Incorrect = 'incorrect',
    Correct = 'correct',
    Incomplete = 'incomplete',
    Editing = 'editing'
}

export default class CourseItemTaskAnswer extends Entity {
    constructor(answer?: Partial<CourseItemTaskAnswer>) {
        super(answer);
        if (answer) this.update(answer);
    }

    @observable createdDate: Moment;
    @observable updatedDate: Moment;
    @observable text: string;
    @observable contact: Contact;
    @observable status: CourseItemTaskAnswerStatus;
    @observable comments: CourseItemTaskAnswerComment[];
    @observable commentCount: number;
    @observable files: AccelFile[];
    @observable fields: StudentCourseItemTaskAnswerField[];

    static fromJson(json: any): CourseItemTaskAnswer {
        return new CourseItemTaskAnswer({
            ...json,
            text: !isEmpty(json.text) ? json.text : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
            comments: json.comments ? json.comments.map(CourseItemTaskAnswerComment.fromJson) : undefined,
            files: json.files ? json.files.map(AccelFile.fromJson) : undefined,
            fields: json.studentAnswerFields ? json.studentAnswerFields.map(StudentCourseItemTaskAnswerField.fromJson) : undefined
        });
    }

    getFieldValue(fId: string): StudentCourseItemTaskAnswerField | null {
        const val = this.fields.find(x => x.field.id == fId);
        return val ?? null;
    }

    @action setFieldValue(f: StudentCourseItemTaskAnswerField) {
        const val = this.getFieldValue(f.field.id);
        if (val) {
            val.update(f, true);
        } else {
            this.fields.push(f);
        }
    }

    @action
    update(task: Partial<CourseItemTaskAnswer>) {
        super.update(task);
    }
}