import { BaseFilter, FileManagerItem, FileManagerItemType, FileType } from '..';
import { FileManagerItemTarget } from '../../api/methods/file-manager/types/FileSystemTarget';
import { FileManagerSection } from './file-manager-item';

export default class FileManagerItemFilter extends BaseFilter<FileManagerItem> {
	constructor(filter?: Partial<FileManagerItemFilter>, fsTarget?: FileManagerItemTarget) {
		super();
		if (filter) this.update(filter);
		if (fsTarget) this.update({
			...fsTarget
		});
	}

	id: string;
	fileId: string;
	parentId: string | null;
	search: string;
	fileType?: FileType;
	fileTypes?: FileType[];
	siteId?: string;
	itemType: FileManagerItemType;
	sectionType: FileManagerSection;

	ignoreParentId: boolean;

	update(changes: Partial<FileManagerItemFilter>) {
		super.update(changes);
	}
}
