import HubCaller from './HubCaller';
import HubUnsubscription from './HubUnsubscription';

export default abstract class HubSubscription<T = any> {
    public method: string;
    public connection: signalR.HubConnection;
    public callback: (payload: T, caller: HubCaller) => void;
    public unsubscription: HubUnsubscription;
    protected subscribed: boolean = false;
    protected processPayload: (json: any, caller: HubCaller | null) => T = (json: any, caller: HubCaller | null) => json;

    public subscribe(connection: signalR.HubConnection, callback: (payload: T, caller: HubCaller | null) => void) {
        if (this.subscribed)
            throw new Error('Already subscribed');
        this.connection = connection;
        this.callback = (json: any) => {
            const caller = json.caller ? HubCaller.fromJson(json.caller) : null;
            try {
                const payload = this.processPayload(json.payload, caller);
                callback(payload, caller);
            } catch (e) {
                console.error(e);
            }
        };
        this.connection.on(this.method, this.callback);
        this.unsubscription = new HubUnsubscription(this);
        this.subscribed = true;
    }

    public unsubscribe() {
        if (this.unsubscription == null)
            throw new Error('You must subscribe first');
        this.unsubscription.dispose();
    }

    public _call(json: any) {
        if (!this.subscribed)
            throw new Error('You must subscribe first');
        //@ts-ignore
        this.callback(json);
    }
}
