import { action } from 'mobx';
import { Contact, Entity } from '..';

export enum HistoryEventType {
    StudentLicenseCreated = 'studentLicenseCreated',
    StudentLicenseUpdated = 'studentLicenseUpdated',
    StudentLicenseDeleted = 'studentLicenseDeleted',

    StudentLicenseSuspensionCreated = 'studentLicenseSuspensionCreated',
    StudentLicenseSuspensionDeleted = 'studentLicenseSuspensionDeleted',

    StudentCourseFeedbackCreated = 'studentCourseFeedbackCreated',
    StudentCourseItemStarted = 'studentCourseItemStarted',
    StudentCourseItemCompleted = 'studentCourseItemCompleted',
    StudentCourseItemTheoryCompleted = 'studentCourseItemTheoryCompleted',
    StudentCourseItemTestsCompleted = 'studentCourseItemTestsCompleted',
    StudentCourseItemTasksCompleted = 'studentCourseItemTasksCompleted',
    StudentCourseItemTaskAnswerStatusUpdated = 'studentCourseItemTaskAnswerStatusUpdated',
    StudentCourseItemRatingCreated = 'studentCourseItemRatingCreated',
    StudentCourseItemRatingUpdated = 'studentCourseItemRatingUpdated',
    StudentLibraryItemRatingCreated = 'studentLibraryItemRatingCreated',
    StudentLibraryItemRatingUpdated = 'studentLibraryItemRatingUpdated',
    StudentCourseStarted = 'studentCourseStarted',
    StudentCourseCompleted = 'studentCourseCompleted',
    StudentUpdated = 'studentUpdated',
    /**
     * Student updated with limited fields
     */
    StudentUpdatedLimited = 'studentUpdatedLimited',
    StudentLogin = 'studentLogin',
    StudentResetPassword = 'studentResetPassword',
    StudentCreated = 'studentCreated',
    StudentConfirmInvite = 'studentConfirmInvite',
    StudentConfirmEmail = 'studentConfirmEmail',
    StudentInviteCreated = 'studentInviteCreated',
    StudentRestored = 'studentRestored',
    StudentMergedSource = 'studentMergedSource',
    StudentMergedTarget = 'studentMergedTarget',
    StudentRegister = 'studentRegister',
    StudentProductSubscriptionUpdated = 'studentProductSubscriptionUpdated',

    StudentBonusTransactionCreated = 'studentBonusTransactionCreated',
    StudentBonusTransactionDeleted = 'studentBonusTransactionDeleted',

    AdminResponsibleCreated = "adminResponsibleCreated",
    AdminResponsibleUpdated = "adminResponsibleUpdated",
    AdminResponsibleDeleted = "adminResponsibleDeleted",
    AdminStepCourseOpened = "adminStepCourseOpened",
    AdminStepCourseClosed = "adminStepCourseClosed",
    AdminStepCourseCompleted = "adminStepCourseCompleted",
    AdminStepCourseUncompleted = "adminStepCourseUncompleted",
    AllAdminResponsibleUpdated = "allAdminResponsibleUpdated",


    // #region Lead 8001-9000
    ImportLeadUpdated = 'importLeadUpdated',
    ImportLeadCreated = 'importLeadCreated',
    LeadNoteCreated = 'leadNoteCreated',
    LeadToBroadcastListAdded = 'leadToBroadcastListAdded',
    LeadFromBroadcastListDeleted = 'leadFromBroadcastListDeleted',
    LeadDeleted = 'leadDeleted',
    LeadBanned = 'leadBanned',
    LeadUnbanned = 'leadUnbanned',

    LeadTagsUpdated = 'leadTagsUpdated',
    LeadGroupsUpdated = 'leadGroupsUpdated',

    LeadAddCartProduct = 'leadAddCartProduct',
    LeadDeleteCartProduct = 'leadDeleteCartProduct',
    LeadVisitCheckout = 'leadVisitCheckout',
    LeadVisitCheckoutSuccess = 'leadVisitCheckoutSuccess',
    LeadWebinarRegistration = 'leadWebinarRegistration',
    LeadWebinarVisit = 'leadWebinarVisit',

    LeadAttributeCreated = 'leadAttributeCreated',
    LeadAttributeUpdated = 'leadAttributeUpdated',
    LeadAttributeDeleted = 'leadAttributeDeleted',
    LeadAttributeRestored = 'leadAttributeRestored',

    LeadChatChannelStarted = 'leadChatChannelStarted',
    LeadChatBotStarted = 'leadChatBotStarted',
    LeadChatChannelScenarioStarted = 'leadChatChannelScenarioStarted',
    // #endregion

    AccelFileCreated = 'accelFileCreated',

    SchoolRegisterFormSubmitted = 'schoolRegisterFormSubmitted',

    CourseImageCreated = 'courseImageCreated',
    CourseImageDeleted = 'courseImageDeleted',
    CourseCreated = 'courseCreated',
    CourseUpdated = 'courseUpdated',

    CourseCategoryLinkCreated = 'courseCategoryLinkCreated',
    CourseCategoryLinkDeleted = 'courseCategoryLinkDeleted',

    CourseRequirementCreated = 'courseRequirementCreated',
    CourseRequirementDeleted = 'courseRequirementDeleted',

    ProductPackageCreated = 'productPackageCreated',
    ProductPackageUpdated = 'productPackageUpdated',
    ProductPackageDeleted = 'productPackageDeleted',
    ProductPackageCourseLinkCreated = 'productPackageCourseLinkCreated',
    ProductPackageCourseLinkDeleted = 'productPackageCourseLinkDeleted',
    ProductPackageLibraryAccessLinkCreated = 'productPackageLibraryAccessLinkCreated',
    ProductPackageLibraryAccessLinkDeleted = 'productPackageLibraryAccessLinkDeleted',

    CoursePlanCreated = 'coursePlanCreated',
    CoursePlanUpdated = 'coursePlanUpdated',
    CoursePlanDeleted = 'coursePlanDeleted',
    CoursePlanCopied = 'coursePlanCopied',

    CourseItemCreated = 'courseItemCreated',
    CourseItemUpdated = 'courseItemUpdated',
    CourseItemDeleted = 'courseItemDeleted',
    CourseItemCopied = 'courseItemCopied',

    CourseItemPlanCreated = 'courseItemPlanCreated',
    CourseItemPlanDeleted = 'courseItemPlanDeleted',

    CourseItemCommentCreated = 'courseItemCommentCreated',
    CourseItemCommentUpdated = 'courseItemCommentUpdated',
    CourseItemCommentDeleted = 'courseItemCommentDeleted',

    CourseItemTaskCreated = 'courseItemTaskCreated',
    CourseItemTaskUpdated = 'courseItemTaskUpdated',
    CourseItemTaskDeleted = 'courseItemTaskDeleted',
    CourseItemTaskCopied = 'courseItemTaskCopied',

    CourseItemTestCreated = 'courseItemTestCreated',
    CourseItemTestUpdated = 'courseItemTestUpdated',
    CourseItemTestDeleted = 'courseItemTestDeleted',
    CourseItemTestCopied = 'courseItemTestCopied',

    CourseItemTestQuestionCreated = 'courseItemTestQuestionCreated',
    CourseItemTestQuestionUpdated = 'courseItemTestQuestionUpdated',
    CourseItemTestQuestionDeleted = 'courseItemTestQuestionDeleted',
    CourseItemTestQuestionCopied = 'courseItemTestQuestionCopied',

    CourseItemTestAnswerCreated = 'courseItemTestAnswerCreated',
    CourseItemTestAnswerUpdated = 'courseItemTestAnswerUpdated',
    CourseItemTestAnswerDeleted = 'courseItemTestAnswerDeleted',

    CourseItemTaskFileCreated = 'courseItemTaskFileCreated',
    CourseItemTaskFileUpdated = 'courseItemTaskFileUpdated',
    CourseItemTaskFileDeleted = 'courseItemTaskFileDeleted',

    CourseItemTestFileCreated = 'courseItemTestFileCreated',
    CourseItemTestFileUpdated = 'courseItemTestFileUpdated',
    CourseItemTestFileDeleted = 'courseItemTestFileDeleted',

    CourseItemFormSubmitted = 'courseItemFormSubmitted',

    ForumThreadCreated = 'forumThreadCreated',
    ForumThreadUpdated = 'forumThreadUpdated',
    ForumThreadDeleted = 'forumThreadDeleted',
    ForumThreadRestored = 'forumThreadRestored',

    ForumThreadModeratorCreated = 'forumThreadModeratorCreated',
    ForumThreadModeratorDeleted = 'forumThreadModeratorDeleted',

    EmailSent = 'emailSent',
    EmailUnsubscribed = 'emailUnsubscribed',
    EmailPermanentFail = 'emailPermanentFail',
    EmailComplained = 'emailComplained',
    EmailClicked = 'emailClicked',
    EmailOpened = 'emailOpened',
    EmailDelivered = 'emailDelivered',
    EmailSuppressed = 'emailSuppressed',
    EmailSubscribed = 'emailSubscribed',

    EmailBroadcastCreated = 'broadcastCreated',
    EmailBroadcastUpdated = 'broadcastUpdated',
    EmailBroadcastDeleted = 'broadcastDeleted',
    EmailBroadcastStatusUpdated = 'broadcastStatusUpdated',

    SchoolCallSessionStarted = 'schoolCallSessionStarted',
    SchoolCallSessionCompleted = 'schoolCallSessionCompleted',

    AdminTaskCreated = 'adminTaskCreated',
    AdminTaskUpdated = 'adminTaskUpdated',
    AdminTaskDeleted = 'adminTaskDeleted',
    AdminTaskCompleted = 'adminTaskCompleted',
    AdminTaskResetStatus = 'adminTaskResetStatus',
    AdminTaskReportUpdated = 'adminTaskReportUpdated',

    LibraryItemViewed = 'libraryItemViewed',
    LibraryItemFormSubmitted = 'libraryItemFormSubmitted',


    SiteCreated = 'siteCreated',
    SiteUpdated = 'siteUpdated',
    SiteDeleted = 'siteDeleted',

    SitePageCreated = 'sitePageCreated',
    SitePageUpdated = 'sitePageUpdated',
    SitePageDeleted = 'sitePageDeleted',

    SiteDomainCreated = 'siteDomainCreated',
    SiteDomainUpdated = 'siteDomainUpdated',
    SiteDomainDeleted = 'siteDomainDeleted',

    SiteMenuItemCreated = 'siteMenuItemCreated',
    SiteMenuItemUpdated = 'siteMenuItemUpdated',
    SiteMenuItemDeleted = 'siteMenuItemDeleted',
    SiteViewed = 'siteViewed',
    SiteFormSubmitted = 'siteFormSubmitted',

    //region Purchase Order
    PurchaseOrderCreated = 'purchaseOrderCreated',
    PurchaseOrderUpdated = 'purchaseOrderUpdated',
    PurchaseOrderDeleted = 'purchaseOrderDeleted',
    PurchaseOrderCompleted = 'purchaseOrderCompleted',
    PurchaseOrderUnCompleted = 'purchaseOrderUnCompleted',
    PurchaseOrderCanceled = 'purchaseOrderCanceled',
    PurchaseOrderPaid = 'purchaseOrderPaid',
    PurchaseOrderRestored = 'purchaseOrderRestored',

    PurchaseOrderPaymentCreated = 'purchaseOrderPaymentCreated',
    PurchaseOrderPaymentUpdated = 'purchaseOrderPaymentUpdated',
    PurchaseOrderPaymentDeleted = 'purchaseOrderPaymentDeleted',
    PurchaseOrderPaymentRefunded = 'purchaseOrderPaymentRefunded',

    PurchaseOrderContentCreated = 'purchaseOrderContentCreated',
    PurchaseOrderContentUpdated = 'purchaseOrderContentUpdated',
    PurchaseOrderContentDeleted = 'purchaseOrderContentDeleted',
    //

    WidgetViewed = 'widgetViewed',
    WidgetFormSubmitted = 'widgetFormSubmitted',

    WebhookSent = 'webhookSent',


    //#region Quiz
    QuizStarted = 'quizStarted',
    QuizSubmitted = 'quizSubmitted',
    QuizViewed = 'quizViewed',
    //#endregion

    //#region PipelineDeal 
    PipelineDealCreated = 'pipelineDealCreated',
    PipelineDealUpdated = 'pipelineDealUpdated',
    PipelineDealDeleted = 'pipelineDealDeleted',
    PipelineDealTaskAdded = 'pipelineDealTaskAdded',
    PipelineDealTaskDeleted = 'pipelineDealTaskDeleted',
    PipelineDealOrderAdded = 'pipelineDealOrderAdded',
    PipelineDealOrderDeleted = 'pipelineDealOrderDeleted',
    PipelineDealStageChanged = 'pipelineDealStageChanged',
    PipelineDealArchived = 'pipelineDealArchived',
    PipelineDealRestored = 'pipelineDealRestored',
    PipelineDealCompleted = 'pipelineDealCompleted',
    //#endregion

    //#region Glossary
    GlossaryCreated = "glossaryCreated",
    GlossaryUpdated = "glossaryUpdated",
    GlossaryDeleted = "glossaryDeleted",
    GlossaryRestored = "glossaryRestored",
    GlossaryLinkCreated = "glossaryLinkCreated",
    GlossaryLinkDeleted = "glossaryLinkDeleted",
    //#endregion

    //#region Application 29001-30000
    TelegramGroupUserInvited = 'telegramGroupUserInvited',
    TelegramGroupUserBanned = 'telegramGroupUserBanned',
    TelegramGroupUserApproved = 'telegramGroupUserApproved',
    TelegramGroupUserRejected = 'telegramGroupUserRejected',
    TelegramGroupUserDeleted = 'telegramGroupUserDeleted',
    TelegramGroupUserLeft = 'telegramGroupUserLeft',
    TelegramGroupUserRequested = 'telegramGroupUserRequested',
    //#endregion

    //#region Partnership 30001-31000
    PartnershipTransactionRequestCreated = 'partnershipTransactionRequestCreated',
    PartnershipTransactionRequestUpdated = 'partnershipTransactionRequestUpdated',
    PartnershipTransactionRequestDeleted = 'partnershipTransactionRequestDeleted',

    PartnershipTransactionCreated = 'partnershipTransactionCreated',
    PartnershipTransactionDeleted = 'partnershipTransactionDeleted',
    //#endregion

    //#region Gamification 32001-33000
    GamificationTransactionCreated = 'gamificationTransactionCreated',
    //#endregion
}

export enum HistoryEventGlobalType {
    System = 'system',
    Study = 'study',
    ContentEdit = 'contentEdit',
    CRM = 'crm',
    Forum = 'forum',
    Email = 'email',
    Library = 'library',
    SiteContent = 'siteContent',
    Site = 'site',
    Webinar = 'webinar',
    Product = 'product',
    Application = 'application',
    Partnership = 'partnership',
    Call = 'call',
    Cert = 'cert',
    Gamification = 'gamification',
}

export enum HistoryEventCreatorType {
    User = 0,
    System = 1,
    Scenario = 2,
    Support = 3
}

export enum HistoryEventCategory {
    Main = 'main',
    Site = 'site',
    Study = 'study',
    Crm = 'crm'
}

export class HistoryEventPayloadFactory {
    public static parse(type: HistoryEventType, json: string) {
        const payload = json ? JSON.parse(json) : undefined;
        switch (type) {
            case HistoryEventType.StudentMergedSource:
            case HistoryEventType.StudentMergedTarget:
                return {
                    contacts: payload?.contacts
                        ? payload.contacts.map((x: any) => new Contact({ ...x }))
                        : [],
                    results: payload?.results
                };
            default:
                return payload;
        }
    }
}

export default class HistoryEvent<TAddData = any> extends Entity {
    constructor(e?: Partial<HistoryEvent>) {
        super(e);
        if (e) this.update(e);
    }

    createdDate: Date;
    type: HistoryEventType;
    globalType: HistoryEventGlobalType;
    additionalData: TAddData;

    @action
    update(entity: Partial<HistoryEvent>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    static fromJson(json: any): HistoryEvent {
        return new HistoryEvent({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            additionalData: HistoryEventPayloadFactory.parse(json.type, json.additionalData)
        });
    }
}