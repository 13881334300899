import { BaseFilter } from '../../';
import CoursePlan from './course-plan';

export default class CoursePlanFilter extends BaseFilter<CoursePlan> {
    constructor(filter?: Partial<CoursePlanFilter>) {
        super();
        if (filter) this.update(filter);
    }

    courseId?: string;
    courseIds?: string[];
    productPackageId?: string;
    isPopular: boolean;

    update(changes: Partial<CoursePlanFilter>) {
        super.update(changes);
    }
}