import React, { useMemo, useState } from 'react';
import Linkify from 'react-linkify';
import LinkifyIt from 'linkify-it';
import { combineClasses } from '../../utils';

type LinkifyTextProps = {
    text?: string;
    placeholder?: string;
    className?: string;
    linkClassName?: string;
    wrap?: boolean;
}
const LinkifyText: React.FC<LinkifyTextProps> = ({ text, placeholder, wrap, className, linkClassName }) => {
    const [linkify] = useState(new LinkifyIt());

    const content = useMemo(() => {
        return !text
        ? <span className='color-secondary'>{placeholder}</span>
        : <Linkify
            matchDecorator={(text) => linkify.match(text)}
            componentDecorator={(href, text, key) => <a key={key} href={href} target='_blank' className={linkClassName}>{text}</a>}>
            {text}
        </Linkify>
    }, [text, placeholder]);

    if (wrap === true)
        return <div className={combineClasses('pre-wrap', className)}>{content}</div>;
    return <>{content}</>;
}
LinkifyText.defaultProps = {
    wrap: true
}
export default LinkifyText;