import { TimeSpan } from '../../../utils';
import HubRequest from './HubRequest';
import HubResponse from './HubResponse';
export default abstract class HubRequestWithResponse<T> extends HubRequest {
    protected abstract processPayload: (json: any) => T;

    public async invoke(connection: signalR.HubConnection): Promise<HubResponse<T>> {
        const response = await super.invoke(connection);
        return new HubResponse(response, this.processPayload);
    }
}

export abstract class HubRequestWithResponseMock<T> extends HubRequest {
    protected payload: any = null;
    protected delay: TimeSpan = TimeSpan.fromMilliseconds(100);
    protected abstract processPayload: (json: any) => T;

    public async invoke(connection: signalR.HubConnection): Promise<HubResponse<T>> {
        return new Promise((resolve) => {
            setTimeout(() => {
                const response = new HubResponse<T>({
                    body: this.payload,
                    errrors: []
                }, this.processPayload);
                return resolve(response);
            }, this.delay.milliseconds);
        });
    }
}