import React from 'react';
import Duration from '../../../Duration/Duration';
import { TimeSpan } from '../../../../utils';
import SpaceSeparator from '../../../SpaceSeparator/SpaceSeparator';

export type AudioWaveformLevel = number;

export type AudioWaveformProps = {
    currentSec: number;
    durationInSec: number;
    className?: string;
}
export const AudioPlayerDuration: React.FC<AudioWaveformProps> = ({ currentSec, durationInSec, className }) => {
    return <SpaceSeparator separator='slash' className={className}>
        {currentSec > 0 && <Duration duration={TimeSpan.fromSeconds(currentSec)} includeSeconds />}
        <Duration duration={TimeSpan.fromSeconds(durationInSec)} includeSeconds />
    </SpaceSeparator>;
};
