import React, { useMemo } from "react";
import {
    ReloadOutlined
} from '@ant-design/icons';
import { AccelFile } from '../../../../models';
import { FileListSize, FileListViewType } from '../../Files';
import { FileUploadStatus } from '../../../../enums';
import { combineClasses, FileProvider, Localization } from '../../../../utils';
import { Image, FileIcon } from '../../..';
import styles from './FilePreview.module.scss';
import FileProgress from '../FileProgress/FileProgress';
import { FileIconColor } from '../../../FileIcon/FileIcon';

type Props = {
    view: FileListViewType;
    file: AccelFile;
    size: FileListSize;
    progress: number;
    uploadStatus: FileUploadStatus;
    loc: Localization;
    fileProvider: FileProvider;
    onPreview: () => void;
    onRetryUpload: () => void;
    className?: string;
    fileIconColor: FileIconColor;
}
const FilePreview: React.FC<Props> = ({ view, file, size, progress, uploadStatus, loc, fileProvider, className, fileIconColor, onPreview, onRetryUpload }) => {

    const fileIconSize = useMemo(() => {
        if (view == 'grid') return size;
        switch (size) {
            case 'xs': return 'xs';
            case 'sm': return 'sm';
            case 'md': return 'sm';
            case 'lg': return 'md';
            case 'xl': return 'lg';
            default: return size;
        }
    }, [view, size]);

    return <div className={combineClasses(styles.fl_item__preview, 'flex flex-center relative w-100 c-pointer', className)}
        data-upload-status={uploadStatus}
        data-size={size}
        onClick={() => uploadStatus == FileUploadStatus.Uploaded && onPreview()}
        title={loc.word('File.clickToPreview', { default: 'Click to preview' })}>
        {file.isImage
            ? <Image fit='cover' width='100%' height='100%' file={file} usePreview resize={{ w: 100 }}></Image>
            : <FileIcon filename={file.extension} size={fileIconSize} bgColor={fileIconColor} />}

        <div className={combineClasses(styles.fl_item__preview_background, 'absolute t-0 l-0 r-0 b-0 flex flex-center fs-20')}>
            {uploadStatus == FileUploadStatus.Uploading && <FileProgress view={view} file={file} progress={progress} size={size} />}

            {uploadStatus == FileUploadStatus.Error
                && <div title={loc.word('File.retryUploadMsg', { default: 'Retry upload' })} onClick={() => onRetryUpload()}>
                    <ReloadOutlined />
                </div>}
        </div>
    </div>;
}

export default FilePreview;