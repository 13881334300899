import React, { ReactNode } from "react";
import styles from './Rate.module.scss';

type RateStepProps = {
    text: ReactNode;
    value: number;
    selected: boolean;
    breakLine?: boolean;
    onClick: (value: number) => void;
}

const RateStep: React.FC<RateStepProps> = ({ text, value, selected, breakLine, onClick }) => {
    return <>
        <div className={styles.rate_step}
            data-rating={value}
            data-selected={selected}
            onClick={() => onClick(value)}>
            {text}
        </div>
        {breakLine && <div className={styles.break}></div>}
    </>;
}
export default RateStep;