import { AccelFile, Entity, CourseItemTaskField } from '../../../';
import { action, computed, observable } from 'mobx';
import { isEmpty } from '../../../../utils';

export default class CourseItemTask extends Entity {
    constructor(task?: Partial<CourseItemTask>) {
        super(task);
        if (task) this.update(task);
    }
    @observable question: string;
    @observable description: string;
    @observable correctAnswer: string;
    @observable position: number;
    @observable files: AccelFile[];
    @observable disableVerification: boolean;
    @observable fields: CourseItemTaskField[];

    @computed get hasDescription() {
        return !isEmpty(this.description);
    }

    @computed get hasQuestion() {
        return !isEmpty(this.question);
    }

    static fromJson(json: any): CourseItemTask {
        return new CourseItemTask({
            ...json,
            files: json.files ? json.files.map(AccelFile.fromJson) : undefined,
            fields: json.fields ? json.fields.map(CourseItemTaskField.fromJson) : undefined
        });
    }

    @action
    update(task: Partial<CourseItemTask>) {
        super.update(task);
    }
}