import React, { useContext, useMemo } from "react";
import { TimeMeasure } from "../../models";
import { LocStringCase } from '../../utils/localization/localization';
import { Context } from '../AccelProvider/AccelProvider';

export type TimeLocProps = {
    measure: TimeMeasure;
    value: number;
    renderValue?: (value: number) => React.ReactNode;
    short?: boolean;
    case?: LocStringCase;
    className?: string;
}
const TimeLoc: React.FC<TimeLocProps> = ({ measure, value, renderValue, short, case: _case, className }) => {
    const { loc } = useContext(Context);

    const timeLoc = useMemo(() => {
        let pluralKeyPrefix = `TimeMeasure.${measure}`;
        if (short == true) {
            pluralKeyPrefix += '.short';
            return loc.word(pluralKeyPrefix, { case: _case, default: measure });
        }
        pluralKeyPrefix += `.plural`;
        return loc.wordPlural(value,
            { one: `${pluralKeyPrefix}.one`, two: `${pluralKeyPrefix}.two`, other: `${pluralKeyPrefix}.other` },
            { case: _case, default: { one: measure, two: measure, other: measure } });
    }, [measure, value]);

    return <span className={className}>
        {renderValue ? renderValue(value) : value}
        {!short && <>&nbsp;</>}
        {timeLoc}
    </span>;
}
TimeLoc.defaultProps = {
    case: 'lower'
}
export default TimeLoc;