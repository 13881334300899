import ApiError from './error';
import { ErrorCode, HttpStatusCode } from '../../enums';
import { ApiResponse } from './response';
import { ArgsProps } from 'antd/lib/notification';
import { notification } from 'antd';
import { Localization } from '../../utils';
import ApiRequest from './request';

export default class ApiResult<T extends ApiResponse = ApiResponse> {
    public response: T;
    public error: ApiError | null = null;
    public status: any;
    public request: ApiRequest;

    public get hasError(): boolean { return this.error != null || this.status != HttpStatusCode.OK; }
    public get success(): boolean { return !this.hasError && !this.response.hasErrors; }

    public hasResponseError(...errors: ErrorCode[]) { return this.response.hasError(...errors); }

    /**
     * @deprecated use `useNotifier` | `useApiRequest` hooks or <Request> instead
     * @param opts 
     * @returns 
     */
    public notify(opts: {
        success?: ArgsProps,
        error?: ArgsProps,
        loc?: Localization
    } = {}) {
        if (this.success) {
            opts.success && notification.success({ duration: 2, ...opts.success });
            return;
        }
        if (this.response.errors.length > 0) {
            this.response.errors.forEach(e => {
                notification.error({ duration: 2, message: e.message })
            });
            return;
        }
        if (opts.error) {
            notification.error({ duration: 2, ...opts.error });
            return;
        }
        if (opts.loc) {
            notification.error({ duration: 2, message: opts.loc.word('Error occured') });
            return;
        }
    }

    constructor(result: Partial<ApiResult<T>>) {
        Object.assign(this, result);
    }
}

export class OkApiResult extends ApiResult<any> {
    constructor(body?: any) {
        super({
            status: 200,
            response: new ApiResponse({ errors: [], body }),
            error: null
        });
    }
}

export class BadApiResult extends ApiResult<any> {
    constructor(status?: number, response?: any) {
        super({
            status: status ?? 0,
            response: response ?? new ApiResponse(null),
            error: response ? new ApiError(response) : null
        });
    }

    static withErrors(...errors: ErrorCode[]) {
        return new BadApiResult(HttpStatusCode.OK, new ApiResponse({ body: null, errors: errors.map(x => ({ code: x })) }));
    }
}