import { DependencyList, useContext, useMemo, useState } from "react"
import { Rule } from "antd/lib/form";
import { Localization, isEmpty } from "../../../utils";
import { Context } from "../../AccelProvider/AccelProvider";
import { isValidPhoneNumber } from "../../PhoneInput/PhoneInput";

export const RegexPattern = {
    digit: /^[0-9]*$/,
    latinAndDigits: /^[A-Za-z0-9]+$/,
    simplePolicyPassword: /^(?=.*\d)(?=.*\D).{6,}$/,
    strongPolicyPassword: /^(?=.*[A-Za-z]|[А-Яа-я])(?=.*\d)(?=.*[@$!%*?&#^();:"№.,~`])[A-Za-z\d@$!%*?&#^();:"№.,~`А-Яа-я]{8,30}$/,
    urlDomain: /^[a-z0-9]+([\-_\.]{1,253}[a-z0-9]+)*\.[a-z]{2,63}$/i,
    urlWithArgs: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i, // todo fix this
    urlSegment: /^([a-z0-9]+(?:[_a-z0-9\-]{0,61}[a-z0-9])?)+$/,
    email: /^[_a-zA-Z0-9][a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-.]{0,61}[a-zA-Z0-9])?)$/,
    emailNoDomain: /^[_a-zA-Z0-9][a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*$/,
    axlVariable: /^#.*#$/,
}

export class FormRuleBuilder {

    rules: Rule[] = [];

    constructor(private loc: Localization) {
    }

    /**
     * @returns Get current ruleset and flush rules
     */
    build() {
        const rules = this.rules;
        this.rules = [];
        return rules;
    }

    required(required: boolean = true, message?: string) {
        this.rules.push({
            required,
            message: message ?? this.loc.word('Required field')
        });
        return this;
    }

    selectRequired(required: boolean = true, message?: string) {
        this.rules.push({
            required,
            validator: async (rule, value?: any[]) => {
                if (!required) return;
                if (!value || value.length == 0)
                    throw new Error(message ?? this.loc.word('Required field'));
            }
        });
        return this;
    }

    /**
     * @param type (optional) whether to validate full email or email without domain 
     */
    email(type: 'full' | 'noDomain' = 'full') {
        const regexEmail = type === 'noDomain' ? RegexPattern.emailNoDomain : RegexPattern.email;
        this.pattern(regexEmail, this.loc.word('Global.rule.email', { default: "Please enter a valid email" }));
        return this;
    }


    password(policy: 'simple' | 'strong' = 'simple',) {
        switch (policy) {
            case 'simple':
                this.rules.push(
                    {
                        type: 'string',
                        min: 6,
                        max: 30,
                        message: this.loc.word('Global.rule.password.lengthRule', { default: 'Password must be between 6 and 30 characters' })
                    },
                    {
                        pattern: RegexPattern.simplePolicyPassword,
                        message: this.loc.word('Global.rule.password.patternRule', { default: 'Password should include at least one letter and one number' })
                    });
                break;
            case 'strong':
                this.rules.push(
                    {
                        pattern: RegexPattern.strongPolicyPassword,
                        message: this.loc.word('Global.rule.password.strongPolicy', { default: 'Password must be between 8 and 30 characters long, including letters, symbols and numbers' })
                    });
                break;
        }
        return this;
    }

    phone() {
        this.rules.push(
            {
                validator: async (rule: any, value: any) => {
                    if (!value || isValidPhoneNumber(value))
                        return;
                    throw new Error(this.loc.word('Global.rule.phone.pattern', { default: "Please enter a valid phone number" }));
                }
            });
        return this;
    }

    match(target: any, message?: string) {
        return this.pushRules({
            validator: async (rule, _value) => {
                const targetValue = typeof target === 'function' ? target() : target;
                if (targetValue !== _value)
                    throw new Error(message ?? this.loc.word('Global.rule.match', { default: 'Value must match' }));
            }
        });
    }

    min(min: number, message?: string) {
        this.rules.push(
            {
                type: 'string',
                min,
                message: message ?? this.loc.word('Global.rule.minLengthTemplate', { default: 'Value must contain at least {0} characters', args: [min.toString()] })
            });
        return this;
    }

    max(max: number, message?: string) {
        this.rules.push(
            {
                type: 'string',
                max,
                message: message ?? this.loc.word('Global.rule.maxLengthTemplate', { default: 'Value must contain no more than {0} characters', args: [max.toString()] })
            });
        return this;
    }

    minNumber(min: number, message?: string) {
        this.rules.push(
            {
                type: 'number',
                min,
                message: message ?? this.loc.word('Global.rule.minNumberTemplate', { default: 'Value must be more than {0}', args: [min.toString()] })
            });
        return this;
    }

    maxNumber(max: number, message?: string) {
        this.rules.push(
            {
                type: 'number',
                max,
                message: message ?? this.loc.word('Global.rule.maxNumberTemplate', { default: 'Value must be less than {0}', args: [max.toString()] })
            });
        return this;
    }

    latinAndDigits(message?: string) {
        this.pattern(RegexPattern.latinAndDigits, message ?? this.loc.word('Global.rule.latinAndDigits', { default: 'Value must contain only latin characters and digits' }));
        return this;
    }

    digits(message?: string) {
        this.pattern(RegexPattern.digit, message ?? this.loc.word('Global.rule.digits', { default: 'Value must be a digit' }));
        return this;
    }

    pattern(regex: RegExp, message?: string) {
        this.rules.push({
            pattern: regex,
            message: message ?? this.loc.word('Global.rule.pattern', { default: 'Value must match pattern' })
        });
        return this;
    }

    url(opts?: { onlyDomain: boolean }, message?: string) {
        const msg = message ?? this.loc.word('Global.rule.url', { default: 'Value must be a url' });
        const onlyDomain = opts?.onlyDomain ?? false;
        if (!onlyDomain) {
            this.rules.push({
                type: 'url',
                message: msg
            });
        }
        else
            this.pattern(RegexPattern.urlDomain, msg);
        return this;
    }

    urlSegment(message?: string) {
        this.pattern(RegexPattern.urlSegment, message ?? this.loc.word('Global.rule.urlSegment', { default: 'Enter a valid domain (only latin lowercase letters, no spaces)' }));
        return this;
    }

    notEmpty(message?: string, whitespace: boolean = true) {
        this.rules.push({
            type: 'string',
            whitespace: whitespace,
            validator: async (rule: any, value: any) => {
                if (isEmpty(value))
                    throw new Error(message ?? this.loc.word('Global.rule.notEmpty', { default: 'Value cannot be empty' }));
            }
        });
        return this;
    }

    custom(rule: Rule) {
        this.rules.push(rule);
        return this;
    }

    axlVariable(message?: string) {
        this.pattern(RegexPattern.axlVariable, message ?? this.loc.word('Global.rule.axlVariable', { default: 'Variable must be in format #variable#' }));
        return this;
    }

    private pushRules(...rules: Rule[]) {
        this.rules.push(...rules);
        return this;
    }
}

const useFormRuleBuilder = () => {
    const { loc } = useContext(Context);
    const [builder] = useState(new FormRuleBuilder(loc));

    return builder;
}
export default useFormRuleBuilder;

const useFormRules = function <T = any>(factory: (builder: FormRuleBuilder) => T, deps: DependencyList | undefined): T {
    const builder = useFormRuleBuilder();
    const rules = useMemo(() => factory(builder), deps);
    return rules;
}
export { useFormRules };
