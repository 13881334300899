import { action, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';


export class ParagraphItemSettings extends QuizItemSettings {
    constructor(s?: Partial<ParagraphItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    /** markdown */
    @observable text: string;
    @observable textHtml: string;
    @observable fontSize: number;
    @observable textColor?: string;

    @action update(q: Partial<ParagraphItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<ParagraphItemSettings>) {
        return new ParagraphItemSettings({
            ...QuizItemSettings.default,
            text: '',
            fontSize: 16,
            ...settings
        });
    }

    clone() {
        return new ParagraphItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return ParagraphItemSettings.build(json);
    }
}
