import { TimeSpan } from ".";

export default class Timer {
    private timerObj: any;
    public isActive: boolean = false;
    public isInterval: boolean = false;

    public set(fn: () => void, span: TimeSpan, fireImmediately = false) {
        if (fireImmediately) fn();
        this.timerObj = setTimeout(() => {
            fn();
            this.clear();
        }, span.milliseconds);
        this.isActive = true;
        this.isInterval = false;
    }

    public setInterval(fn: () => void, span: TimeSpan, fireImmediately = false) {
        if (fireImmediately) fn();
        this.timerObj = setInterval(() => {
            fn();
        }, span.milliseconds);
        this.isActive = true;
        this.isInterval = true;
    }

    public clear() {
        if (this.isInterval) {
            clearInterval(this.timerObj);
        } else {
            clearTimeout(this.timerObj);
        }
        this.timerObj = null;
    }
}
