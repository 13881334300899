import { Lang } from '../../enums';
import moment, { type Moment } from 'moment';

// moment().format('LT');   // 1:30 PM
// moment().format('LTS');  // 1:30:05 PM
// moment().format('L');    // 11/25/2021
// moment().format('l');    // 11/25/2021
// moment().format('LL');   // November 25, 2021
// moment().format('ll');   // Nov 25, 2021
// moment().format('LLL');  // November 25, 2021 1:30 PM
// moment().format('lll');  // Nov 25, 2021 1:30 PM
// moment().format('LLLL'); // Thursday, November 25, 2021 1:30 PM
// moment().format('llll'); // Thu, Nov 25, 2021 1:30 PM

export declare type DateTimeFormat = 'LT' | 'LTS' | 'L' | 'l' | 'LL' | 'll' | 'LLL' | 'lll' | 'LLLL' | 'llll';

export class DateTimeConverter {

    static format(date: Moment | Date, lang: Lang, format: DateTimeFormat | string = 'LLL'): string {
        const d = moment(date);
        return d.format(format);
    }

    static fromNow(date: Moment | Date, lang: Lang): string {
        const d = moment(date);
        return d.fromNow();
    }

    static calendar(date: Moment | Date, lang: Lang): string {
        const d = moment(date);
        return d.calendar();
    }
}