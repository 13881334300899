import { useEffect, useState } from "react";
import { TimeSpan } from "../../utils";

export function useDetectFirstRender(delay?: TimeSpan) {
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        let timer: any;
        if (delay) timer = setTimeout(() => setFirstRender(false), delay.milliseconds);
        else setFirstRender(false);

        return () => clearTimeout(timer);
    }, []);
    return firstRender;
}