
import { observable, action } from 'mobx';
import { Admin, Entity } from '../..';
import { ISerializable } from '../../Entity';


export enum CallSIPRegistrationStatus {
    None = 'none',
    InProgress = 'inProgress',
    Verified = 'verified',
    Diactivated = 'diactivated',
    Error = 'error'
}

export default class CallSIPRegistration extends Entity implements ISerializable {
    constructor(callSIPRegistration?: Partial<CallSIPRegistration>) {
        super(callSIPRegistration);
        if (callSIPRegistration) this.update(callSIPRegistration);
    }

    @observable name: string;
    @observable domain: string;
    @observable externalId: string;
    @observable login: string;
    @observable password: string;
    @observable lastErrorMessage: string;
    @observable providerCost: number;
    @observable status: CallSIPRegistrationStatus;
    @observable createdDate: Date;
    @observable updatedDate: Date;
    @observable purchaseDate: Date | null;
    @observable nextSubscriptionRenewalDate: Date | null;
    @observable admins: Admin[] = [];


    toJson() {
        return {
            id: this.id,
            name: this.name,
            domain: this.domain,
            externalId: this.externalId,
            login: this.login,
            password: this.password,
            lastErrorMessage: this.lastErrorMessage,
            providerCost: this.providerCost,
            status: this.status,
            createdDate: this.createdDate,
            updatedDate: this.updatedDate,
            purchaseDate: this.purchaseDate,
            nextSubscriptionRenewalDate: this.nextSubscriptionRenewalDate,
            adminId: this.admins?.length > 0
                ? this.admins[0].id
                : null
        };
    }

    static fromJson(json: any): CallSIPRegistration {
        return new CallSIPRegistration({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            purchaseDate: json.purchaseDate ? new Date(json.purchaseDate) : null,
            nextSubscriptionRenewalDate: json.nextSubscriptionRenewalDate ? new Date(json.nextSubscriptionRenewalDate) : null,
            admins: json.sipRegistrationLinks?.map((x: any) => Admin.fromJson(x.schoolAdmin.admin)) ?? [],
        });
    }

    @action
    update(callSIPRegistration: Partial<CallSIPRegistration>, allowUndefined = false) {
        super.update(callSIPRegistration, allowUndefined);
    }

    static default() {
        return new CallSIPRegistration({
            admins: []
        });
    }
}

