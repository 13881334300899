
import { observable, action } from 'mobx';
import { Entity, Flow, ForumThread } from '..';

export default class FlowForum extends Entity {
    constructor(item?: Partial<FlowForum>) {
        super(item);
        if (item) this.update(item);
    }

    @observable forumThread: ForumThread;
    @observable flow: Flow;

    static fromJson(json: any): FlowForum {
        return new FlowForum({
            ...json,
            forum: json.forumThread ? ForumThread.fromJson(json.forumThread) : undefined,
            flow: json.flow ? Flow.fromJson(json.flow) : undefined,
        });
    }

    @action
    update(item: Partial<FlowForum>) {
        super.update(item);
    }
}

