import React, { } from "react";

import {
    CloseCircleOutlined,
    ReloadOutlined,
    CloudDownloadOutlined,
    UploadOutlined,
    DeleteFilled
} from '@ant-design/icons';

import { Popconfirm, Tooltip, Button, Space } from 'antd';
import { AccelFile } from '../../../../models';
import { FileUploadStatus } from '../../../../enums';
import { Localization } from '../../../../utils';
import { SizeType } from "antd/lib/config-provider/SizeContext";

type FileActionsProps = {
    file: AccelFile;
    downloadable: boolean;
    deletable: boolean;
    replaceable: boolean;
    uploadStatus: FileUploadStatus;
    loc: Localization;
    theme: 'light' | 'dark';
    size: SizeType;
    onReplace: () => void;
    onDownload: () => void;
    onUpload: () => void;
    onRemove: () => void;
    onAbort: () => void;
}
const FileActions: React.FC<FileActionsProps> = ({ file, downloadable, replaceable, deletable, uploadStatus, loc, theme, size, onReplace, onDownload, onUpload, onRemove, onAbort }) => {

    return <Space size={size == 'small' ? 2 : 5}>

        {uploadStatus == FileUploadStatus.Uploading
            && <Button size={size}
                type='text'
                icon={<CloseCircleOutlined />}
                title={loc.word('Cancel')}
                onClick={() => onAbort()}
                className='p-0'
            />}

        {(replaceable && uploadStatus == FileUploadStatus.Uploaded)
            && <Button size={size}
                type='text'
                icon={<UploadOutlined className={theme == 'light' ? 'color-white' : undefined} />}
                title={loc.word('File.replaceMsg', { default: 'Replace file' })}
                onClick={() => onReplace()}
                className='p-0'
            />}

        {(downloadable && uploadStatus == FileUploadStatus.Uploaded)
            && <Button size={size}
                type='text'
                icon={<CloudDownloadOutlined className={theme == 'light' ? 'color-white' : undefined} />}
                title={loc.word('Download')}
                onClick={() => onDownload()}
                className='p-0'
            />}

        {uploadStatus == FileUploadStatus.Error
            && <>
                <Tooltip title={loc.word('File.uploadErrorMsg', { default: 'Click to retry upload' })}>
                    <Button size={size}
                        type='text'
                        danger
                        icon={<ReloadOutlined className={theme == 'light' ? 'color-white' : undefined} />}
                        onClick={() => onUpload()}
                        className='p-0'
                    />
                </Tooltip>
            </>}

        {(deletable == true && uploadStatus != FileUploadStatus.Uploading) &&
            <Popconfirm title={loc.word('File.deleteConfirmation', { default: 'Are you sure you want to delete this file?' })}
                onConfirm={() => onRemove()}>
                <Button size={size}
                    type='text'
                    danger
                    icon={<DeleteFilled />}
                    className='p-0'
                />
            </Popconfirm>}
    </Space>
}
export default FileActions;