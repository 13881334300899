import { computed, observable } from 'mobx';
import { Entity, Course, CourseItem, SitePage, Flow, Webinar, Widget, Product, ChatBot, Quiz, Pipeline, Partnership, Cert, ChatChannel, TelegramGroup } from '..';
import { Gamification, GamificationProduct } from '../Gamification';

export enum ScenarioType {
    Course = 'course',
    CourseItem = 'courseItem',
    Page = 'sitePage',
    Flow = 'flow',
    Webinar = 'webinar',
    Widget = 'widget',
    Product = 'product',
    ChatBot = 'chatBot',
    TelegramGroup = 'group',
    Simple = 'simple',
    Quiz = 'quiz',
    Pipeline = 'pipeline',
    Partnership = 'partnership',
    Cert = 'cert',
    ChatChannel = 'chatChannel',
    Gamification = 'gamification',
    GamificationProduct = 'gamificationProduct',
}

export default class Scenario extends Entity {
    constructor(scenario?: Partial<Scenario>) {
        super(scenario);
        if (scenario) this.update(scenario);
    }

    @observable name: string;
    @observable description: string;
    @observable isActive: boolean;
    createdDate: Date;
    updatedDate: Date;
    course: Course | null;
    courseItem: CourseItem | null;
    sitePage: SitePage | null;
    flow: Flow | null;
    webinar: Webinar | null;
    widget: Widget | null;
    product: Product | null;
    chatBot: ChatBot | null;
    telegramGroup: TelegramGroup | null;
    quiz: Quiz | null;
    pipeline: Pipeline | null;
    partnership: Partnership | null;
    cert: Cert | null;
    chatChannel: ChatChannel | null;
    gamification: Gamification | null;
    gamificationProduct: GamificationProduct | null;

    /**
     * The target id of the scenario used to prevent fetching every entity separately
     */
    targetId: string;
    nodeCount: number;
    type: ScenarioType;
    completedStudentScenariosCount: number;
    activeStudentScenariosCount: number;

    @computed get contentName() {
        if (this.softDeleted)
            return null;

        switch (this.type) {
            case ScenarioType.CourseItem:
                return this.courseItem?.title;
            case ScenarioType.Course:
                return this.course?.name;
            case ScenarioType.Page:
                return this.sitePage?.name;
            case ScenarioType.Flow:
                return this.flow?.title;
            case ScenarioType.Webinar:
                return this.webinar?.name;
            case ScenarioType.Widget:
                return this.widget?.name;
            case ScenarioType.Product:
                return this.product?.name;
            case ScenarioType.ChatBot:
                return this.chatBot?.name;
            case ScenarioType.TelegramGroup:
                return this.telegramGroup?.name;
            case ScenarioType.Quiz:
                return this.quiz?.title;
            case ScenarioType.Pipeline:
                return this.pipeline?.name;
            case ScenarioType.Partnership:
            case ScenarioType.Gamification:
                return undefined;
            case ScenarioType.Cert:
                return this.cert?.name;
            case ScenarioType.ChatChannel:
                return this.chatChannel?.name;
            case ScenarioType.GamificationProduct:
                return this.gamificationProduct?.title;
            default:
                return this.name;
        }
    }

    @computed get isContentDeleted() {
        if (this.softDeleted)
            return true;

        switch (this.type) {
            case ScenarioType.CourseItem:
                return this.courseItem?.softDeleted || false;
            case ScenarioType.Course:
                return this.course?.softDeleted || false;
            case ScenarioType.Page:
                return this.sitePage?.softDeleted || false;
            case ScenarioType.Flow:
                return this.flow?.softDeleted || false;
            case ScenarioType.Webinar:
                return this.webinar?.softDeleted || false;
            case ScenarioType.Widget:
                return this.widget?.softDeleted || false;
            case ScenarioType.Product:
                return this.product?.softDeleted || false;
            case ScenarioType.ChatBot:
                return this.chatBot?.softDeleted || false;
            case ScenarioType.TelegramGroup:
                return this.telegramGroup?.softDeleted || false;
            case ScenarioType.Quiz:
                return this.quiz?.softDeleted || false;
            case ScenarioType.Pipeline:
                return this.pipeline?.softDeleted || false;
            case ScenarioType.Cert:
                return this.cert?.softDeleted || false;
            case ScenarioType.ChatChannel:
                return this.chatChannel?.softDeleted || false;
            default:
                return false;
        }
    }

    @computed get canCopy() {
        return this.type == ScenarioType.Simple;
    }

    update(entity: Partial<Scenario>) {
        super.update(entity);
    }

    static fromJson(json: any): Scenario {
        return new Scenario({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            course: json.course ? Course.fromJson(json.course) : undefined,
            courseItem: json.courseItem ? CourseItem.fromJson(json.courseItem) : undefined,
            sitePage: json.sitePage ? SitePage.fromJson(json.sitePage) : undefined,
            flow: json.flow ? Flow.fromJson(json.flow) : undefined,
            webinar: json.webinar ? Webinar.fromJson(json.webinar) : undefined,
            widget: json.widget ? Widget.fromJson(json.widget) : undefined,
            product: json.product ? Product.fromJson(json.product) : undefined,
            chatBot: json.chatBot ? ChatBot.fromJson(json.chatBot) : undefined,
            telegramGroup: json.group ? TelegramGroup.fromJson(json.group) : undefined,
            quiz: json.quiz ? Quiz.fromJson(json.quiz) : undefined,
            pipeline: json.pipeline ? Pipeline.fromJson(json.pipeline) : undefined,
            partnership: json.partnership ? Partnership.fromJson(json.partnership) : undefined,
            cert: json.cert ? Cert.fromJson(json.cert) : undefined,
            chatChannel: json.chatChannel ? ChatChannel.fromJson(json.chatChannel) : undefined,
            gamification: json.gamification ? Gamification.fromJson(json.gamification) : undefined,
            gamificationProduct: json.gamificationProduct ? GamificationProduct.fromJson(json.gamificationProduct) : undefined,
            type: json.type,
            nodeCount: json.nodeCount,
            completedStudentScenariosCount: json.completedStudentScenariosCount,
            activeStudentScenariosCount: json.activeStudentScenariosCount,
        });
    }

    copy() {
        return new Scenario({
            id: this.id,
            name: this.name,
            description: this.description,
            type: this.type,
        });
    }
}