import { action, observable } from 'mobx';
import QuizItemSettings from './QuizItemSettings';

export class ImageItemSettings extends QuizItemSettings {
    constructor(s?: Partial<ImageItemSettings>) {
        super();
        if (s)
            this.update(s);
    }

    @observable imageUrl: string | null;
    @observable fit: 'fill' | 'contain' | 'cover' | 'none';
    @observable widthPercent: number;
    @observable height: number | null;
    @observable align: 'left' | 'center' | 'right';

    @action update(q: Partial<ImageItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    static build(settings?: Partial<ImageItemSettings>) {
        return new ImageItemSettings({
            ...QuizItemSettings.default,
            imageUrl: null,
            fit: 'contain',
            widthPercent: 100,
            height: null,
            align: 'center',
            ...settings
        });
    }

    clone() {
        return new ImageItemSettings({
            ...this
        });
    }

    static fromJson(json: any) {
        return ImageItemSettings.build(json);
    }
}
