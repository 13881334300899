import { Course, Entity, CoursePlan, Library, AccelFile } from '..';
import { ProductContentType } from './ProductType';
import { observable, action, computed } from 'mobx';


export default class ProductContent extends Entity {

    constructor(content?: Partial<ProductContent>) {
        super(content);
        if (content)
            this.update(content);
    }

    @observable image: AccelFile | null;
    @observable course: Course | null;
    @observable coursePlan: CoursePlan | null;
    @observable library: Library | null;
    @observable libraryItemIds: string[] | null;
    @observable type: ProductContentType;

    @computed get contentSoftDeleted() {
        switch (this.type) {
            case ProductContentType.Course:
                return this.course?.softDeleted;
            case ProductContentType.Library:
                return this.library?.softDeleted;
            default: return undefined;
        }
    }

    @computed get name() {
        switch (this.type) {
            case ProductContentType.Course:
                return this.course?.name;
            case ProductContentType.Library:
                return this.library?.name;
            default: return undefined;
        }
    }

    @computed get hasLibraryItems() {
        return this.libraryItemIds && this.libraryItemIds.length > 0;
    }

    @action changeType(type: ProductContentType) {
        this.type = type;
        this.course = null;
        this.coursePlan = null;
        this.library = null;
        this.libraryItemIds = null;
    }

    @action update(product: Partial<ProductContent>, allowUndefined = false) {
        super.update(product, allowUndefined);
    }

    static fromJson(json: any): ProductContent {
        return new ProductContent({
            id: json.id,
            type: json.type,
            course: json.course ? Course.fromJson(json.course) : null,
            coursePlan: json.coursePlan ? CoursePlan.fromJson(json.coursePlan) : null,
            library: json.libraryAccess ? Library.fromJson(json.libraryAccess.library) : null,
            libraryItemIds: (json.libraryAccess && json.libraryAccess.itemAccesses)
                ? json.libraryAccess.itemAccesses.map((x: any) => x.libraryItem.id)
                : null,
            image: json.image ? AccelFile.fromJson(json.image) : undefined,
        });
    }

    clone() {
        return new ProductContent({
            id: this.id,
            type: this.type,
            course: this.course,
            coursePlan: this.coursePlan,
            library: this.library,
            libraryItemIds: this.libraryItemIds?.slice()
        });
    }

    hasChanges(content: ProductContent) {
        return this.id != content.id
            || this.image?.id != content.image?.id
            || this.course?.id != content.course?.id
            || this.coursePlan?.id != content.coursePlan?.id
            || this.library?.id != content.library?.id
            || this.type != content.type
            || !(this.libraryItemIds ?? []).isEquals(content.libraryItemIds ?? [], (a, b) => a === b);
    }
}
