export { }

declare global {
    interface Storage {
        getItemOrDefault<T = any>(key: string, defaultItem: T, parse?: (val: string) => T): T;

        getJsonOrDefault<T = any>(key: string, defaultItem: T): T;
        setJson(key: string, value: any): void;
    }
}

if (!Storage.prototype.getItemOrDefault) {
    Storage.prototype.getItemOrDefault = function <T = any>(key: string, defaultItem: T, parse?: (val: string) => T): T {
        const val = localStorage.getItem(key);
        if (val == null) return defaultItem!;
        //@ts-ignore just to suppress webinar build error
        if (!parse) return val as T;
        return parse(val);
    }
}

if (!Storage.prototype.getJsonOrDefault) {
    Storage.prototype.getJsonOrDefault = function <T = any>(key: string, defaultItem: T): T {
        return localStorage.getItemOrDefault(key, defaultItem, x => JSON.parse(x));
    }
}

if (!Storage.prototype.setJson) {
    Storage.prototype.setJson = function (key: string, value: any): void {
        return localStorage.setItem(key, JSON.stringify(value));
    }
}