import { Updatable } from '../../../..';

export default class OnlinePbxSettings extends Updatable {
    public apiKey: string;

    constructor(settings?: Partial<OnlinePbxSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<OnlinePbxSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}