
import { observable, action } from 'mobx';
import { Entity, Flow, Library, LibraryItem } from '..';

export default class FlowLibrary extends Entity {
    constructor(item?: Partial<FlowLibrary>) {
        super(item);
        if (item) this.update(item);
    }

    @observable flow: Flow;
    @observable library: Library;
    @observable libraryItems: LibraryItem[];

    static fromJson(json: any): FlowLibrary {
        return new FlowLibrary({
            ...json,
            library: json.library ? Library.fromJson(json.library) : undefined,
            libraryItems: json.items
                ? json.items.map((x: any) => LibraryItem.fromJson(x))
                : [],
            flow: json.flow ? Flow.fromJson(json.flow) : undefined,
        });
    }

    @action
    update(item: Partial<FlowLibrary>) {
        super.update(item);
    }
}

