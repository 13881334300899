import { BillingPlanPriceType } from './../PlanPrice/BillingPlanPriceType';
import { action, computed } from 'mobx';
import { type Moment } from 'moment';
import { Entity, Updatable } from '../..';
import moment from 'moment';
import { Currency } from '../../../enums';

export class BillingInvoiceBracketAmount extends Updatable {
    constructor(i?: Partial<BillingInvoiceBracketAmount>) {
        super();
        if (i) this.update(i);
    }

    type: BillingPlanPriceType;
    count: number;
    amount: number;

    fromBracket: number;
    toBracket?: number;
    bracketAmount: number;

    static fromJson(json: any): BillingInvoiceBracketAmount {
        return new BillingInvoiceBracketAmount({
            type: json.type,
            count: json.count,
            amount: json.amount,
            fromBracket: json.fromBracket,
            toBracket: json.toBracket,
            bracketAmount: json.bracketAmount,
        });
    }

    @action
    update(t: Partial<BillingInvoiceBracketAmount>) {
        super.update(t);
    }
}


export class BillingInvoiceDetail extends Updatable {
    constructor(i?: Partial<BillingInvoiceDetail>) {
        super();
        if (i) this.update(i);
    }

    month: number;
    year: number;
    day: number;
    createdDate: Moment;

    get period(): string {
        return `${this.year}-${this.month.toString().padStart(2, '0')}-${this.day.toString().padStart(2, '0')}`;
    }

    get date(): Moment {
        const d = moment.utc();
        d.set('year', this.year);
        d.set('month', this.month - 1);
        d.set('date', this.day);
        d.startOf('day');
        return d;
    }

    type: BillingPlanPriceType;
    count: number;
    amount: number;

    static fromJson(json: any): BillingInvoiceDetail {
        return new BillingInvoiceDetail({
            month: json.month,
            year: json.year,
            day: json.day,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            type: json.type,
            count: json.count,
            amount: json.amount,
        });
    }

    @action
    update(t: Partial<BillingInvoiceDetail>) {
        super.update(t);
    }
}

export default class BillingInvoice extends Entity {
    constructor(i?: Partial<BillingInvoice>) {
        super(i);
        if (i) this.update(i);
    }

    month: number;
    year: number;

    get period(): string {
        return `${this.year}-${this.month.toString().padStart(2, '0')}`;
    }

    number: string;
    createdDate: Moment;
    // sum of bracketAmounts
    amount: number;
    correctionToMinimumExpensesAmount: number;
    trialPeriodBonusAmount: number;
    exchangeCurrency: Currency;
    exchangeRate: number;

    isClosed: boolean;

    totals: BillingInvoiceBracketAmount[];
    brackets: BillingInvoiceBracketAmount[];
    details: BillingInvoiceDetail[];

    @computed get bracketsMap(): Map<BillingPlanPriceType, BillingInvoiceBracketAmount[]> {
        if (!this.brackets) return new Map();
        const map = this.brackets.groupBy(x => x.type) as Map<BillingPlanPriceType, BillingInvoiceBracketAmount[]>;
        map.forEach((brackets, type) => map.set(type, brackets.orderBy(x => x.fromBracket)));
        return map;
    }

    @computed get detailsMap(): Map<BillingPlanPriceType, BillingInvoiceDetail[]> {
        if (!this.details) return new Map();
        const map = this.details.groupBy(x => x.type) as Map<BillingPlanPriceType, BillingInvoiceDetail[]>;
        map.forEach((brackets, type) => map.set(type, brackets.orderBy(x => x.period)));
        return map;
    }

    static fromJson(json: any): BillingInvoice {
        return new BillingInvoice({
            id: json.id,
            month: json.month,
            year: json.year,
            number: json.number,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            details: json.details ? json.details.map(BillingInvoiceDetail.fromJson) : undefined,
            brackets: json.brackets ? json.brackets.map(BillingInvoiceBracketAmount.fromJson) : undefined,
            totals: json.totals ? json.totals.map(BillingInvoiceBracketAmount.fromJson) : undefined,
            amount: json.amount,
            correctionToMinimumExpensesAmount: json.correctionToMinimumExpensesAmount,
            trialPeriodBonusAmount: json.trialPeriodBonusAmount,
            exchangeCurrency: json.exchangeCurrency,
            exchangeRate: json.exchangeRate,
            isClosed: json.isClosed,
        });
    }

    @action
    update(t: Partial<BillingInvoice>) {
        super.update(t);
    }
}

