
import { observable, action } from 'mobx';
import { Entity, Course, Flow, CoursePlan } from '..';

export default class FlowCourse extends Entity {
    constructor(item?: Partial<FlowCourse>) {
        super(item);
        if (item) this.update(item);
    }

    @observable plan: CoursePlan;
    @observable course: Course;
    @observable flow: Flow;

    static fromJson(json: any): FlowCourse {
        return new FlowCourse({
            ...json,
            course: json.course ? Course.fromJson(json.course) : undefined,
            plan: json.plan ? CoursePlan.fromJson(json.plan) : undefined,
            flow: json.flow ? Flow.fromJson(json.flow) : undefined,
        });
    }

    @action
    update(item: Partial<FlowCourse>) {
        super.update(item);
    }
}

