export enum Env {
    dev = 'Development',
    prod = 'Production'
}

const env: Env = process.env.NODE_ENV == 'development' ? Env.dev : Env.prod;
const isDev = env == Env.dev;
const isProd = env == Env.prod;
export {
    env,
    isDev,
    isProd
}