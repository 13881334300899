import { useState, useCallback } from "react";
import { type AudioMeta } from "../../models/file/accel-file";
import { FileSize } from "../../utils";

export interface recorderControls {
    startRecording: () => Promise<boolean>;
    stopRecording: () => void;
    togglePauseResume: () => void;
    recordingBlob?: Blob;
    isRecording: boolean;
    isPaused: boolean;
    recordingTime: number;
    mediaRecorder?: MediaRecorder;
}

export type MediaAudioTrackConstraints = Pick<
    MediaTrackConstraints,
    | "deviceId"
    | "groupId"
    | "autoGainControl"
    | "channelCount"
    | "echoCancellation"
    | "noiseSuppression"
    | "sampleRate"
    | "sampleSize"
>;

const useAudioRecorder: (
    audioTrackConstraints?: MediaAudioTrackConstraints,
    onNotAllowedOrFound?: (exception: DOMException) => any,
    mediaRecorderOptions?: MediaRecorderOptions,
    onRecorded?: (blob: Blob) => any,
) => recorderControls = (
    audioTrackConstraints,
    onNotAllowedOrFound,
    mediaRecorderOptions,
    onRecorded
) => {
        const [isRecording, setIsRecording] = useState(false);
        const [isPaused, setIsPaused] = useState(false);
        const [recordingTime, setRecordingTime] = useState(0);
        const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
        const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>();
        const [recordingBlob, setRecordingBlob] = useState<Blob>();

        const _startTimer: () => void = useCallback(() => {
            const interval = setInterval(() => {
                setRecordingTime((time) => time + 1);
            }, 1000);
            setTimerInterval(interval);
        }, [setRecordingTime, setTimerInterval]);

        const _stopTimer: () => void = useCallback(() => {
            timerInterval != null && clearInterval(timerInterval);
            setTimerInterval(undefined);
        }, [timerInterval, setTimerInterval]);

        /**
         * Calling this method would result in the recording to start. Sets `isRecording` to true
         */
        const startRecording: () => Promise<boolean> = useCallback(async () => {
            if (timerInterval != null) return false;

            try {
                const stream = await navigator.mediaDevices
                    .getUserMedia({ audio: audioTrackConstraints ?? true });

                setIsRecording(true);
                const recorder: MediaRecorder = new MediaRecorder(
                    stream,
                    mediaRecorderOptions
                );
                setMediaRecorder(recorder);
                recorder.start();
                _startTimer();

                recorder.addEventListener("dataavailable", (event) => {
                    setRecordingBlob(event.data);
                    recorder.stream.getTracks().forEach((t) => t.stop());
                    setMediaRecorder(undefined);
                    onRecorded?.(event.data);
                });
                return true;
            } catch (err: any) {
                console.log(err.name, err.message, err.cause);
                onNotAllowedOrFound?.(err);
                return false;
            }

        }, [
            timerInterval,
            setIsRecording,
            setMediaRecorder,
            _startTimer,
            setRecordingBlob,
            onNotAllowedOrFound,
            mediaRecorderOptions,
            audioTrackConstraints
        ]);

        /**
         * Calling this method results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
         */
        const stopRecording: () => void = useCallback(() => {
            mediaRecorder?.stop();
            _stopTimer();
            setRecordingTime(0);
            setIsRecording(false);
            setIsPaused(false);
        }, [
            mediaRecorder,
            setRecordingTime,
            setIsRecording,
            setIsPaused,
            _stopTimer,
        ]);

        /**
         * Calling this method would pause the recording if it is currently running or resume if it is paused. Toggles the value `isPaused`
         */
        const togglePauseResume: () => void = useCallback(() => {
            if (isPaused) {
                setIsPaused(false);
                mediaRecorder?.resume();
                _startTimer();
            } else {
                setIsPaused(true);
                _stopTimer();
                mediaRecorder?.pause();
            }
        }, [mediaRecorder, setIsPaused, _startTimer, _stopTimer]);

        return {
            startRecording,
            stopRecording,
            togglePauseResume,
            recordingBlob,
            isRecording,
            isPaused,
            recordingTime,
            mediaRecorder,
        };
    };

export default useAudioRecorder;

export const useAudioContext = () => {
    const getMeta = useCallback(async (file: Blob | File) => {

        // Check if file is larger than 10MB
        if (file.size >= FileSize.fromMegabytes(10).bytes) {
            // Generate random frequencyLevels
            const frequencyLevels = Array.from({ length: 128 }, () => parseFloat(Math.random().toPrecision(3)));
            return {
                frequencyLevels,
                durationInSec: 0,
                bitrate: 0,
                channels: 0,
                codecName: file.type === 'audio/webm' ? 'opus' : undefined,
                formatName: file.type ?? "audio/webm"
            } as AudioMeta;
        }

        var audioCtx = new window.AudioContext();
        // create a gain node and connect to the audio context
        var volume = audioCtx.createGain();
        volume.connect(audioCtx.destination);

        // create a analyser node to extract data from the audio source
        // connect the analyser node to the gain node
        var analyser = audioCtx.createAnalyser();
        analyser.connect(volume)
        var source = audioCtx.createBufferSource();

        var arrBuffer = await file.arrayBuffer();

        const buffer = await audioCtx.decodeAudioData(arrBuffer);
        source.buffer = buffer;
        source.connect(analyser);
        const rawData = buffer.getChannelData(0); // We only need to work with one channel of data
        const samples = 128; // Number of samples we want to have in our final data set
        const blockSize = Math.floor(rawData.length / samples); // Number of samples in each subdivision
        const frequencyLevels = [];
        for (let i = 0; i < samples; i++) {
            let sum = 0;
            for (let j = 0; j < blockSize; j++) {
                sum += Math.abs(rawData[i * blockSize + j]);
            }
            let mean = (sum / blockSize);
            frequencyLevels.push(mean);
        }

        const max = Math.max(...frequencyLevels);
        frequencyLevels.forEach((v, i) => frequencyLevels[i] = parseFloat((v / max).toPrecision(3)));

        await audioCtx.close();
        return {
            frequencyLevels,
            durationInSec: buffer.duration,
            bitrate: buffer.sampleRate,
            channels: buffer.numberOfChannels,
            codecName: file.type == 'audio/webm' ? 'opus' : undefined,
            formatName: file.type ?? "audio/webm"
        } as AudioMeta;
    }, []);

    return [getMeta];
}
