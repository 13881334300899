import { action, observable } from "mobx";
import { Updatable, WebinarRegistrationDesignSettings } from "../..";

export default class WebinarDesignSettings extends Updatable {
    constructor(settings?: Partial<WebinarDesignSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable registration: WebinarRegistrationDesignSettings;

    @action update(settings: Partial<WebinarDesignSettings>, allowUndefined: boolean = false) {
        super.update(settings, allowUndefined);
    }

    hasChanges(settings: WebinarDesignSettings) {      
        return this.registration.hasChanges(settings.registration);
    }

    copy() {
        return new WebinarDesignSettings({
            registration: this.registration?.copy(),
        });
    }

    static fromJson(json: any) {
        return new WebinarDesignSettings({
            ...json,
            registration: json.registration ? WebinarRegistrationDesignSettings.fromJson(json.registration) : undefined,
        });
    }
}