import React, { useEffect, useState } from "react";
import styles from './LoadingOverlay.module.scss';
import { Space } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useTimer } from '..';
import { combineClasses, TimeSpan } from '../../utils';

declare type LoadingOverlayPosition = 'relative' | 'absolute' | 'fixed' | 'overlay';
type LoadingOverlayProps = {
    loading?: boolean;
    icon?: React.ReactNode;
    label?: React.ReactNode;
    /**
     * relative - take 100% of wrapper
     * 
     * absolute - take 100% of page (or wrapper if it contains 'relative')
     * 
     * fixed - take 100% of page
     * 
     * overlay - take 100% of wrapper and cover children
     */
    position?: LoadingOverlayPosition;
    delay?: TimeSpan;
    size?: 'small' | 'middle' | 'large';
    theme?: 'light' | 'dark';
    animation?: boolean;
    className?: string;
}
const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loading, icon, label, position, delay, size, theme, animation, className, children }) => {
    const [delayed, setDelayed] = useState(delay ? true : false);
    const timer = useTimer();

    useEffect(() => {
        if (!delay) return;
        timer.set(() => setDelayed(false), delay);
        return () => timer.clear();
    }), [];

    if (delayed) return null;
    return <>
        {loading == true &&
            <div className={combineClasses(styles.loadingOverlay, className)}
                data-position={position ?? 'relative'}
                data-size={size ?? 'middle'}
                data-theme={theme ?? 'light'}
                data-animation={animation ?? false}>
                <Space size={size} align='center'>
                    {icon ?? <LoadingOutlined className={styles.icon} />}
                    {label}
                </Space>
            </div>}
        {children}
    </>;
}
LoadingOverlay.defaultProps = {
    loading: true
}
export default LoadingOverlay;