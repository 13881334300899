import cookies, { CookieAttributes } from 'js-cookie';

export default class CookieStorage {
    set(name: string, value: string, options?: CookieAttributes): void {
        cookies.set(name, value, options);
    }

    setJSON(name: string, value: any, options?: CookieAttributes): void {
        this.set(name, JSON.stringify(value), options);
    }

    get(name: string): string | undefined {
        return cookies.get(name);
    }

    /**
     * Returns the parsed representation of the string
     * stored in the cookie according to JSON.parse
     */
    getJSON<T = any>(name: string): T | undefined {
        var value = this.get(name);
        if (value) return JSON.parse(value) as T;
        return undefined;
    }

    remove(name: string, options?: CookieAttributes): void {
        cookies.remove(name, options);
    }
}