import { debounce, DebounceSettings } from 'lodash';
import React from "react";
import { TimeSpan } from '../../utils';

const useDebouncedCallback = (
    callback: Function,
    dependencies: any[],
    delay: TimeSpan,
    options?: DebounceSettings
) => {

    // FIX ME
    // const comboDeps = dependencies
    //     ? [callback, delay, ...dependencies]
    //     : [callback, delay];

    return React.useCallback(debounce((...args) => {
        callback(...args);
    }, delay.milliseconds, options), [...dependencies]);
};

export default useDebouncedCallback;
