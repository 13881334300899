import { observable, action } from 'mobx';
import Entity, { ISerializable } from '../Entity';

export declare type AccelTagType = 'contact' | 'order' | 'messageTemplate';

export default class AccelTag extends Entity implements ISerializable {
    constructor(tag?: Partial<AccelTag>) {
        super(tag);
        if (tag) this.update(tag);
    }

    @observable text: string;
    @observable type: AccelTagType;
    @observable color: string;
    @observable position?: number;

    @observable createdDate: Date;
    @observable updatedDate: Date;

    @action update(tag: Partial<AccelTag>) {
        super.update(tag);
    }

    clone(): AccelTag {
        return new AccelTag({
            ...this,
        });
    }

    toJson() {
        return {
            id: this.id,
            text: this.text,
            type: this.type,
            color: this.color,
            position: this.position,
        };
    }

    static fromJson(json: any) {
        return new AccelTag({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
        });
    }
}
