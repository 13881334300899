import React from 'react';
import { combineClasses, combineRefs } from '../../utils';

declare type DraggableData = {
    node: HTMLElement;
    position: DraggablePosition;
    deltaX: number;
    deltaY: number;
}

declare type DraggableAxis = 'x' | 'y' | 'both';
export declare type DraggablePosition = { top: number; left?: number, right?: number; };
declare type DraggableEventHandler = (e: MouseEvent, data: DraggableData) => void | false;
type DraggableProps = {
    axis?: DraggableAxis;
    defaultPosition?: DraggablePosition;
    bounds?: 'parent' | string | React.RefObject<any>;
    onStart?: DraggableEventHandler;
    onDrag?: DraggableEventHandler;
    onStop?: DraggableEventHandler;
    onMouseDown?: (e: MouseEvent) => void;
    className?: string;
    classNameDragging?: string;
    classNameDraggingBounds?: string;
    style?: React.CSSProperties;
    handler?: React.RefObject<any>;
    nodeRef?: React.RefObject<HTMLDivElement>;
}

type DraggableState = {
    dragging: boolean;
    position: DraggablePosition;
    lastX?: number;
    lastY?: number;
    bounds?: HTMLElement;
}

export default class Draggable extends React.Component<DraggableProps, DraggableState> {

    static defaultProps: DraggableProps = {
        axis: 'both',
        defaultPosition: { top: 0, left: 0 }
    };

    private nodeRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);
        this.state = {
            dragging: false,
            position: this.props.defaultPosition ?? { top: 0, left: 0 },
        };
        this.nodeRef = React.createRef();
    }

    getBoundsNode(): HTMLElement {
        if (!this.props.bounds || this.props.bounds == 'body') return document.body;
        if (this.props.bounds == 'parent') return this.nodeRef.current!.parentElement!;
        if (typeof (this.props.bounds) === 'string') return document.querySelector(this.props.bounds) as HTMLElement;
        return this.props.bounds.current;
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            position: !this.props.defaultPosition ? this.getPosition(this.nodeRef.current!) : this.state.position,
            bounds: this.getBoundsNode()
        });
        const handler = this.props.handler?.current ?? this.nodeRef.current!;
        handler.addEventListener('mousedown', this.onDragStart);
    }

    componentWillUnmount() {
        const handler = this.props.handler?.current ?? this.nodeRef.current!;
        handler.removeEventListener('mousedown', this.onDragStart);
    }

    getPosition(node: HTMLElement): DraggablePosition {
        const currentStyle = node.style;
        return {
            top: currentStyle.top ? parseInt(currentStyle.top) : 0,
            left: currentStyle.left ? parseInt(currentStyle.left) : undefined,
            right: currentStyle.right ? parseInt(currentStyle.right) : undefined,
        };
    }

    createEventData(e: MouseEvent, position: DraggablePosition): DraggableData {
        return {
            node: this.nodeRef.current!,
            position,
            deltaX: e.movementX,
            deltaY: e.movementY
        }
    }

    onDragStart = (e: MouseEvent) => {
        this.setState({
            ...this.state,
            dragging: true
        });
        this.props.onMouseDown?.(e);

        this.props.onStart?.(e, this.createEventData(e, this.state.position));
        document.addEventListener('mousemove', this.onDrag);
        document.addEventListener('mouseup', this.onDragStop);

        if (this.props.classNameDraggingBounds && this.state.bounds)
            this.state.bounds.classList.add(this.props.classNameDraggingBounds);
    }

    onDrag = (e: MouseEvent) => {

        // mouse move
        let position = this.calcPosition(this.state.position, e);

        //bounds
        const bounds = this.state.bounds!.getBoundingClientRect();
        position = this.calcBoundsPosition(position, bounds);

        this.props.onDrag?.(e, this.createEventData(e, position));
        this.setState({
            ...this.state,
            position,
            lastX: position.left,
            lastY: position.top
        });
    }

    onDragStop = (e: MouseEvent) => {
        this.setState({
            ...this.state,
            dragging: false
        });
        document.removeEventListener('mousemove', this.onDrag);
        document.removeEventListener('mouseup', this.onDragStop);

        this.props.onStop?.(e, this.createEventData(e, this.state.position));

        if (this.props.classNameDraggingBounds && this.state.bounds)
            this.state.bounds.classList.remove(this.props.classNameDraggingBounds);
    }

    private canDragAxis(axis: DraggableAxis) {
        return this.state.dragging && this.props.axis == 'both' || this.props.axis == axis;
    }

    private calcPosition(position: DraggablePosition, e: MouseEvent): DraggablePosition {
        const canDragY = this.canDragAxis('y');
        const canDragX = this.canDragAxis('x');
        return {
            top: canDragY ? position.top + e.movementY : position.top,
            left: canDragX && position.left !== undefined
                ? position.left + e.movementX
                : undefined,
            right: canDragX && position.right !== undefined
                ? position.right - e.movementX
                : undefined,
        };
    }

    private calcBoundsPosition(position: DraggablePosition, bounds: DOMRect): DraggablePosition {
        const node = this.nodeRef.current!;
        const newPosition: DraggablePosition = { ...position };
        if (newPosition.top < bounds.top) newPosition.top = bounds.top;
        const maxTop = bounds.height - node.clientHeight;
        if (newPosition.top > maxTop) newPosition.top = maxTop;
        if (newPosition.top < 0) newPosition.top = 0;

        if (newPosition.right) {
            if (newPosition.right < 0) newPosition.right = 0;
            const maxRight = bounds.width - node.clientWidth;
            if (newPosition.right > maxRight) newPosition.right = maxRight;
        }

        if (newPosition.left) {
            if (newPosition.left < bounds.left) newPosition.left = bounds.left;
            const maxLeft = bounds.width - node.clientWidth + bounds.left;
            if (newPosition.left > maxLeft) newPosition.left = maxLeft;
        }
        return newPosition;
    }

    render() {
        const style: React.CSSProperties = this.state.position && {
            top: this.state.position.top,
            left: this.state.position.left,
            right: this.state.position.right,
        };
        return <div ref={combineRefs(this.nodeRef, this.props.nodeRef!)}
            className={combineClasses('fixed', this.props.className, this.state.dragging ? this.props.classNameDragging : undefined)}
            style={style}>
            {this.props.children}
        </div>;
    }
}