import { Button } from "antd";
import React from "react";
import { Loc } from "../../..";

type Props = {
    footer: React.ReactNode;
}

const SelectFooter: React.FC<Props> = ({ footer, children }) => {
    return <>
        {children}
        <div className="border-t pt-4">
            {footer}
        </div>
    </>;
}
export default SelectFooter;



type DefaultSelectFooterProps = {
    mode?: 'multiple' | 'tags' | 'mono';
    onSelectAll: () => void;
    children: React.ReactNode;
}

const DefaultSelectFooter: React.FC<DefaultSelectFooterProps> = ({ mode, children, onSelectAll }) => {
    if (!mode || mode == 'mono') return <>{children}</>;
    return <SelectFooter footer={<div>
        <Button type='link' onClick={onSelectAll}>
            <Loc word='Select.selectAllVisible' default='Select all visible' />
        </Button>
    </div>} children={children} />;
}
export { DefaultSelectFooter };