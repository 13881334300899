import { action, observable } from "mobx";
import { Updatable } from "../../..";
import { WebinarRegistrationFieldType } from "../../../../enums";

export default class WebinarRegistrationField extends Updatable {
    constructor(field?: Partial<WebinarRegistrationField>) {
        super();
        if (field) this.update(field);
    }

    @observable type: WebinarRegistrationFieldType;
    @observable required: boolean;

    @action update(settings: Partial<WebinarRegistrationField>) {
        super.update(settings);
    }

    get hasSettings() {
        return false;
    }

    get isEditable() {
        return this.type != WebinarRegistrationFieldType.FirstName && this.type != WebinarRegistrationFieldType.TermOfService;
    }

    get isDeletable() {
        return this.type != WebinarRegistrationFieldType.FirstName;
    }

    hasChanged(field: WebinarRegistrationField) {
        return this.required != field.required || this.type != field.type;
    }

    copy() {
        return new WebinarRegistrationField({
            ...this
        });
    }

    static fromJson(json: any) {
        return new WebinarRegistrationField({
            ...json
        });
    }
}