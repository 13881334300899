import { AccelFile, Entity, School } from '..';
import { observable, action, computed } from 'mobx';
import moment, { type Moment } from 'moment';

export enum ApplicationType {
    Custom = 'custom',
    Telegram = 'telegramGroups',
    MetaConversions = 'metaConversions',
    YandexMetrika = 'yandexMetrika'
}

export default class Application extends Entity {
    constructor(application?: Partial<Application>) {
        super(application);
        if (application) this.update(application);
    }

    @observable title: string;
    @observable description: string;
    @observable shortDescription: string;
    @observable pricingJson: string;
    @observable createdDate: Moment;
    @observable updatedDate: Moment | null;

    @observable type: ApplicationType;
    @observable image: AccelFile | null;
    @observable school: School | null;
    @observable schoolAppsCount: number;

    @action update(application: Partial<Application>, allowUndefined = false) {
        super.update(application, allowUndefined);
    }

    static fromJson(json: any): Application {
        return new Application({
            id: json.id,
            title: json.title,
            description: json.description,
            shortDescription: json.shortDescription,
            type: json.type,
            pricingJson: json.pricingJson,
            softDeleted: json.softDeleted,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            image: json.image ? AccelFile.fromJson(json.image) : null,
            school: json.school ? School.fromJson(json.school) : null,
            schoolAppsCount: json.schoolLinksCount ?? 0,
        });
    }
}