import { CourseItemTaskFieldType } from '../CourseItemTaskField';
import CourseItemTaskFieldListSettings from './CourseItemTaskFieldListSettings';

export default class CourseItemTaskFieldSettingsFactory {
    public static build(type: CourseItemTaskFieldType, settingsJson?: string) {
        const json = settingsJson ? JSON.parse(settingsJson) : null;
        switch (type) {
            case CourseItemTaskFieldType.MonoSelect:
            case CourseItemTaskFieldType.MultiSelect:
                return CourseItemTaskFieldListSettings.fromJson(json);
            default:
                return json;
        }
    }
}
