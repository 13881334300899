import { observable, action } from 'mobx';
import { Cert, CertItemSettings, Entity } from '..';
import CertSettingsFactory from './settings/CertSettingsFactory';
import { KeyGenerator, Localization } from '../../utils';
import { Lang } from '../../enums';

export type CertItemType = 'text' | 'image';
export type CertItemTextSubType = 'firstName' | 'lastName' | 'fullNameFirstLast' | 'fullNameLastFirst' | 'id' | 'email' | 'courseName' | 'certNumber' | 'custom' | 'date';
export type CertItemSubType = CertItemTextSubType;

export type TextDateMeta = {
    format: string;
}
export type CertItemMeta = TextDateMeta;

export default class CertItem<TSettings extends CertItemSettings = CertItemSettings, TMeta = CertItemMeta> extends Entity {
    constructor(c?: Partial<CertItem>) {
        super();
        if (c) this.update(c);

    }

    type: CertItemType;
    subType: CertItemSubType;
    settingsJson: string;
    @observable settings: TSettings;
    /**
     * extra data e.g. var args
     */
    @observable meta: TMeta;
    /**
     * text - variable or static text
     * image - imageId
     */
    @observable value?: string;

    _key: string;
    _new: boolean;

    toJson() {
        return {
            id: this.id,
            type: this.type,
            subType: this.subType,
            settingsJson: JSON.stringify(this.settings.toJson()),
            value: this.value,
            metaJson: this.meta ? JSON.stringify(this.meta) : undefined,
            // for sorting without parsing settings on server
            zIndex: this.settings.zIndex
        };
    }

    clone(): CertItem<TSettings, TMeta> {
        return new CertItem<TSettings, TMeta>({
            ...this,
            //@ts-ignore
            meta: this.meta,
            settings: this.settings.clone()
        });
    }

    @action update(c: Partial<CertItem>, allowUndefined = false) {
        super.update(c, allowUndefined);
    }

    hasChanges(item: CertItem) {
        return this.type != item.type
            || this.subType != item.subType
            || this.settings.hasChanges(item.settings)
            || this.value != item.value;
    }

    static fromJson(json: any): CertItem {
        return new CertItem({
            ...json,
            meta: json.metaJson ? JSON.parse(json.metaJson) : undefined,
            settings: json.settingsJson ? CertSettingsFactory.parse(json.type, json.settingsJson) : undefined,
        });
    }
}


export class CertItemFactory {
    static create({ cert, loc, type, subType }: {
        cert: Cert,
        type: CertItem['type'],
        subType?: CertItem['subType'],
        loc: Localization
    }) {
        const item = new CertItem({
            _key: KeyGenerator.generate(5),
            type,
            subType,
            settings: CertSettingsFactory.default(type, cert),
            _new: true
        });
        if (subType == 'date')
            item.update({ meta: { format: loc.lang == Lang.en ? 'MM/dd/yyyy' : 'dd.MM.yyyy' } });
        item.update({
            value: type == 'text'
                ? CertItemFactory.defaultTextValue({ subType: subType!, loc, meta: item.meta })
                : undefined
        })
        return item;
    }

    static defaultTextValue({ loc, subType, meta }: { subType: CertItemSubType, loc?: Localization, meta?: CertItemMeta }): string {
        switch (subType) {
            case 'certNumber': return '#Cert.Number#';
            case 'firstName': return '#Contact.FirstName#';
            case 'lastName': return '#Contact.LastName#';
            case 'fullNameFirstLast': return '#Contact.FirstName# #Contact.LastName#';
            case 'fullNameLastFirst': return '#Contact.LastName# #Contact.FirstName#';
            case 'id': return '#Contact.ID#';
            case 'email': return '#Contact.Email#';
            case 'courseName': return '#Course.Name#';
            case 'date': return `#Function.DateUtc(${meta?.format})#`;
            default: return loc!.word('Cert.item.type.text.custom.placeholder', { default: 'Text' });
        }
    }
}