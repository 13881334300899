import { action, observable } from "mobx";
import moment, { type Moment } from "moment";
import { Entity, Admin, Contact, Pipeline, PipelineFieldValue, PipelineStage, PipelineTag, CustomFieldType } from "../..";

export default class PipelineStageItem extends Entity {
    constructor(p?: Partial<PipelineStageItem>) {
        super(p);
        if (p) this.update(p);
    }

    @observable number: number;

    @observable stage: PipelineStage;
    @observable inArchive: boolean;

    @observable lexorankPosition: string;

    @observable dealAmount: number;
    @observable revenue: number;

    createdDate: Moment;
    updatedDate?: Moment;
    completedDate?: Moment;

    @observable responsible: Admin | null;
    @observable contact: Contact;

    @observable orderCount: number;
    @observable adminTaskCount: number;
    @observable outdated: boolean;

    @observable tags: PipelineTag[];
    @observable fieldValues: PipelineFieldValue[];

    @observable pipeline: Pipeline;

    getFieldValue(fieldId: string): PipelineFieldValue | null {
        const val = this.fieldValues.find(x => x.customField.id == fieldId);
        return val ?? null;
    }

    @action setFieldValue(value: PipelineFieldValue) {
        const val = this.getFieldValue(value.customField.id);
        if (val) {
            val.update(value, true);
        } else {
            this.fieldValues.push(value);
        }
    }

    clone(): PipelineStageItem {
        return new PipelineStageItem({
            ...this,
            fieldValues: this.fieldValues?.map(x => x.copy()),
            tags: this.tags?.slice(),
        });
    }

    static fromJson(json: any) {
        return new PipelineStageItem({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            contact: json.contact ? Contact.fromJson(json.contact) : undefined,
            responsible: json.responsible ? Admin.fromJson(json.responsible) : json.responsible,
            tags: json.tagLinks ? json.tagLinks.map((x: any) => PipelineTag.fromJson(x.tag)) : undefined,
            fieldValues: json.fieldValues ? PipelineStageItem.parseFieldValuesFromJson(json) : undefined,
            stage: json.stage ? PipelineStage.fromJson(json.stage) : undefined,
            pipeline: json.pipeline ? Pipeline.fromJson(json.pipeline) : undefined,
        });
    }

    static parseFieldValuesFromJson(json: any): PipelineFieldValue[] {
        const customFieldValues = json.fieldValues.map(PipelineFieldValue.fromJson) as PipelineFieldValue[];
        const grouped = customFieldValues.groupBy(x => x.customField.id);
        const result: PipelineFieldValue[] = [];
        grouped.forEach(x => {
            const first = x[0];
            // if attr type is 'multilist' => combine values into one array only , and ignore everything else
            if (first.customField.type == CustomFieldType.MultiList) {
                // merge arrays to gather all the selected options
                const combinedValues = x.map(x => x.valueMultiList!).reduce((a, b) => [...a, ...b]);
                first.update({ valueMultiList: combinedValues });
            }
            result.push(first);
        });
        return result;
    }


    @action
    update(session: Partial<PipelineStageItem>, allowUndefined = false) {
        super.update(session, allowUndefined);
    }
}