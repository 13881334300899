
export class JwtToken {
    /**
     * This will define the expiration in NumericDate value
     */
    public exp: Date;
    /**
     * The time the JWT was issued. Can be used to determine the age of the JWT
     */
    public iat: Date;
    /**
     * Current user
     */
    public user: any;
}

export default class JwtDecoder {
    public decode(token: string): JwtToken | null {
        try {
            const payload = token.split('.')[1];
            const payloadJson = JSON.parse(this.b64DecodeUnicode(payload));
            const decodedToken = new JwtToken();
            decodedToken.exp = new Date(payloadJson.exp * 1000);
            decodedToken.iat = new Date(payloadJson.iat * 1000);
            decodedToken.user = payloadJson.user;
            return decodedToken;
        } catch (e) {
            console.error('[JwtDecoder] Error while decoding token:', token, e);
            return null;
        }
    }

    private b64DecodeUnicode(str: string) {
        var base64 = str.replace(/-/g, '+').replace(/_/g, '/');
        return decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
}