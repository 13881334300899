import { reaction as mobxReaction, IReactionPublic, IReactionOptions } from "mobx";

export const reaction = <T>(
    expression: () => T,
    effect: (value: T, r: IReactionPublic, prevValue?: T) => void,
    opts?: IReactionOptions
) => {
    let prevValue: T = expression();
    return mobxReaction(expression, async (v, r) => {
        effect(v, r, prevValue);
        prevValue = v;
    }, opts);
};