import { Entity } from "../..";
import { action, observable } from "mobx";

export default class VideoDomain extends Entity {
    constructor(videoDomain?: Partial<VideoDomain>) {
        super();
        if (videoDomain) this.update(videoDomain);
    }

    @observable text: string;
    @observable createdDate: Date;
    @observable updatedDate?: Date;

    @action update(accessDomain: Partial<VideoDomain>) {
        super.update(accessDomain);
    }

    static fromJson(json: any): VideoDomain {
        return new VideoDomain({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
        });
    }
}