import { observable, action, computed } from 'mobx';
import { Admin, Contact, Entity, UserNotificationType } from '../..';

export default class UserNotificationChannel extends Entity {
    constructor(ch?: Partial<UserNotificationChannel>) {
        super(ch);
        if (ch) this.update(ch);
    }

    @observable notificationType: UserNotificationType;
    @observable useSite: boolean;
    @observable useEmail: boolean;
    @observable useTg: boolean;
    @observable useWebPush: boolean;

    @observable admin: Admin;
    @observable contact: Contact;

    @observable subscriptionId?: string;

    @computed get hasSubscription() {
        return this.subscriptionId != null;
    }

    get canUseAsGlobalSub() {
        switch (this.notificationType) {
            case UserNotificationType.AdminTaskStatus:
            case UserNotificationType.AdminTaskCreated:
            case UserNotificationType.CourseTaskAnswer:
            case UserNotificationType.CourseTaskComment:
            case UserNotificationType.Order:
            case UserNotificationType.OrderPayment:
                return true;
            default:
                return false;
        }
    }

    /**
     * indicates whether this type of subscription can be used for a personal subscription to a contact
     */
    get canUseAsPersonalSub() {
        switch (this.notificationType) {
            case UserNotificationType.CourseTaskAnswer:
            case UserNotificationType.CourseTaskComment:
            case UserNotificationType.OrderPayment:
            case UserNotificationType.Order:
                return true;
            default:
                return false;
        }
    }

    static fromJson(json: any): UserNotificationChannel {
        return new UserNotificationChannel({
            id: json.id,
            notificationType: json.notificationType,
            useSite: json.useSite,
            useEmail: json.useEmail,
            useTg: json.useTg,
            useWebPush: json.useWebPush,
            subscriptionId: json.subscriptionId,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
        });
    }

    @action
    update(n: Partial<UserNotificationChannel>, allowUndefined: boolean = false) {
        super.update(n, allowUndefined);
    }
}

