import { Moment } from 'moment';
import { BaseFilter, SchoolContentItemProgressRating, SchoolContentSectionType } from '../..';

export default class SchoolContentItemProgressRatingFilter extends BaseFilter<SchoolContentItemProgressRating> {
    constructor(filter?: Partial<SchoolContentItemProgressRatingFilter>) {
        super();
        if (filter) this.update(filter);
    }

    courseId?: string;
    courseItemId?: string;
    libraryId?: string;
    libraryItemId?: string;
    sectionType?: SchoolContentSectionType;
    studentId?: string;
    rating?: number;
    studentIds?: string[];
    courseItemIds?: string[];
    createdDateFrom: Moment | null;
    createdDateTo: Moment | null;
    hasComment: boolean | null;
    hasFiles: boolean | null;
    ratingFrom: number | null;
    ratingTo: number | null;
    
    update(changes: Partial<SchoolContentItemProgressRatingFilter>) {
        super.update(changes);
    }
}