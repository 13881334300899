import { observable, action, computed } from 'mobx';
import JwtDecoder, { JwtToken } from '../utils/jwt-decoder/jwt-decoder';
import { inject } from 'react-ioc';
import { AuthCookieClient } from '../utils';

export default class TokenStore {

    constructor(private jwtDecoder: JwtDecoder,
        private cookieClient: AuthCookieClient) {
        this.jwtDecoder = jwtDecoder || inject(this, JwtDecoder);
        this.cookieClient = cookieClient || inject(this, AuthCookieClient);

        this.updateTokens(this.cookieClient.getAccessToken() ?? null, this.cookieClient.getRefreshToken() ?? null);
    }

    @observable refreshToken: string | null;
    @observable accessToken: string | null;

    @computed get decodedAccessToken(): JwtToken | null {
        if (this.accessToken == null)
            return null;
        return this.jwtDecoder.decode(this.accessToken);
    }

    @action updateTokens(accessToken: string | null, refreshToken: string | null) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;

        if (this.accessToken != null && this.decodedAccessToken != null) {
            this.cookieClient.setAccessToken(this.accessToken, this.decodedAccessToken.exp);
        } else {
            this.cookieClient.removeAccessToken();
        }

        if (this.refreshToken != null) {
            this.cookieClient.setRefreshToken(this.refreshToken);
        } else {
            this.cookieClient.removeRefreshToken();
        }
    }

    clearTokens() {
        this.updateTokens(null, null);
    }
}