import { action, observable } from 'mobx';
import moment from 'moment';
import { AccelFile, BaseFilter, Cert, Contact, Course, Entity } from '..';
import { ISerializable } from '../Entity';

export default class ContactCert extends Entity implements ISerializable {
    constructor(c?: Partial<ContactCert>) {
        super();
        if (c) this.update(c);
    }

    @observable number: string;

    @observable cert: Cert;
    @observable contact: Contact;
    @observable course: Course;
    @observable image: AccelFile;

    @observable createdDate: moment.Moment;
    @observable updatedDate: moment.Moment | null;


    toJson() {
        return {
            id: this.id,
            number: this.number,
            certId: this.cert === null ? null : this.cert?.id,
            contactId: this.contact === null ? null : this.contact?.id,
            courseId: this.course === null ? null : this.course?.id,
        }
    }

    clone() {
        return new ContactCert({
            ...this,
            cert: this.cert.clone(),
            contact: this.contact.clone(),
            course: this.course.clone(),
        });
    }

    @action update(c: Partial<ContactCert>, allowUndefined = false) {
        super.update(c, allowUndefined);
    }

    hasChanges(settings: ContactCert) {
        return Object.keys(settings).some(key => settings[key] !== this[key]);
    }

    static fromJson(json: any): ContactCert {
        const cert = new ContactCert({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : null,
            cert: json.cert ? Cert.fromJson(json.cert) : undefined,
            contact: json.contact ? Contact.fromJson(json.contact) : undefined,
            course: json.course ? Course.fromJson(json.course) : undefined,
            image: json.image ? AccelFile.fromJson(json.image) : undefined,
        });
        return cert;
    }
}


export class ContactCertFilter extends BaseFilter<ContactCert>{
    constructor(filter?: Partial<ContactCertFilter>) {
        super();
        if (filter) this.update(filter);
    }

    id: string;
    excludeId: string;
    contactId: string;
    certId: string;
    courseId: string;
    courseIds: string[];
    createdDateFrom: moment.Moment;
    createdDateTo: moment.Moment;

    update(changes: Partial<ContactCertFilter>) {
        super.update(changes);
    }
}