import { action, observable } from "mobx";
import {CustomField, CustomFieldType, PipelineStage} from "../..";


export default class PipelineField extends CustomField {
    constructor(f?: Partial<PipelineField>) {
        super(f);
        if (f) this.update(f);
    }

    @observable stages: PipelineStage[] = [];
  
    clone(changes?: Partial<PipelineField>) {
        return new PipelineField({
            ...this,
            ...changes
        });
    }

    @action
    update(f: Partial<PipelineField>, allowUndefined = false) {
        super.update(f, allowUndefined);
    }

    static default(){
        return new PipelineField({
            visible: true,
            required: false,
            type: CustomFieldType.Text,
            additionalData: JSON.stringify([]),
            stages: []
        });
    }

    static fromJson(json: any) {
        return new PipelineField({
            id: json.id,
            type: json.type,
            name: json.name,
            keyName: json.key,
            additionalData: json.additionalData,
            position: json.position,
            visible: json.visible,
            required: json.required,
            stages: json.stageLinks ? json.stageLinks.map((x:any) => PipelineStage.fromJson(x.stage)) : []
        });
    }
}