
export enum MegabytesPer {
    Gigabytes = 1024
}

export enum KilobytesPer {
    Megabytes = 1024,
    Gigabytes = KilobytesPer.Megabytes * MegabytesPer.Gigabytes,
}

export enum BytesPer {
    Kilobytes = 1024,
    Megabytes = BytesPer.Kilobytes * KilobytesPer.Megabytes,
    Gigabytes = BytesPer.Megabytes * MegabytesPer.Gigabytes
}