import moment from 'moment';
import { BaseFilter, SchoolContentItemComment, CourseItemCommentState, SchoolContentSectionType } from '../..';

export default class SchoolContentItemCommentFilter extends BaseFilter<SchoolContentItemComment> {
    constructor(filter?: Partial<SchoolContentItemCommentFilter>) {
        super();
        if (filter) this.update(filter);
    }

    courseItemId?: string;
    courseId?: string;
    libraryItemId?: string;
    libraryId?: string;
    progressId?: string;
    parentId?: string;
    studentId?: string;
    state?: CourseItemCommentState;
    states?: CourseItemCommentState[];
    root: boolean;
    parentSoftDeleted?: boolean;
    ratingId?: string;
    hasRating?: boolean;
    studentIds?: string[];
    courseItemIds?: string[];
    libraryItemIds?: string[];
    createdDateFrom: moment.Moment | null;
    createdDateTo: moment.Moment | null;
    sectionType?: SchoolContentSectionType;

    update(changes: Partial<SchoolContentItemCommentFilter>) {
        super.update(changes);
    }
}