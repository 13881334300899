import { action, computed, observable } from 'mobx';
import { isEmpty } from '../../../../utils';
import { IClonable, Updatable } from '../../../Entity';

export default class QuizItemSettings extends Updatable implements IClonable {
    constructor(s?: Partial<QuizItemSettings>) {
        super();
        if (s) this.update(s);
    }

    @observable question: string;
    @observable placeholder: string;
    @observable desc: string;
    @observable descHtml: string;
    @observable fieldName: string;
    @observable hideIfFilled: boolean;
    @observable attrId: string | null;
    @observable customAttrKey: string | null;
    @observable required: boolean;
    @observable hideQuestion: boolean;

    @computed get displayLabel() {
        return !isEmpty(this.fieldName) ? this.fieldName : this.question;
    }

    @action update(q: Partial<QuizItemSettings>, allowUndefined = false) {
        super.update(q, allowUndefined);
    }

    clone(): QuizItemSettings {
        return new QuizItemSettings({
            ...this
        });
    }

    static get default() {
        return new QuizItemSettings({
            required: false,
            question: '',
            placeholder: '',
            desc: '',
            descHtml: '',
            fieldName: undefined,
            hideIfFilled: false,
            customAttrKey: undefined,
            attrId: null,
            hideQuestion: false,
        });
    }

    toJson() {
        return JSON.stringify(this);
    }

    static fromJson(json: any) {
        return new QuizItemSettings({
            ...QuizItemSettings.default,
            ...json
        });
    }
}

