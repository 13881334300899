import { observable, action } from 'mobx';
import { CertFontFamily } from '../CertSettings';
import { CertItemSettings } from '../..';


export class CertItemTextSettings extends CertItemSettings {
    constructor(settings?: Partial<CertItemTextSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable fontFamily: CertFontFamily | null;
    @observable fontSize: number;
    @observable fontColor: string;
    @observable italic: boolean;
    @observable bold: boolean;
    @observable strikethrough: boolean;
    @observable underline: boolean;
    @observable textAlign: 'left' | 'center' | 'right';

    @action update(c: Partial<CertItemTextSettings>, allowUndefined = false) {
        super.update(c, allowUndefined);
    }

    clone() {
        return new CertItemTextSettings(this);
    }


    toJson() {
        return {
            ...this,
        };
    }

    hasChanges(settings: CertItemTextSettings): boolean {
        return this.fontFamily != settings.fontFamily
            || this.fontSize != settings.fontSize
            || this.fontColor != settings.fontColor
            || this.italic != settings.italic
            || this.bold != settings.bold
            || this.strikethrough != settings.strikethrough
            || this.underline != settings.underline
            || this.textAlign != settings.textAlign
            || super.hasChanges(settings);
    }

    static default(changes?: Partial<CertItemTextSettings>) {
        return new CertItemTextSettings({
            angle: 0,
            bold: false,
            fontSize: 60,
            fontColor: '#000000',
            fontFamily: null,
            italic: false,
            strikethrough: false,
            textAlign: 'center',
            underline: false,
            positionX: 0,
            positionY: 0,
            scaleX: 1,
            scaleY: 1,
            width: 300,
            height: 50,
            ...changes
        });
    }
}
