import { ApiResponseError } from '../../../api';

export default class HubResponse<T> {
    public payload: T;
    public errors: ApiResponseError[] = [];
    public body: any = null;
    public resetToken: boolean = false;

    public get success(): boolean {
        return this.errors.length == 0 && this.body != null;
    }

    public hasError(error: number): boolean {
        return this.errors.some(x => x.code == error);
    }

    public getError(error: number): ApiResponseError | undefined {
        return this.errors.find(x => x.code == error);
    }

    constructor(response: any = null, processPayload: (json: any) => T = (x) => x) {
        if (response == null || (response.body == null && response.errors == null))
            return;
        this.errors = response.errors.map(ApiResponseError.fromJson);
        this.body = response.body;
        this.resetToken = response.resetToken;
        if (this.success && this.body)
            this.payload = processPayload(this.body);
    }
}

export class BadHubResponse extends HubResponse<any> {
    public get success(): boolean {
        return false;
    }

    constructor() {
        super(null, () => null);
    }
}