import { type TimeMeasure, Updatable } from '../..';
import { observable, action } from 'mobx';
import { ScenarioNodePortType } from './ScenarioPort';


export class ScenarioNodePortPayloadFactory {
    static fromJson(payload: any, type: ScenarioNodePortType) {
        switch (type) {
            case 'button':
                return ScenarioNodePortButtonPayload.fromJson(payload);
            case 'expireButton':
                return ScenarioNodePortExpiredButtonPayload.fromJson(payload);
            default:
                return null;
        }
    }
}

export type ScenarioNodePortButtonType = 'default' | 'link';
export class ScenarioNodePortButtonPayload extends Updatable {
    constructor(p?: Partial<ScenarioNodePortButtonPayload>) {
        super();
        if (p) this.update(p);
    }

    @observable title: string;
    @observable type: ScenarioNodePortButtonType;
    @observable link: string;

    @action
    update(entity: Partial<ScenarioNodePortButtonPayload>) {
        super.update(entity);
    }

    static fromJson(json: any): ScenarioNodePortButtonPayload {
        return new ScenarioNodePortButtonPayload({
            ...json
        });
    }
}

export declare type ScenarioNodePortExpiredButtonMeasure = 'days' | 'hours' | 'minutes';
export class ScenarioNodePortExpiredButtonPayload extends Updatable {
    constructor(p?: Partial<ScenarioNodePortExpiredButtonPayload>) {
        super();
        if (p) this.update(p);
    }

    @observable value: number;
    @observable measure: TimeMeasure;

    @action
    update(entity: Partial<ScenarioNodePortExpiredButtonPayload>) {
        super.update(entity);
    }

    static fromJson(json: any): ScenarioNodePortExpiredButtonPayload {
        return new ScenarioNodePortExpiredButtonPayload({
            ...json
        });
    }
}