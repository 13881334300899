import React, { useContext } from "react";
import { Pagination, ConfigProvider, } from 'antd';
import { IListStore } from '../../stores';
import ruRU from 'antd/es/locale/ru_RU';
import enUS from 'antd/es/locale/en_US';
import { Context } from '../AccelProvider/AccelProvider';
import { Lang } from '../../enums';

type PaginationProps = {
    store: IListStore<any>;
    size?: 'default' | 'small';
    showSizeChanger?: boolean;
}
const StorePagination: React.FC<PaginationProps> = ({ store, size, showSizeChanger }) => {
    const ctx = useContext(Context);

    const onPageChange = (page: number, pageSize?: number) => {
        store.filter.update({
            page: page,
            take: pageSize ?? 25
        });
        store.fetch();
    }

    return <ConfigProvider locale={ctx.lang == Lang.ru ? ruRU : enUS}>
        <Pagination
            size={size}
            current={store.filter.page}
            total={store.filter.itemsTotal}
            pageSize={store.filter.take}
            showSizeChanger={showSizeChanger ?? true}
            onChange={(page, pageSize) => onPageChange(page, pageSize)}
            onShowSizeChange={(current, pageSize) => onPageChange(1, pageSize)}
        />
    </ConfigProvider>;
};
export default StorePagination;