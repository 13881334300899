import { Updatable } from "../Entity";

export default class UtmContainer extends Updatable {
    constructor(utm?: Partial<UtmContainer>) {
        super();
        if (utm) this.update(utm);
    }

    campaign: string;
    content: string;
    medium: string;
    source: string;
    term: string;

    get isEmpty() {
        return !this.campaign && !this.content && !this.medium && !this.source && !this.term;
    }

    update(utm: Partial<UtmContainer>, allowUndefined = false) {
        super.update(utm, allowUndefined);
    }

    toJson() {
        return {
            utmCampaign: this.campaign,
            utmContent: this.content,
            utmMedium: this.medium,
            utmSource: this.source,
            utmTerm: this.term,
        }
    }

    static fromJson(json: any) {
        return new UtmContainer({
            campaign: json.utmCampaign ?? json.campaign,
            content: json.utmContent ?? json.content,
            medium: json.utmMedium ?? json.medium,
            source: json.utmSource ?? json.source,
            term: json.utmTerm ?? json.term,
        })
    }
}