import { useState, useRef, useCallback, MutableRefObject } from 'react';

export function useRefCallback<TElement>(node?: TElement): [(node: any) => void, MutableRefObject<any>, boolean] {
    const [mounted, setMounted] = useState(node ? true : false);
    const ref = useRef<TElement | null>(node ?? null);
    const setRef = useCallback(node => {
        if (ref.current) {
            // Make sure to cleanup any events/references added to the last instance
        }

        if (node) {
            // Check if a node is actually passed. Otherwise node would be null.
            // You can now do what you need to, addEventListeners, measure, etc.
        }

        // Save a reference to the node
        ref.current = node;
        setMounted(true);
    }, []);

    return [setRef, ref, mounted];
}
