import React from 'react';
import styles from './VoiceRecordCancel.module.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Loc from '../../../../../Loc/Loc';

type Props = {
    cancelProgress: number;
    locked: boolean;
    recording: boolean;
}
const VoiceRecordTouchSlideToCancel: React.FC<Props> = ({ cancelProgress, locked, recording }) => {
    return <div className={styles.voice_record_cancel}
        data-locked={locked}
        data-recording={recording}
        data-percent={cancelProgress}
        style={{
            opacity: cancelProgress == 0 ? 1 : 0.9 * ((100 - cancelProgress) / 100),
            marginRight: cancelProgress == 0 ? 0 : 80 * (cancelProgress / 100)
        }}
    >
        <ArrowLeftOutlined />
        <Loc word='Comment.voice.cancel.touch.help' default='Slide to cancel' className='text-placeholder' />
    </div>;
}
export default VoiceRecordTouchSlideToCancel;