
import { observable, action } from 'mobx';
import { Entity, Library, LibraryItem, Package } from '..';

export default class PackageLibrary extends Entity {
    constructor(item?: Partial<PackageLibrary>) {
        super(item);
        if (item) this.update(item);
    }

    @observable library: Library;
    @observable libraryItems: LibraryItem[];
    @observable package: Package;

    static fromJson(json: any): PackageLibrary {
        return new PackageLibrary({
            ...json,
            library: json.library ? Library.fromJson(json.library) : undefined,
            libraryItems: json.items
                ? json.items.map((x: any) => LibraryItem.fromJson(x))
                : [],
            package: json.package ? Package.fromJson(json.package) : undefined,
        });
    }

    @action
    update(item: Partial<PackageLibrary>) {
        super.update(item);
    }
}

