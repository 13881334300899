export { }

declare global {
    interface HTMLElement {
        isVisible(): boolean;
    }
}

if (!HTMLElement.prototype.isVisible) {
    HTMLElement.prototype.isVisible = function (): boolean {
        return !!(this.offsetWidth || this.offsetHeight || this.getClientRects().length);
    }
}