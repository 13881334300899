import { observable, action, runInAction } from 'mobx';
import { BaseFilter } from '../../models';
import { FetchFunction } from './InfinityList';

export class InfinityListStoreFilter<T> extends BaseFilter<T> {
    constructor(filter?: Partial<InfinityListStoreFilter<T>>) {
        super();
        if (filter) this.update(filter);
    }

    update(changes: Partial<InfinityListStoreFilter<T>>) {
        for (const key in changes)
            if (changes.hasOwnProperty(key) && changes[key] !== undefined)
                this[key] = changes[key];
    }
}

export class InfinityListStore<T> {
    @observable fetching: boolean = false;
    @observable items: T[] = [];
    @observable filter = new InfinityListStoreFilter<T>();

    constructor(private fetchItems: FetchFunction<T>) {
    }

    @action
    async fetch(reset: boolean = false) {
        this.fetching = true;
        try {
            const { items, filter } = await this.fetchItems(this.filter.page, this.filter.take);
            runInAction(() => {
                this.filter = filter;
                if (reset)
                    this.items = items.slice();
                else
                    this.items.push(...items);
                this.fetching = false;
            });
        } catch{ }
    }
}