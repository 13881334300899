import { ApiRequest, ApiResponse, HttpMethod } from '../../..';
import { FileManagerItem, FileManagerItemFilter } from '../../../../models';

export class FetchFileManagerItemRequest extends ApiRequest {
	public method = HttpMethod.GET;
	public fields = `{
        id,
        name,
		updatedDate,
		createdDate,
		type,
		parentId,
		softDeleted,
		file {
			id,
			name,
			size,
			type,
			mimeType,
			cloudKey,
			extension,
			isPublic
		},
		site{
			id
		}
    }`;
	public url: string = '/file-manager';

	constructor(fileItemId: string, fields?: string) {
		super();
		const filter = new FileManagerItemFilter({
			take: 1,
			fileId: fileItemId,
			ignoreParentId: true
		});
		this.body = {
			...filter
		}
		this.fields = fields || this.fields;
	}
}

export class FetchFileManagerItemResponse extends ApiResponse {
	public item: FileManagerItem | null;
	constructor(response: any) {
		super(response);
		this.item = this.body.items.map(FileManagerItem.fromJson)[0] ?? null;
	}
}
