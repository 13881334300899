import { observable } from "mobx";
import { isEmpty } from "../../../utils";
import BaseChannelSettings from "./BaseChannelSettings";


export default class VkChannelSettings extends BaseChannelSettings {
    @observable groupId: number;
    @observable groupName: string;

    constructor(settings?: Partial<VkChannelSettings>) {
        super();
        if (settings) this.update(settings);
    }

    get isValid() {
        return !isEmpty(this.accessToken) && this.hasGroup;
    }

    get hasGroup() {
        return !isEmpty(this.groupName) && this.groupId > 0;
    }

    update(entity: Partial<VkChannelSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    hasChanges(settings: VkChannelSettings) {
        return super.hasChanges(settings)
            || this.groupId !== settings.groupId;
    }

    clone() {
        return new VkChannelSettings(this);
    }
}