﻿/**
 * ВНИМАНИЕ! При добавлении нового языка необходимо поддержать локализацию Ant Design, moment.js, cron
 * 
 * Как правило эти локали располагаются в App.tsx каждого проекта.
 * 
 * Для проекта админки он находится в TimezoneProvider.tsx
 * 
 * Например,
 * 
 * import 'moment/dist/locale/zh-tw';
 * 
 * case Lang.zhtw: moment.locale('zh-tw');
 * 
 * __________
 * 
 * import zhTW from 'antd/es/locale/zh_TW';
 * 
 * case Lang.zhtw: return zhTW;
 * 
 * __________
 * 
 * При отправке языка на сервер нужно использовать ключ, а не значение, то есть 'zhcn', а не 'zh-CN'
 * 
 * __________
 * 
 * При выводе списка языков для пользователя НУЖНО использовать список доступных языков из LocalizationStore.ts или useLang().langs
 * НЕ ИСПОЛЬЗОВАТЬ Object.keys(Lang)
 */
export enum Lang {
    ru = 'ru',
    en = 'en',
    id = 'id',
    uk = 'uk',
    es = 'es',
    zhtw = 'zhtw',
    zhcn = 'zhcn'
};