import { IReactionOptions, IReactionPublic } from 'mobx';
import React from 'react';
import { reaction } from '../../utils';

export function useReaction<T>(
    expression: () => T,
    effect: (value: T, r: IReactionPublic, prevValue?: T) => void,
    options?: IReactionOptions
) {
    React.useEffect(() => {
        const disposer = reaction(expression, effect, options)
        return () => disposer();
    }, []);
}
