import { observable } from 'mobx';
import { ISerializable, Updatable } from '../../Entity';

export default class CertItemSettings extends Updatable implements ISerializable {
    constructor(settings?: Partial<CertItemSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable positionX: number;
    @observable positionY: number;
    @observable scaleX: number;
    @observable scaleY: number;
    @observable zIndex: number;

    @observable width: number;
    @observable height: number;
    @observable angle: number;

    toJson() {
        return {
            ...this
        };
    }

    hasChanges(settings: CertItemSettings) {
        return this.positionX != settings.positionX
            || this.positionY != settings.positionY
            || this.scaleX != settings.scaleX
            || this.scaleY != settings.scaleY
            || this.zIndex != settings.zIndex
            || this.width != settings.width
            || this.height != settings.height
            || this.angle != settings.angle;
    }
}